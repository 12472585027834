const ResultIcon = ({ isFill }: { isFill: boolean }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.69303 19.7752C3.74871 20.5662 4.37086 21.199 5.16107 21.2653C9.51092 21.6299 13.4885 21.6299 17.8384 21.2653C18.6286 21.199 19.2507 20.5662 19.3064 19.7752C19.6445 14.9725 19.6445 10.4352 19.3064 5.63257C19.2507 4.84155 18.6287 4.21067 17.8417 4.11398C15.8089 3.86425 13.685 3.54297 11.4997 3.54297C9.31447 3.54297 7.19054 3.86425 5.15776 4.11398C4.3707 4.21067 3.74871 4.84155 3.69303 5.63257C3.35493 10.4352 3.35493 14.9725 3.69303 19.7752Z"
      fill={isFill ? '#54B948' : 'white'}
      fillOpacity="0.2"
    />
    <path
      d="M3.69303 19.7752C3.74871 20.5662 4.37086 21.199 5.16107 21.2653C9.51092 21.6299 13.4885 21.6299 17.8384 21.2653C18.6286 21.199 19.2507 20.5662 19.3064 19.7752C19.6445 14.9725 19.6445 10.4352 19.3064 5.63257C19.2507 4.84155 18.6287 4.21067 17.8417 4.11398C15.8089 3.86425 13.685 3.54297 11.4997 3.54297C9.31447 3.54297 7.19054 3.86425 5.15776 4.11398C4.3707 4.21067 3.74871 4.84155 3.69303 5.63257C3.35493 10.4352 3.35493 14.9725 3.69303 19.7752Z"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.46429"
    />
    <path
      d="M8.36914 10.7031H14.6325"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.46429"
      strokeLinecap="round"
    />
    <path
      d="M8.36914 15.4805H12.1488"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.46429"
      strokeLinecap="round"
    />
    <rect
      x="6.85352"
      y="1.23047"
      width="9.29241"
      height="4.87723"
      rx="2.43862"
      fill="white"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.46429"
      strokeLinejoin="round"
    />
  </svg>
);

export default ResultIcon;
