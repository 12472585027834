import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Divider,
} from '@mui/material';
import HomeIcon from '../../Assets/Images/HomeIcon';
import ResultIcon from '../../Assets/Images/ResultIcon';
import LogoutIcon from '../../Assets/Images/LogoutIcon';
import SettingsIcon from '../../Assets/SettingsIcon';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';
import EnterPasswordModal from '../EnterPasswordModal';

const NAV_LIST = [
  {
    path: '/home',
    title: 'Home',
    icon: HomeIcon,
  },
  {
    path: '/results',
    title: 'Results',
    icon: ResultIcon,
  },
  {
    path: '/profile',
    title: 'Settings',
    icon: SettingsIcon,
  },
];

const renderNavItems = (
  currentPath: string,
  navigationList: { path: string; title: string; icon: any }[],
  setIsPasswordModalOpen: Dispatch<SetStateAction<boolean>>
) =>
  navigationList.map(({ path, icon, title }) => {
    const selected = currentPath.startsWith(path);
    const navigate = useNavigate();
    return (
      <ListItemButton
        className={styles['list-button']}
        selected={selected}
        key={path}
        color="red"
        onClick={() =>
          path === '/profile' ? setIsPasswordModalOpen(true) : navigate(path)
        }
      >
        <ListItemIcon>
          <SvgIcon isFill={selected} component={icon} />
        </ListItemIcon>
        <ListItemText className={selected ? styles['list-highlight'] : ''}>
          {title}
        </ListItemText>
      </ListItemButton>
    );
  });

const SideNavBar = () => {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const handlePasswordModalClose = () => setIsPasswordModalOpen(false);

  const handleSettingsClick = () => {
    setIsPasswordModalOpen(false);
    navigate('/profile');
  };

  return (
    <Box className={styles['navbar-box']} display={{ xs: 'none', lg: 'flex' }}>
      <List component="nav">
        {renderNavItems(location.pathname, NAV_LIST, setIsPasswordModalOpen)}
      </List>
      <Box>
        <Divider className={styles['navbar-divider']} />
        <ListItemButton className={styles['list-button']} onClick={logout}>
          <ListItemIcon>
            <SvgIcon component={LogoutIcon} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
      </Box>
      <EnterPasswordModal
        isOpen={isPasswordModalOpen}
        onClose={handlePasswordModalClose}
        onSuccess={handleSettingsClick}
      />
    </Box>
  );
};

export default SideNavBar;
