import { Button, IconButton, Stack, Typography } from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import GreenCross from '../../Assets/Images/green-cross.svg';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { SubscriptionDetail } from '../PaymentDetails/interfaces/subscription-detail.interface';
import { useNavigate } from 'react-router-dom';
import { validMultiplePlansForTeacher } from '../../Utils/constants/planTypes';
import { AxiosError } from 'axios';

const UpgradePlanModal = ({
  handleClose,
  setLoader,
}: {
  handleClose: () => void;
  setLoader?: (flag: boolean) => void;
}) => {
  const { error, setError, updateUser, user } = useAuth();
  const navigate = useNavigate();

  const [userSubscriptionDetails, setUserSubscriptionDetails] =
    useState<SubscriptionDetail | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setError('');
      try {
        const userSubscription = await serverApi.getUserSubscription(
          user.token
        );
        setUserSubscriptionDetails(userSubscription);
      } catch (error) {
        console.error(
          'Error fetching user subscription and payment plan',
          error
        );
        setError('Failed to fetch exchange rate');
      }
    };

    fetchData();
  }, []);

  const onCancel = () => {
    setError('');
    handleClose();
  };

  const handleLoader = (loader: boolean) => {
    if (setLoader) {
      setLoader(loader);
    }
  };

  const onUpgrade = async () => {
    try {
      let result;
      handleLoader(true);
      handleClose();
      let updatedSubscription = 'Multiple Users Plan';
      if (user.role === 'teacher') {
        // fetches the next upgradable plan
        const index = validMultiplePlansForTeacher.indexOf(user.plan_type) + 1;
        updatedSubscription = validMultiplePlansForTeacher[index];
        result = await serverApi.getSubscriptionPlans(
          updatedSubscription,
          user.token
        );
      } else
        result = await serverApi.getSubscriptionPlans(
          'Multiple Users Plan',
          user.token
        );

      updateUser({ ...user, subscription_type: updatedSubscription });
      handleLoader(false);
      navigate('/subscription', {
        state: {
          plans: result,
          flag: true,
          prev_subscription: userSubscriptionDetails?.uuid,
        },
      });
    } catch (error) {
      handleLoader(false);
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  return (
    <Stack spacing={1.5} className={styles['change-password-modal']}>
      <IconButton className={styles['cross-btn']} onClick={() => handleClose()}>
        <img src={GreenCross} alt="green-cross-btn" />
      </IconButton>
      <Typography className={styles['header-text']}>
        Upgrade Your Plan
      </Typography>
      <Typography>Upgrade your plan to add more users.</Typography>
      <Stack direction="column" spacing={2} width="90%" marginTop="10px">
        <Button
          className={styles['next-button']}
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          onClick={async () => await onUpgrade()}
        >
          <Typography>
            <b>Upgrade Plan</b>
          </Typography>
        </Button>
        <Button
          className={styles['next-button']}
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          color="error"
          onClick={() => onCancel()}
        >
          <Typography>
            <b>Cancel</b>
          </Typography>
        </Button>
        <Typography
          visibility={`${error ? 'visible' : 'hidden'}`}
          variant="body2"
          color="red.main"
        >
          {error ? error : '&nbsp;'}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UpgradePlanModal;
