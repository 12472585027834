import { useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { deepmerge } from '@mui/utils';
import { ThemeContext } from './Context/Theme';
import { baseTheme, modeTheme } from './MUITheme';
import AppRouter from './Routes/AppRouter';
import './App.scss';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Box } from '@mui/material';
import {
  DndProvider,
  TouchTransition,
  MouseTransition,
} from 'react-dnd-multi-backend';

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

const App = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  const MuiTheme = createTheme(deepmerge(baseTheme, modeTheme[theme]));

  const memoedTheme = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme]
  );

  return (
    <Box className="App">
      <ThemeProvider theme={MuiTheme}>
        <CssBaseline />
        <ThemeContext.Provider value={memoedTheme}>
          <DndProvider options={HTML5toTouch}>
            <AppRouter />
          </DndProvider>
        </ThemeContext.Provider>
      </ThemeProvider>
    </Box>
  );
};

export default App;
