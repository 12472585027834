import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import HomeContainer from '../Components/HomeContainer';
import PublicContainer from '../Components/PublicContainer';
import useAuth from '../Context/Auth';

interface RoutePropsType {
  children: React.ReactElement;
  redirectTo?: string;
  setBackground?: boolean;
}

export const PrivateRoute: React.FC<RoutePropsType> = ({
  children,
  redirectTo = '/',
  setBackground,
}) => {
  const { user, profiles } = useAuth();
  const location = useLocation();

  const isUserLoggedIn = user.token && user.email_verified;

  if (user.user_status === 'activation_pending')
    return <Navigate to="/category-selection" />;

  if (!isUserLoggedIn)
    return <Navigate to={user.token ? '/signup/verify-email' : redirectTo} />;

  return profiles.length === 0 && location.pathname !== '/add-student' ? (
    <Navigate to="/add-student" />
  ) : (
    <HomeContainer setBackground={setBackground}>{children}</HomeContainer>
  );
};

export const PublicRoute: React.FC<RoutePropsType> = ({
  children,
  redirectTo = '/home',
  setBackground,
}) => {
  const { user } = useAuth();
  return user.token && user.email_verified ? (
    <Navigate to={redirectTo} />
  ) : (
    <PublicContainer setBackground={setBackground}>{children}</PublicContainer>
  );
};

export const SubscriptionRoute: React.FC<RoutePropsType> = ({
  children,
  redirectTo = '/',
  setBackground,
}) => {
  const { user } = useAuth();
  return user.token && user.email_verified ? (
    <HomeContainer setBackground={setBackground}>{children}</HomeContainer>
  ) : (
    <Navigate to={redirectTo} />
  );
};
