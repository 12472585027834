import { Box, Button, Stack, Typography } from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const DeactivateAccountModal = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { error, setError, user, logout } = useAuth();

  const convertDate = () => {
    const inputDate = new Date(user.subscriptionValidUntil || '');

    const day = inputDate.getUTCDate().toString().padStart(2, '0');
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = inputDate.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const handleSubmit = async () => {
    try {
      setError('');
      const response = await serverApi.deactivateAccount(user.token);
      if (response.status === 200) {
        handleClose();
        logout();
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  return (
    <Stack spacing={2} className={styles['delete-modal']}>
      <Typography variant="h3" className={styles['header-text']}>
        Are you sure you want to deactivate account?
      </Typography>
      <Typography variant="body1" className={styles['sub-text']}>
        If you deactivate your account now, you’ll only be able to use the best
        app in the world till {convertDate()}.
      </Typography>
      <Stack className={styles['button-stack']}>
        <Box width="90%">
          <Button
            className={styles['delete-button']}
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            color="error"
            onClick={() => handleSubmit()}
          >
            <Typography>
              <b>Deactivate my Account</b>
            </Typography>
          </Button>
          <Typography
            visibility={`${error ? 'visible' : 'hidden'}`}
            variant="body2"
            color="red.main"
          >
            {error ? error : '&nbsp;'}
          </Typography>
        </Box>

        <Box width="90%">
          <Button
            className={styles['cancel-button']}
            variant="outlined"
            type="button"
            fullWidth
            size="large"
            onClick={() => handleClose()}
          >
            <Typography>
              <b>Cancel</b>
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DeactivateAccountModal;
