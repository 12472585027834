import { Dispatch, SetStateAction } from 'react';
import { Container } from '@mui/material';
import AudioPlayer from '../AudioPlayer';
import VoiceRecorder from '../VoiceRecorder';
import { ISound } from '../../Utils/constants/interfaces';
import styles from './styles.module.scss';

interface PracticeSessionProps {
  wordToRead: string | undefined;
  audioList?: ISound[];
  isAppSpeaking: boolean;
  onPlayerComplete: () => void;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  duration: 2 | 3 | 5 | 10;
  currentStatus: string;
  onRecordingComplete: (audioData?: Blob) => void;
  isPaused?: boolean;
}

const Player = ({
  wordToRead,
  audioList,
  isAppSpeaking,
  onPlayerComplete,
  setActiveIndex,
  onRecordingComplete,
  currentStatus,
  duration,
  isPaused,
}: PracticeSessionProps) => {
  return (
    <Container className={styles['player-container']}>
      {!isPaused &&
        (isAppSpeaking ? (
          <AudioPlayer
            wordToRead={wordToRead}
            audioList={audioList}
            onPlayerComplete={onPlayerComplete}
            setActiveIndex={setActiveIndex}
          />
        ) : (
          <VoiceRecorder
            duration={duration}
            onRecordingComplete={onRecordingComplete}
            currentStatus={currentStatus}
          />
        ))}
    </Container>
  );
};

export default Player;
