import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  IconButton,
} from '@mui/material';
import GreenCross from '../../Assets/Images/green-cross.svg';
import styles from './styles.module.scss';

interface AccountDeactivatedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AccountDeactivatedModal = ({
  isOpen,
  onClose,
}: AccountDeactivatedModalProps) => {
  const navigate = useNavigate();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack spacing={1} className={styles['deactivated-modal']}>
        <Stack flexDirection="row-reverse" mr={2}>
          <IconButton onClick={onClose} className={styles['cross-icon']}>
            <img src={GreenCross} alt="cross" />
          </IconButton>
        </Stack>
        <Stack spacing={3} className={styles['password-container']}>
          <Typography variant="h3" className={styles['header-text']}>
            Your account
            <br />
            has been deactivated
          </Typography>
          <Typography variant="body1" className={styles['sub-text']}>
            Please update your payment method to continue using the best app in
            the world.
          </Typography>
          <Box width="90%">
            <Button
              className={styles['subscription-button']}
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              onClick={() => navigate('/category-selection')}
            >
              Go to Subscriptions
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default AccountDeactivatedModal;
