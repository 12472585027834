import { Box, Button, Stack, Typography } from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';

const DeleteProfileModal = ({
  profileId,
  handleClose,
}: {
  profileId: string;
  handleClose: () => void;
}) => {
  const { error, user, updateProfiles, selectedProfile, setCurrentProfile } =
    useAuth();

  const handleSubmit = async () => {
    try {
      const response = await serverApi.deleteStudent(
        user?.token as string,
        profileId as string
      );
      if (response.status === 200) {
        const profiles = await serverApi.getProfiles(user.token);
        const profilesStore = profiles.data.profiles;
        updateProfiles(profilesStore);
        if (profileId === selectedProfile.uuid && profilesStore.length > 0) {
          setCurrentProfile(profilesStore[0]);
        }
      }
      handleClose();
    } catch (error) {
      console.error('Error while deleting a student profile', error);
    }
  };

  return (
    <Stack spacing={2} className={styles['delete-modal']}>
      <Typography variant="h3" className={styles['header-text']}>
        Are you sure you want to delete this profile?
      </Typography>
      <Typography variant="body1" className={styles['sub-text']}>
        Note: It will delete all progress for this profile.
      </Typography>
      <Stack className={styles['button-stack']}>
        <Box width="90%">
          <Button
            className={styles['delete-button']}
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            color="error"
            onClick={() => handleSubmit()}
          >
            <Typography>
              <b>Delete Profile</b>
            </Typography>
          </Button>
          <Typography
            visibility={`${error ? 'visible' : 'hidden'}`}
            variant="body2"
            color="red.main"
          >
            {error ? error : '&nbsp;'}
          </Typography>
        </Box>

        <Box width="90%">
          <Button
            className={styles['cancel-button']}
            variant="outlined"
            type="button"
            fullWidth
            size="large"
            onClick={() => handleClose()}
          >
            <Typography>
              <b>Cancel</b>
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DeleteProfileModal;
