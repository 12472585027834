import { useState } from 'react';
import { Dialog, Stack } from '@mui/material';
import SpeechSession from '../SpeechSession';
import { SESSION_STAGES } from '../../Utils/constants/speechSession';
import styles from './styles.module.scss';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  isPractice?: boolean;
}

const SessionModal = (props: SimpleDialogProps) => {
  const { onClose, open, isPractice } = props;
  const [currentStage, setCurrentStage] = useState<string>(
    SESSION_STAGES.countdown
  );

  const updateSessionStage = (stage: string) => setCurrentStage(stage);

  const onModalClose = () => {
    onClose();
    setTimeout(() => {
      setCurrentStage(SESSION_STAGES.countdown);
    }, 1000);
  };

  return (
    <Dialog
      fullScreen
      onClose={onModalClose}
      open={open}
      className={styles['modal']}
    >
      <Stack className={`${styles['loading-modal']} ${styles[currentStage]}`}>
        <SpeechSession
          currentStage={currentStage}
          setCurrentStage={updateSessionStage}
          isPractice={isPractice}
          onClose={onModalClose}
        />
      </Stack>
    </Dialog>
  );
};

export default SessionModal;
