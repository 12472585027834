import rabbit from '../../Assets/Images/Avatars/rabbit.svg';
import huskyWolf from '../../Assets/Images/Avatars/husky-wolf.svg';
import panda from '../../Assets/Images/Avatars/panda.svg';
import deer from '../../Assets/Images/Avatars/deer-avatar.svg';
import parrot from '../../Assets/Images/Avatars/parrot.svg';
import owl from '../../Assets/Images/Avatars/owl.svg';
import squirrel from '../../Assets/Images/Avatars/squirrel.svg';

export const IMAGE_MAPPING: { [key: string]: string } = {
  'Bird-Parrot.png': parrot,
  'Bird-deer.png': deer,
  'Husky-Wolf.png': huskyWolf,
  'Owl.png': owl,
  'Panda.png': panda,
  'Rabbit.png': rabbit,
  'Racoon.png': squirrel,
};
