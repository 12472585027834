import { Box, Button, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';

const SuccessModal = ({
  title,
  description,
  handleClose,
}: {
  title: string;
  description: string;
  handleClose: () => void;
}) => {
  return (
    <Stack spacing={5} className={styles['modal-success']}>
      <Typography variant="h4" className={styles['title']}>
        {title}
      </Typography>
      <Typography variant="body1" className={styles['description']}>
        {description}
      </Typography>
      <Box width="70%">
        <Button
          variant="contained"
          fullWidth
          className={styles['button']}
          onClick={handleClose}
        >
          Close
        </Button>
      </Box>
    </Stack>
  );
};

export default SuccessModal;
