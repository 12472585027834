import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Stack,
  Box,
  IconButton,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import FormField from '../FormField';
import FormDropdown from '../FormDropdown';
import Pencil from '../../Assets/Images/pencil.svg';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import { countries } from 'countries-list';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const passwordValidationRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,.@#$%^&*!])[A-Za-z\u00C0-\u024F\d,.@#$%^&*!]{7,20}$/
);

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  fullname: yup
    .string()
    .min(6, 'Fullname must be 8 characters long')
    .required('Fullname is required'),
  location: yup.string().required('Country is required'),
  password: yup
    .string()
    .matches(
      passwordValidationRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .matches(
      passwordValidationRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Password is required'),
});

const LoginBox = ({ role, spelling }: { role: string; spelling: string }) => {
  const navigate = useNavigate();
  const { error, setError, signup } = useAuth();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const formData = new FormData();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError('');
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fillData = (values: {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    location: string;
  }): boolean => {
    if (
      values &&
      role &&
      spelling &&
      values.password === values.confirmPassword
    ) {
      formData.set('full_name', values.fullname);
      formData.set('email', values.email);
      formData.set('password', values.password);
      formData.set('country', values.location);
      formData.set('role', role);
      formData.set('english_version', spelling);

      const file = fileInputRef.current?.files?.[0];
      if (file) {
        formData.set('photo', file as Blob);
      }
      return true;
    } else {
      if (!role) {
        setError('Role not set');
        setTimeout(() => {
          setError('');
          navigate('/signup');
        }, 2000);
      } else if (!spelling) {
        setError('Spelling not set');
        setTimeout(() => {
          setError('');
          navigate('/signup/spelling-selection');
        }, 2000);
      } else if (values.password !== values.confirmPassword)
        setError(`Passwords don't match`);
      return false;
    }
  };

  const sendEmail = async (email: string) => {
    await serverApi.sendEmailVerificationLink(email);
  };

  const handleSubmit = async (values: {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    location: string;
  }) => {
    if (fillData(values)) {
      try {
        setError('');
        const response = await serverApi.signup(formData);
        if (response.status === 201) {
          const { user, token } = response.data.data;
          const userStore = { ...user, token };
          signup(userStore);
          formik.resetForm();
          setSelectedImage(null);
          sendEmail(user.email);
          navigate('/signup/verify-email');
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          //check if message is an array
          if (Array.isArray(error.response?.data.message)) {
            setError(error.response?.data.message[0]);
          } else {
            setError(error.response?.data.message);
          }
        }
      }
    } else {
      console.error('Error in filling data');
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      location: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: {
      fullname: string;
      email: string;
      password: string;
      location: string;
      confirmPassword: string;
    }) => {
      handleSubmit(values);
    },
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack className={styles['signup-box']}>
        <Stack width="90%" alignItems="center" spacing={2}>
          {isSmallScreen ? (
            <Stack direction="row" className={styles['small-screen-heading']}>
              <IconButton
                onClick={handleBackClick}
                className={styles['back-btn']}
              >
                <img
                  src={BackArrow}
                  alt="back"
                  className={styles['back-img']}
                />
              </IconButton>
              <Typography variant="h4" className={styles['page-name']}>
                Create an Account
              </Typography>
            </Stack>
          ) : (
            <Typography variant="h3" className={styles['header-text']}>
              Create an account
            </Typography>
          )}
          <Stack width="100%" className={styles['fields-stack']}>
            <Box className={styles['profile-image-box']}>
              <Avatar
                src={selectedImage || '/broken-image.jpg'}
                className={styles['profile-image']}
              ></Avatar>
              <IconButton
                className={styles['edit-btn']}
                onClick={openFileInput}
                disableRipple
              >
                <img
                  src={Pencil}
                  alt="edit-button"
                  className={styles['edit-btn-image']}
                />
              </IconButton>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
            </Box>

            <Box>
              <FormField
                name="fullname"
                fieldLabel="Full Name"
                id="fullname"
                placeHolder="e.g Steve Irwin"
                value={formik.values.fullname}
                error={
                  formik.touched.fullname && formik.errors.fullname
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.fullname && formik.errors.fullname
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.fullname && formik.errors.fullname
                  ? formik.errors.fullname
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormField
                name="email"
                fieldLabel="Email"
                id="email"
                placeHolder="steve.12@gmail.com"
                value={formik.values.email}
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
                handleChange={formik.handleChange}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.email && formik.errors.email
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormField
                fieldLabel="Enter Password"
                id="password"
                placeHolder="Enter Password"
                type="password"
                value={formik.values.password}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="password"
                isPassword={true}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.password && formik.errors.password
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormField
                fieldLabel="Confirm Password"
                id="confirmPassword"
                placeHolder="Enter Password"
                type="password"
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="confirmPassword"
                isPassword={true}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormDropdown
                fieldLabel="Country"
                id="location"
                value={formik.values.location}
                error={
                  formik.touched.location && formik.errors.location
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="location"
                options={Object.values(countries)}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.location && formik.errors.location
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.location && formik.errors.location
                  ? formik.errors.location
                  : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>

          <Box width="100%">
            <Button
              className={styles['next-button']}
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              disabled={!formik.isValid}
            >
              <Typography>
                <b>Next</b>
              </Typography>
            </Button>
            <Typography
              visibility={`${error ? 'visible' : 'hidden'}`}
              variant="body2"
              color="red.main"
            >
              {error ? error : '&nbsp;'}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default LoginBox;
