export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface DraggableItem {
  id: string;
  flashcard_value: string;
  position?: Position;
}
