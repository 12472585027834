import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import SessionModal from '../SessionModal';
import Racoon from '../../Assets/Images/sessionCard_racoon.svg';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';
import AccountDeactivatedModal from '../AccountDeactivatedModal';
import Lock from '../../Assets/Images/lock.svg';

/**
 * In order to use audio output on auto-play on mobile browsers, some kind of user interaction is needed i.e. a click.
 * To enable the application to fully pronounce separated words and read complete words, we read an empty string on the click of the Start Session button.
 * After this, the autoplay functionality will work.
 */
const enableSpeechSynthesis = () => {
  const msg = new SpeechSynthesisUtterance();
  const synthesis = window.speechSynthesis;
  msg.text = '';

  const voice = synthesis
    .getVoices()
    .filter((v) => v.name === 'Google UK English Female');

  msg.voice = voice[0];
  synthesis.cancel();
  synthesis.speak(msg);

  msg.onend = () => {
    return;
  };
};

const SessionCard = () => {
  const { user, selectedProfile } = useAuth();
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMicAllowed, setIsMicAllowed] = useState<boolean>(false);

  useEffect(() => {
    const checkMicPermission = () => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(() => {
          setIsMicAllowed(true);
        })
        .catch(() => {
          setIsMicAllowed(false);
        });
    };

    checkMicPermission();
  }, []);

  const handleStartSession = () => {
    if (user.user_status === 'deactivated') {
      setIsDeactivatedModalOpen(true);
    } else {
      isMicAllowed && setIsModalOpen(true);
      enableSpeechSynthesis();
    }
  };

  const isSessionLocked =
    process.env.REACT_APP_SESSION_LOCK == 'true' &&
    (selectedProfile.is_session_attended ||
      user.user_status === 'deactivation_pending');

  return (
    <>
      <Card className={styles['session-card']}>
        <CardContent>
          {isSessionLocked && (
            <Box component="img" src={Lock} className={styles['lock-image']} />
          )}
          <Stack alignItems="center" spacing={{ xs: 2, sm: 3, lg: 0 }}>
            <Typography variant="h3" className={styles['heading']}>
              <b>{`Let's Go!`}</b>
            </Typography>
            <Box
              component="img"
              src={Racoon}
              className={styles['racoon-image']}
            />
            <Typography className={styles['subtitle']}>
              Ready to start?
            </Typography>
          </Stack>
        </CardContent>
        <CardActions className={styles['session-action']}>
          <Button
            className={styles['session-button']}
            variant="contained"
            size="large"
            onClick={handleStartSession}
            disabled={isSessionLocked}
          >
            Start Session
          </Button>
        </CardActions>
        <SessionModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      </Card>
      <AccountDeactivatedModal
        isOpen={isDeactivatedModalOpen}
        onClose={() => setIsDeactivatedModalOpen(false)}
      />
    </>
  );
};

export default SessionCard;
