import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import GreenCross from '../../Assets/Images/green-cross.svg';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const passwordValidationRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,.@#$%^&*!])[A-Za-z\d,.@#$%^&*!]{7,20}$/
);

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup
    .string()
    .matches(
      passwordValidationRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .matches(
      passwordValidationRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Confirm password is required'),
});

const ChangePasswordModal = ({ handleClose }: { handleClose: () => void }) => {
  const { error, setError, user } = useAuth();

  const handleSubmit = async (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setError('');
    if (values.newPassword !== values.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await serverApi.changePassword(
        user.token,
        values.oldPassword,
        values.newPassword
      );
      if (response.status === 201) {
        setError('');
        handleClose();
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    }) => {
      handleSubmit(values);
    },
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} className={styles['change-password-modal']}>
        <IconButton
          className={styles['cross-btn']}
          onClick={() => handleClose()}
        >
          <img src={GreenCross} alt="green-cross-btn" />
        </IconButton>
        <Typography className={styles['header-text']}>
          Change Password
        </Typography>
        <Stack className={styles['fields-stack']}>
          <Box>
            <FormField
              fieldLabel="Enter old password"
              id="oldPassword"
              placeHolder="alpha numeric password"
              type="password"
              value={formik.values.oldPassword}
              error={
                formik.touched.oldPassword && formik.errors.oldPassword
                  ? true
                  : false
              }
              handleChange={formik.handleChange}
              name="oldPassword"
              isPassword={true}
            />
            <Typography
              variant="body2"
              color="red.main"
              visibility={`${
                formik.touched.oldPassword && formik.errors.oldPassword
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              {formik.touched.oldPassword && formik.errors.oldPassword
                ? formik.errors.oldPassword
                : '&nbsp;'}
            </Typography>
          </Box>

          <Box>
            <FormField
              fieldLabel="Enter new password"
              id="newPassword"
              placeHolder="alpha numeric password"
              type="password"
              value={formik.values.newPassword}
              error={
                formik.touched.newPassword && formik.errors.newPassword
                  ? true
                  : false
              }
              handleChange={formik.handleChange}
              name="newPassword"
              isPassword={true}
            />
            <Typography
              variant="body2"
              color="red.main"
              visibility={`${
                formik.touched.newPassword && formik.errors.newPassword
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              {formik.touched.newPassword && formik.errors.newPassword
                ? formik.errors.newPassword
                : '&nbsp;'}
            </Typography>
          </Box>

          <Box>
            <FormField
              fieldLabel="Confirm new password"
              id="confirmPassword"
              placeHolder="alpha numeric password"
              type="password"
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? true
                  : false
              }
              handleChange={formik.handleChange}
              name="confirmPassword"
              isPassword={true}
            />
            <Typography
              variant="body2"
              color="red.main"
              visibility={`${
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : '&nbsp;'}
            </Typography>
          </Box>
        </Stack>

        <Box width="90%">
          <Button
            className={styles['next-button']}
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            disabled={!formik.isValid}
          >
            <Typography>
              <b>Change Password</b>
            </Typography>
          </Button>
          <Typography
            visibility={`${error ? 'visible' : 'hidden'}`}
            variant="body2"
            color="red.main"
          >
            {error ? error : '&nbsp;'}
          </Typography>
        </Box>
      </Stack>
    </form>
  );
};

export default ChangePasswordModal;
