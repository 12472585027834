import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import SplashScreenBox from '../../Components/SplashScreenBox';
import BackGroundIPad from '../../Assets/Images/splash-screen-ipad.svg';
import BackGroundMobile from '../../Assets/Images/splash-screen-mobile.svg';
import BackGroundWeb from '../../Assets/Images/splash-screen-web.svg';
import styles from './styles.module.scss';

const SplashScreen = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const getBackgroundImg = () => {
    if (isSmallScreen) return BackGroundMobile;
    if (isLargeScreen) return BackGroundWeb;

    return BackGroundIPad;
  };

  return (
    <Container maxWidth={false} className={styles['splash-container']}>
      <Stack className={styles['base-stack']}>
        <Box
          component="img"
          src={getBackgroundImg()}
          alt="background-image"
          className={styles['background-img']}
        />
        <SplashScreenBox />
      </Stack>
    </Container>
  );
};

export default SplashScreen;
