import { Box, Container, Stack } from '@mui/material';
import SignupBox from '../../Components/SignupBox';
import CreateAccountBox from '../../Components/CreateAccountBox';
import BackGroundWeb from '../../Assets/Images/splash-screen-web.svg';
import styles from './styles.module.scss';
import { Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import SpellingSelectionBox from '../../Components/SpellingSelectionBox';
import VerifyEmailBox from '../../Components/VerifyEmailBox';

const Signup = () => {
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedSpelling, setSelectedSpelling] = useState<string>('');

  return (
    <Container maxWidth={false} className={styles['signup-container']}>
      <Box display={{ xs: 'none', xl: 'block' }}>
        <Stack className={styles['base-stack']}>
          <img
            src={BackGroundWeb}
            alt="background-image"
            className={styles['background-img']}
          />
          <Box className={`${styles['signup-box']}`}>
            <Routes>
              <Route
                path="/"
                element={
                  <CreateAccountBox
                    fullWidth
                    onBoxClick={(role) => setSelectedRole(role)}
                  />
                }
              />
              <Route
                path="spelling-selection"
                element={
                  <SpellingSelectionBox
                    onBoxClick={(spelling) => setSelectedSpelling(spelling)}
                  />
                }
              />
              <Route
                path="fields"
                element={
                  <SignupBox role={selectedRole} spelling={selectedSpelling} />
                }
              />
              <Route path="verify-email" element={<VerifyEmailBox />} />
            </Routes>
          </Box>
        </Stack>
      </Box>
      <Box display={{ xs: 'block', xl: 'none' }}>
        <Routes>
          <Route
            path="/"
            element={
              <CreateAccountBox
                fullHeight
                onBoxClick={(role) => setSelectedRole(role)}
              />
            }
          />
          <Route
            path="spelling-selection"
            element={
              <SpellingSelectionBox
                onBoxClick={(spelling) => setSelectedSpelling(spelling)}
              />
            }
          />
          <Route
            path="fields"
            element={
              <SignupBox role={selectedRole} spelling={selectedSpelling} />
            }
          />
          <Route path="verify-email" element={<VerifyEmailBox />} />
        </Routes>
      </Box>
    </Container>
  );
};

export default Signup;
