import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { IFlashCard } from '../../Utils/constants/interfaces';

interface FlashCardViewerProps {
  activeIndex: number;
  wrongCount: number;
  flashCard: IFlashCard;
}

const FlashCardViewer = ({
  activeIndex,
  wrongCount,
  flashCard,
}: FlashCardViewerProps) => {
  const [isShaking, setIsShaking] = useState<boolean>(false);
  const [cardList, setCardList] = useState<string[]>([]);
  const [colorList, setColorList] = useState<string[]>([]);

  useEffect(() => {
    const divisions = flashCard.flashcard_value.split('-');
    const colors = flashCard.flashcard_coloring.split('-');
    setCardList(divisions);
    setColorList(colors);
  }, [flashCard]);

  useEffect(() => {
    if (wrongCount === 1) {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 2000);
      return;
    }
    setIsShaking(false);
  }, [wrongCount]);

  return (
    <>
      <Stack direction="row" className={styles['separated']}>
        {cardList.map((cardString: string, index: number) => {
          return (
            <Typography
              variant="roundedMPlus_h1"
              /**
               * The check for applying 'disabled' will only be done if activeIndex > -1, since -1 means that all cards will be active.
               */
              className={`${styles['sound-box-letters']} 
							${activeIndex > -1 && index !== activeIndex ? styles['disabled'] : ''}
							${isShaking ? `${styles['shake']} ${styles['pink']}` : styles[colorList[index]]}
							`}
              key={index}
            >
              {cardString}
            </Typography>
          );
        })}
      </Stack>
    </>
  );
};

export default FlashCardViewer;
