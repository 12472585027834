import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FormField from '../../Components/FormField';
import ModalComponent from '../../Components/ProfileOptionsModal';
import ProfileTable from '../../Components/ProfileTable';
import ProfileDesktopScreen from '../../Components/ProfileDesktopHeader';
import StudentProfileCard from '../../Components/StudentProfileCard';
import ProfileSettingsSection from '../../Components/ProfileSettingsSection';
import raccoon from '../../Assets/Images/Avatars/raccoon.svg';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import Menu from '../../Assets/Images/three-dots.svg';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';
import ChangePasswordModal from '../../Components/ModalChangePassword';
import DeleteAccountModal from '../../Components/ModalDeleteAccount';
import DeactivateAccountModal from '../../Components/ModalDeactivateAccount';
import UpgradePlanModal from '../../Components/ModalUpgradePlan';
import serverApi from '../../Utils/api';
import SuccessModal from '../../Components/ModalSuccess';

const Profile = () => {
  const { user, profiles, updateFullname, logout, setError } = useAuth();
  const navigate = useNavigate();

  const [fullname, setFullname] = useState(user.full_name || 'info_mwp');
  const [isOpen, setIsOpen] = useState(false);

  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [isDeactivateAccountOpen, setIsDeactivateAccountOpen] = useState(false);
  const [isUpgradePlanOpen, setIsUpgradePlanOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const isAddStudentPossible =
    (user.role === 'parent' &&
      user.plan_type === 'Multiple Users Plan' &&
      profiles.length >= 3) ||
    (user.role === 'teacher' &&
      user.plan_type === 'ThirtyStudentsPlan' &&
      profiles.length >= 30);

  const handleChangePasswordOpen = () => {
    setError('');
    setIsChangePasswordOpen(true);
  };
  const handleChangePasswordClose = () => setIsChangePasswordOpen(false);

  const handleDeactivateAccountOpen = () => {
    setError('');
    setIsDeactivateAccountOpen(true);
  };
  const handleDeactivateAccountClose = () => setIsDeactivateAccountOpen(false);

  const handleDeleteAccountOpen = () => {
    setError('');
    setIsDeleteAccountOpen(true);
  };
  const handleDeleteAccountClose = () => setIsDeleteAccountOpen(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleUpgradePlanClose = () => setIsUpgradePlanOpen(false);

  const handleSuccessModalOpen = () => setIsSuccessModalOpen(true);
  const handleSuccessModalClose = () => setIsSuccessModalOpen(false);

  const handleClick = () => {
    navigate('/home');
  };

  const handleAddStudent = () => {
    if (
      (user.plan_type === 'ThreeStudentsPlan' && profiles.length >= 3) ||
      (user.plan_type === 'FiveStudentsPlan' && profiles.length >= 5) ||
      (user.plan_type === 'TenStudentsPlan' && profiles.length >= 10) ||
      (user.plan_type === 'TwentyStudentsPlan' && profiles.length >= 20) ||
      (user.plan_type === 'ThirtyStudentsPlan' && profiles.length >= 30) ||
      user.plan_type == 'Single User Plan'
    ) {
      setIsUpgradePlanOpen(true);
    } else {
      navigate('/add-student', { state: { previousPage: '/profile' } });
    }
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'fullname':
        setFullname(value);
        break;
      default:
        break;
    }
  };

  const handleChangeFullname = async () => {
    if (fullname !== user.full_name && fullname !== '' && fullname.length > 3) {
      try {
        const formData = new FormData();
        formData.append('full_name', fullname);

        const response = await serverApi.updateUser(user.token, formData);
        if (response.status === 200) {
          updateFullname(response.data.data.user[0].full_name);
          handleSuccessModalOpen();
        }
      } catch (error) {
        console.error('Error while updating user', error);
      }
    }
  };

  return (
    <Stack className={styles['profile-container']}>
      <Modal
        className={styles['success-modal']}
        open={isSuccessModalOpen}
        onClose={handleSuccessModalClose}
      >
        <SuccessModal
          title="Name Changed"
          description="Your full name has been changed Successfully."
          handleClose={handleSuccessModalClose}
        />
      </Modal>
      <Modal
        className={styles['change-modal']}
        open={isUpgradePlanOpen}
        onClose={handleUpgradePlanClose}
      >
        <UpgradePlanModal handleClose={handleUpgradePlanClose} />
      </Modal>
      <Modal
        className={styles['change-modal']}
        open={isChangePasswordOpen}
        onClose={handleChangePasswordClose}
      >
        <ChangePasswordModal handleClose={handleChangePasswordClose} />
      </Modal>
      <Modal
        className={styles['change-modal']}
        open={isDeleteAccountOpen}
        onClose={handleDeleteAccountClose}
      >
        <DeleteAccountModal handleClose={handleDeleteAccountClose} />
      </Modal>
      <Modal
        className={styles['change-modal']}
        open={isDeactivateAccountOpen}
        onClose={handleDeactivateAccountClose}
      >
        <DeactivateAccountModal handleClose={handleDeactivateAccountClose} />
      </Modal>
      <Stack className={styles['profile-content']} spacing={5}>
        <Stack spacing={1} direction="row" className={styles['heading']}>
          <IconButton onClick={handleClick} className={styles['back-btn']}>
            <img src={BackArrow} alt="back" className={styles['back-img']} />
          </IconButton>
          <Typography variant="h4" className={styles['page-name']}>
            Settings
          </Typography>
        </Stack>
        <Stack width="100%" display={{ xs: 'none', lg: 'block' }}>
          <ProfileDesktopScreen
            handleDeleteAccountOpen={handleDeleteAccountOpen}
            handleDeactivateAccountOpen={handleDeactivateAccountOpen}
          />
        </Stack>
        <Box
          className={styles['profile-image-box']}
          display={{ xs: 'inline-block', lg: 'none' }}
        >
          <img
            src={raccoon}
            alt="profile-photo"
            className={styles['profile-image']}
          />
        </Box>
        <Stack width="100%" spacing={{ xs: 2, lg: 5 }}>
          <Stack
            spacing={{ xs: 2, lg: 5 }}
            direction={{ md: 'column', lg: 'row' }}
            className={styles['fields-stack']}
          >
            <Box className={styles['form-field-box']}>
              <FormField
                name="fullname"
                id="fullname"
                fieldLabel="Full Name"
                placeHolder="Enter Full Name"
                type="text"
                value={fullname}
                error={false}
                handleChange={handleChange}
                changeButton
                handleClick={handleChangeFullname}
              ></FormField>
            </Box>
            <Box className={styles['form-field-box']}>
              <FormField
                name="email"
                id="email"
                fieldLabel="Email"
                placeHolder="steve_12@gmail.com"
                type="text"
                value={user.email}
                error={false}
                handleChange={handleChange}
                disabled
              ></FormField>
            </Box>
          </Stack>
          <Stack
            spacing={{ xs: 2, lg: 5 }}
            direction={{ md: 'column', lg: 'row' }}
            className={styles['fields-stack']}
          >
            <Box className={styles['form-field-box']}>
              <FormField
                name="password"
                id="password"
                fieldLabel="Password"
                placeHolder="Enter Password"
                type="password"
                value={'12345678'}
                error={false}
                handleChange={handleChange}
                isPassword
                changeButton
                handleClick={handleChangePasswordOpen}
              ></FormField>
            </Box>
            <Box className={styles['form-field-box']}>
              <FormField
                name="country"
                id="country"
                fieldLabel="Country"
                placeHolder="Select Country"
                type="text"
                value={user.country || 'Country'}
                error={false}
                handleChange={handleChange}
                disabled
              ></FormField>
            </Box>
          </Stack>
        </Stack>
        <Stack spacing={2} className={styles['student-profiles-stack']}>
          <Stack direction="row" className={styles['heading-stack']}>
            <Typography variant="h4">Student Profiles</Typography>
            {!isAddStudentPossible ? (
              <IconButton
                className={styles['section-btn']}
                onClick={() => handleAddStudent()}
              >
                <AddIcon className={styles['icon']} />
                <Typography variant="body1" className={styles['text']}>
                  Add Student
                </Typography>
              </IconButton>
            ) : null}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            display={{ lg: 'none' }}
            className={styles['student-profiles-horizontal']}
          >
            {profiles.map((profile) => (
              <StudentProfileCard
                key={profile.uuid}
                avatar_id={profile.avatar_uuid}
                level={profile.current_level || 1}
                profile_name={profile.profile_name || 'Student Name'}
              />
            ))}
          </Stack>
          <Stack display={{ xs: 'none', lg: 'block' }}>
            <ProfileTable />
          </Stack>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            className={styles['view-students-btn']}
            onClick={() => navigate('/student-profiles')}
          >
            <Typography className={styles['btn-text']}>
              <b>See All Students</b>
            </Typography>
          </Button>
        </Stack>
        <ProfileSettingsSection />
        <Stack
          direction="row"
          spacing={3}
          display={{ lg: 'none' }}
          className={styles['buttons-stack']}
        >
          <Button
            type="submit"
            fullWidth
            size="large"
            color="secondary"
            onClick={logout}
            className={styles['logout-btn']}
          >
            Logout
          </Button>
          <IconButton onClick={handleOpen} className={styles['modal-btn']}>
            <img src={Menu} alt="modal-menu" />
          </IconButton>
        </Stack>
      </Stack>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalComponent
          handleClose={handleClose}
          handleDeleteAccountOpen={handleDeleteAccountOpen}
          handleDeactivateAccountOpen={handleDeactivateAccountOpen}
        />
      </Modal>
    </Stack>
  );
};

export default Profile;
