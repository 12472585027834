import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import chestClose from '../../Assets/Images/chest-close.svg';
import chestOpen from '../../Assets/Images/chest-open.svg';
import styles from './styles.module.scss';

const ChestModal = ({
  handleClose,
  openNextModal,
}: {
  handleClose: () => void;
  openNextModal: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const openChestTimeout = setTimeout(() => {
      setIsOpen(true);
    }, 1500);

    return () => {
      clearTimeout(openChestTimeout);
    };
  }, []);

  const closeAfterDelay = () => {
    setTimeout(() => {
      handleClose();
      openNextModal();
    }, 3000);
  };

  useEffect(() => {
    closeAfterDelay();
  }, []);

  return (
    <Stack spacing={5} className={styles['chest-modal-container']}>
      <Typography variant="h3" className={styles['heading']}>
        Chest Unlocked
      </Typography>
      <img
        src={isOpen ? chestOpen : chestClose}
        alt="Chest"
        className={styles['chest-img']}
      />
    </Stack>
  );
};

export default ChestModal;
