import { Divider, Stack, Typography } from '@mui/material';
import SingleResultListItem from '../SingleResultListItem';
import styles from './styles.module.scss';
import { Word } from '../../Pages/SingleResult';
import useAuth from '../../Context/Auth';

interface SingleListResultProps {
  words?: Word[];
}

const IncorrectPronunciationsList = ({ words }: SingleListResultProps) => {
  const { selectedProfile } = useAuth();
  return (
    <Stack spacing={3} className={styles['list-container-stack']}>
      <Stack direction="row" spacing={1} className={styles['divider']}>
        <Typography variant="h5" className={styles['divider-text']}>
          Pronunciations
        </Typography>
        <Divider className={styles['divider-line']} />
      </Stack>

      {words?.map((word, index) => (
        <SingleResultListItem
          key={index}
          word={word}
          username={selectedProfile.profile_name || ''}
        />
      ))}
    </Stack>
  );
};

export default IncorrectPronunciationsList;
