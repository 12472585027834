import { useState } from 'react';
import { Container, Stack } from '@mui/material';
import SessionProgressBar from '../SessionProgressBar';
import FlashCardViewer from '../FlashCardViewer';

import { IFlashCard } from '../../Utils/constants/interfaces';
import {
  RECORDER_STATES,
  TOTAL_SESSION_TIME,
  VOICE_RECORDER_DURATION,
} from '../../Utils/constants/speechSession';

import styles from './styles.module.scss';
import Player from '../Player';

interface PracticeSessionProps {
  sessionStage: string;
  incorrectWordsList: IFlashCard[];
  reviewSessionCallback: () => void;
  sessionEndedCallback: (wrongCount: number, correctCount: number) => void;
  timerValue: number;
  setTimerValue: (t: number) => void;
  isPaused: boolean;
}

const PracticeSessionOne = ({
  sessionStage,
  incorrectWordsList,
  reviewSessionCallback,
  sessionEndedCallback,
  timerValue,
  setTimerValue,
  isPaused,
}: PracticeSessionProps) => {
  const sessionWrongCount = 0;
  const sessionCorrectCount = 0;

  const [incorrectCardIndex, setIncorrectCardIndex] = useState<number>(0);

  const [recorderStatus, setRecorderStatus] = useState<string>(
    RECORDER_STATES.recording
  );

  const [tempWrongCount, setTempWrongCount] = useState<number>(0);

  const [isAppSpeaking, setIsAppSpeaking] = useState<boolean>(true);

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const sessionFinishedCallback = () => {
    sessionEndedCallback(sessionWrongCount, sessionCorrectCount);
  };

  const moveToNextWord = () => {
    setTimeout(() => {
      setActiveIndex(-1);
      setTempWrongCount(0);
      if (incorrectCardIndex === incorrectWordsList.length - 1) {
        setIncorrectCardIndex(0);
      } else {
        setIncorrectCardIndex((prevInd) => prevInd + 1);
      }
      setRecorderStatus(RECORDER_STATES.recording);
      return;
    }, 1000);
  };

  const recordingCompleteHandler = async () => {
    if (isPaused) return;
    setTimeout(() => {
      setIsAppSpeaking(true);
    }, 2000);
    moveToNextWord();
    return;
  };

  const appSpeakingCompleteHandler = () => {
    setIsAppSpeaking(false);
    setRecorderStatus(RECORDER_STATES.recording);
  };

  return (
    <Container maxWidth={false} className={styles['top-container']}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        height={'100%'}
        paddingTop={'40px'}
      >
        <SessionProgressBar
          sessionStage={sessionStage}
          callbackTime={TOTAL_SESSION_TIME}
          timerCallback={reviewSessionCallback}
          timerEndedCallback={sessionFinishedCallback}
          timerValue={timerValue}
          setTimerValue={setTimerValue}
        />
        {incorrectWordsList[incorrectCardIndex] && (
          <FlashCardViewer
            wrongCount={tempWrongCount}
            flashCard={incorrectWordsList[incorrectCardIndex]}
            activeIndex={activeIndex}
          />
        )}
        {incorrectWordsList.length && (
          <Player
            wordToRead={
              incorrectWordsList[incorrectCardIndex].flashcard_value ||
              undefined
            }
            audioList={
              incorrectWordsList[incorrectCardIndex].sounds || undefined
            }
            onPlayerComplete={appSpeakingCompleteHandler}
            setActiveIndex={setActiveIndex}
            duration={VOICE_RECORDER_DURATION}
            onRecordingComplete={recordingCompleteHandler}
            currentStatus={recorderStatus}
            isAppSpeaking={isAppSpeaking}
          ></Player>
        )}
      </Stack>
    </Container>
  );
};

export default PracticeSessionOne;
