import { Typography, Button, Stack, IconButton } from '@mui/material';
import AmexIcon from '../../Assets/Images/Light Color/amex.png';
import CartesBancairesIcon from '../../Assets/Images/Light Color/cartes-bancaires.png';
import DinersIcon from '../../Assets/Images/Light Color/diners.png';
import DiscoverIcon from '../../Assets/Images/Light Color/discover.png';
import JcbIcon from '../../Assets/Images/Light Color/jcb.png';
import MastercardIcon from '../../Assets/Images/Light Color/mastercard.png';
import VisaIcon from '../../Assets/Images/Light Color/visa.png';
import UnionpayIcon from '../../Assets/Images/Light Color/unionpay.svg';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import serverApi from '../../Utils/api';
import CircularProgress from '@mui/material/CircularProgress';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodDetails } from './interfaces/payment-detail.interface';
import { SubscriptionDetail } from './interfaces/subscription-detail.interface';
import useAuth from '../../Context/Auth';
import { validMultiplePlansForTeacher } from '../../Utils/constants/planTypes';
import { AxiosError } from 'axios';

const PaymentDetails = () => {
  type CardBrandIcons = {
    [key: string]: string;
  };

  const { updateUser, user, setError } = useAuth();
  const [userSubscriptionDetails, setUserSubscriptionDetails] =
    useState<SubscriptionDetail | null>(null);
  const [userPaymentDetails, setUserPaymentDetails] =
    useState<PaymentMethodDetails | null>(null);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/home');
  };

  const cardBrandIcons: CardBrandIcons = {
    amex: AmexIcon,
    cartes_bancaires: CartesBancairesIcon,
    diners: DinersIcon,
    discover: DiscoverIcon,
    jcb: JcbIcon,
    mastercard: MastercardIcon,
    visa: VisaIcon,
    unionpay: UnionpayIcon,
  };

  const getCardIcon = (brand: string): string | undefined =>
    cardBrandIcons[brand] || undefined;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const userSubscription = await serverApi.getUserSubscription(
          user.token
        );
        const userPayment = await serverApi.getUserPaymentPlan(user.token);
        setUserSubscriptionDetails(userSubscription);
        setUserPaymentDetails(userPayment);
        setLoader(false);
      } catch (error) {
        console.error('Error fetching exchange rate', error);
        setError('Failed to fetch exchange rate');
        setLoader(false);
      }
    };

    fetchData();
  }, []);

  const handleUpgradeClick = async () => {
    setError('');
    try {
      let result;
      let updatedSubscription = 'Multiple Users Plan';
      if (user.role === 'teacher') {
        // fetches the next upgradable plan
        const index = validMultiplePlansForTeacher.indexOf(user.plan_type) + 1;
        updatedSubscription = validMultiplePlansForTeacher[index];
        result = await serverApi.getSubscriptionPlans(
          updatedSubscription,
          user.token
        );
      } else
        result = await serverApi.getSubscriptionPlans(
          'Multiple Users Plan',
          user.token
        );

      updateUser({ ...user, subscription_type: updatedSubscription });
      navigate('/subscription', {
        state: {
          plans: result,
          flag: true,
          prev_subscription: userSubscriptionDetails?.uuid,
        },
      });
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const handleChangeButtonClick = async () => {
    setError('');
    try {
      const result = {
        priceId: userSubscriptionDetails?.plan_id,
        customerId: userSubscriptionDetails?.stripe_customer_id,
        userId: userSubscriptionDetails?.user_uuid,
        couponCode: '',
      };
      navigate('/update-details', { state: { result } });
    } catch (error: any) {
      setError(error.response.message);
    }
  };

  const getParsedDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = [
      String(date.getUTCMonth() + 1).padStart(2, '0'), // getUTCMonth
      String(date.getUTCDate()).padStart(2, '0'), // getUTCDate
      String(date.getUTCFullYear()).slice(-2), // getUTCFullYear
    ].join('/');
    return formattedDate;
  };

  const isUpgradeDisabled =
    userSubscriptionDetails?.planType === 'Multiple Users Plan' ||
    userSubscriptionDetails?.planType === 'ThirtyStudentsPlan';

  return (
    <Stack spacing={5} className={styles['settings-container']}>
      {loader || !userPaymentDetails || !userSubscriptionDetails ? (
        <Stack className={styles['loader']}>
          <CircularProgress />
        </Stack>
      ) : (
        <Stack
          spacing={5}
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          className={styles['general-settings-stack']}
        >
          <Stack direction="column" className={styles['heading']}>
            <Stack direction="row">
              <IconButton onClick={handleClick} className={styles['back-btn']}>
                <img
                  src={BackArrow}
                  alt="back"
                  className={styles['back-img']}
                />
              </IconButton>
              <Typography variant="h4">Payment Plan</Typography>
            </Stack>
            <Stack className={styles['first-stack']} spacing={0.5}>
              <Stack direction="row" className={styles['content']}>
                <img
                  src={getCardIcon(
                    userPaymentDetails?.paymentMethod.card.brand
                  )}
                  alt={`${userPaymentDetails?.paymentMethod.card.brand} card icon`}
                  style={{
                    width: '70px',
                    height: '40px',
                  }}
                />
                <Button
                  variant="contained"
                  className={styles.changeButton}
                  onClick={handleChangeButtonClick}
                >
                  Change
                </Button>
              </Stack>
              <Typography variant="h5" className={styles.information}>
                **** **** **** {userPaymentDetails?.paymentMethod.card.last4}
              </Typography>
              <Typography variant="h5" className={styles.information}>
                {userPaymentDetails?.paymentMethod.card.exp_month} /{' '}
                {userPaymentDetails?.paymentMethod.card.exp_year
                  .toString()
                  .slice(-2)}
              </Typography>
              <Typography variant="h5" className={styles.information}>
                {user.country}
              </Typography>
              <Typography variant="h5" className={styles.information}>
                Last Charged{' '}
                {getParsedDate(userPaymentDetails?.lastChargedDate)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" className={styles['heading']}>
            <Typography variant="h4" className={styles['second-heading']}>
              Subscription Plan
            </Typography>
            <Stack className={styles['first-stack']}>
              <Stack direction="row" className={styles['content']}>
                <Typography variant="h5" className={styles.information}>
                  {userSubscriptionDetails.planType}
                </Typography>
                <Button
                  variant="contained"
                  className={styles.changeButton}
                  onClick={handleUpgradeClick}
                  disabled={isUpgradeDisabled}
                >
                  Upgrade
                </Button>
              </Stack>
              <Typography variant="h5" className={styles.information}>
                {userSubscriptionDetails.interval_type}ly{' '}
                {userSubscriptionDetails.amount}{' '}
                {userSubscriptionDetails.currency}
              </Typography>
              <Typography variant="h5" className={styles.information}>
                Last Payment{' '}
                {userSubscriptionDetails.lastPaidDate
                  ? getParsedDate(userSubscriptionDetails.lastPaidDate)
                  : '-'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PaymentDetails;
