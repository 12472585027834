import {
  Box,
  Button,
  Stack,
  Typography,
  Modal,
  IconButton,
} from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import GreenCross from '../../Assets/Images/green-cross.svg';
import styles from './styles.module.scss';

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
});

interface EnterPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const EnterPasswordModal = ({
  isOpen,
  onClose,
  onSuccess,
}: EnterPasswordModalProps) => {
  const { error, setError, user } = useAuth();

  const handleSubmit = async (
    values: { password: string },
    resetForm: () => void
  ) => {
    try {
      setError('');
      if (user.uuid) {
        const response = await serverApi.login(user.email, values.password);
        if (response) {
          setError('');
          resetForm();
          onSuccess();
        }
      }
    } catch (error) {
      setError('Invalid password');
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { password: string }, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
    validateOnMount: true,
  });

  return (
    <Modal open={isOpen} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <Stack className={styles['password-modal']}>
          <Stack flexDirection="row-reverse" mr={2}>
            <IconButton onClick={onClose} className={styles['cross-icon']}>
              <img src={GreenCross} alt="cross" />
            </IconButton>
          </Stack>
          <Stack spacing={1} className={styles['password-container']}>
            <Typography variant="h3" className={styles['header-text']}>
              Enter Password
            </Typography>
            <Typography variant="body1" className={styles['sub-text']}>
              Please enter your password to use the feature
            </Typography>
            <Stack className={styles['fields-stack']}>
              <Box>
                <FormField
                  fieldLabel="Enter password"
                  id="password"
                  placeHolder="alpha numeric password"
                  type="password"
                  value={formik.values.password}
                  error={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                  handleChange={formik.handleChange}
                  name="password"
                  isPassword={true}
                />
                <Typography
                  variant="body2"
                  color="red.main"
                  visibility={`${
                    formik.touched.password && formik.errors.password
                      ? 'visible'
                      : 'hidden'
                  }`}
                >
                  {formik.touched.password && formik.errors.password
                    ? formik.errors.password
                    : '&nbsp;'}
                </Typography>
              </Box>
            </Stack>

            <Box width="90%">
              <Button
                className={styles['enter-button']}
                variant="contained"
                type="submit"
                fullWidth
                size="large"
                disabled={!formik.isValid}
              >
                Enter
              </Button>
              <Typography
                visibility={`${error ? 'visible' : 'hidden'}`}
                variant="body2"
                color="red.main"
              >
                {error ? error : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

export default EnterPasswordModal;
