import { Box, Button, Stack, Typography } from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const validationSchema = yup.object().shape({
  password: yup.string().required('New password is required'),
  confirmPassword: yup.string().required('Confirm password is required'),
});

const DeleteAccountModal = ({ handleClose }: { handleClose: () => void }) => {
  const { error, setError, user, logout } = useAuth();

  const handleSubmit = async (values: {
    password: string;
    confirmPassword: string;
  }) => {
    if (values.password !== values.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      if (user.uuid) {
        const response = await serverApi.deleteAccount(
          user.token,
          user.uuid,
          values.password
        );
        if (response.status === 200) {
          setError('');
          handleClose();
          logout();
        } else {
          setError(response.data.message);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { password: string; confirmPassword: string }) => {
      handleSubmit(values);
    },
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack className={styles['delete-modal']}>
        <Typography variant="h3" className={styles['header-text']}>
          Are you sure you want to delete your account?
        </Typography>
        <Typography variant="body1" className={styles['sub-text']}>
          Please enter your password to delete your account. Note: It will
          delete all users and their progress.
        </Typography>
        <Stack className={styles['fields-stack']}>
          <Box>
            <FormField
              fieldLabel="Enter password"
              id="password"
              placeHolder="alpha numeric password"
              type="password"
              value={formik.values.password}
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
              handleChange={formik.handleChange}
              name="password"
              isPassword={true}
            />
            <Typography
              variant="body2"
              color="red.main"
              visibility={`${
                formik.touched.password && formik.errors.password
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : '&nbsp;'}
            </Typography>
          </Box>

          <Box>
            <FormField
              fieldLabel="Confirm password"
              id="confirmPassword"
              placeHolder="alpha numeric password"
              type="password"
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? true
                  : false
              }
              handleChange={formik.handleChange}
              name="confirmPassword"
              isPassword={true}
            />
            <Typography
              variant="body2"
              color="red.main"
              visibility={`${
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? 'visible'
                  : 'hidden'
              }`}
            >
              {formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : '&nbsp;'}
            </Typography>
          </Box>
        </Stack>

        <Box width="90%">
          <Button
            className={styles['delete-button']}
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            color="error"
            disabled={!formik.isValid}
          >
            <Typography>
              <b>Delete my account</b>
            </Typography>
          </Button>
          <Typography
            visibility={`${error ? 'visible' : 'hidden'}`}
            variant="body2"
            color="red.main"
          >
            {error ? error : '&nbsp;'}
          </Typography>
        </Box>

        <Box width="90%">
          <Button
            className={styles['cancel-button']}
            variant="outlined"
            type="button"
            fullWidth
            size="large"
            onClick={() => handleClose()}
          >
            <Typography>
              <b>Cancel</b>
            </Typography>
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default DeleteAccountModal;
