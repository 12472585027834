import { useState, useRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import AudioAnalyser from 'react-audio-analyser';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';

import LoaderAnimation5Sec from '../../Assets/LottieAnimations/SpeechSession/loader5Sec.json';
// TODO: import LoaderAnimation3Sec from '../../Assets/LottieAnimations/SpeechSession/loader3Sec.json';
import LoaderAnimation2Sec from '../../Assets/LottieAnimations/SpeechSession/loader2Sec.json';
import MicIndicationAnimation from '../../Assets/LottieAnimations/SpeechSession/micIndication.json';
import RacoonListening from '../../Assets/LottieAnimations/SpeechSession/racoonListening.json';
import RacoonCorrectAnswer from '../../Assets/LottieAnimations/SpeechSession/racoonCorrectAnswer.json';
import RacoonWrongAnswer from '../../Assets/LottieAnimations/SpeechSession/racoonWrongAnswer.json';
import { RECORDER_STATES } from '../../Utils/constants/speechSession';
import styles from './styles.module.scss';

const MIC_STATES = {
  recording: 'recording',
  inactive: 'inactive',
};

interface VoiceRecorderProps {
  duration: 2 | 3 | 5 | 10;
  currentStatus: string;
  onRecordingComplete: (audioData?: Blob) => void;
}

const VoiceRecorder = ({
  duration,
  currentStatus,
  onRecordingComplete,
}: VoiceRecorderProps) => {
  const loaderRef = useRef<Player>(null);
  //TODO: Fix any type for lottie animations
  const [racoonAnimation, setRacoonAnimation] = useState<any>(RacoonListening);
  const [mic, setMic] = useState<string>('');

  const AUDIO_PROPS = {
    audioType: 'audio/wav',
    status: mic,
    height: 0,
    width: 0,
    /**
     * The react-audio-analyzer library supports mutiple event triggers.
     * Can be found on this link: https://github.com/jiwenjiang/react-audio-analyser
     */
    stopCallback: (e: Blob) => {
      onRecordingComplete(e);
    },
  };

  useEffect(() => {
    switch (currentStatus) {
      case RECORDER_STATES.wrongAnswer:
        setRacoonAnimation(RacoonWrongAnswer);
        break;

      case RECORDER_STATES.correctAnswer:
        setRacoonAnimation(RacoonCorrectAnswer);
        break;

      case RECORDER_STATES.recording:
        setRacoonAnimation(RacoonListening);
        setMic(MIC_STATES.recording);
        // The loader animation is not on loop. It plays automatially first time due to autoPlay property.
        // For every subsequent run, it needs to be triggered.
        // If it is simply displayed and triggered, it is paused on its first frame, which is blank. Hence, nothing can be seen.
        // It needs to be triggered manually everytime mic begins recording.
        loaderRef.current?.play();
        break;
    }
    // if (sessionStage === SESSION_STAGES.incorrectWords) {
    //   setRacoonAnimation(RacoonListening);
    // }
  }, [currentStatus]);

  const loaderCompleteEventHandler = (event: PlayerEvent) => {
    if (event === 'complete') {
      // setting the mic to 'stop' as soon as the loader completes.
      // The duration of mic recording is controlled by the animation.
      setMic(MIC_STATES.inactive);
    }
  };

  /**
   * In case of 2 seconds, the 2 second loader is played at 1x speed
   * In case of 3 seconds, the 5 second loader animation is played at 1.25x speed.
   * In case of 5 seconds, the 5 second loader is played at 1x speed.
   * TODO: Only 2 second and 5 second animations are being used. However, the 3 second animation is present in assets if it is ever needed.
   * It can be deleted if it is not needed.
   */
  return (
    <Stack className={styles['top-container']}>
      <Player
        ref={loaderRef}
        autoplay
        src={duration === 2 ? LoaderAnimation2Sec : LoaderAnimation5Sec}
        className={styles['animation']}
        speed={duration === 3 ? 1.25 : 1}
        onEvent={loaderCompleteEventHandler}
        // style={{ display: `${mic === MIC_STATES.recording ? '' : 'none'}` }}
      />
      <Player
        loop
        autoplay
        src={MicIndicationAnimation}
        className={styles['animation']}
        speed={1}
        style={{ display: `${mic === MIC_STATES.recording ? '' : 'none'}` }}
      />
      <Player
        autoplay
        keepLastFrame
        loop={racoonAnimation === RacoonListening ? true : false}
        src={racoonAnimation}
        className={styles['racoon-animation']}
        speed={1}
      />
      <AudioAnalyser {...AUDIO_PROPS} />
    </Stack>
  );
};

export default VoiceRecorder;
