import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Typography, Button, Stack, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import useAuth from '../../Context/Auth';
import RaccoonWithGreenTile from '../../Assets/Images/raccoon-with-green-tile.svg';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const passwordValidationRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[,.@#$%^&*!])[A-Za-z\d,.@#$%^&*!]{7,20}$/
);

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordValidationRegex,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .min(8, 'Password must be 8 characters long')
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Password is required'),
});

const ChangePasswordBox = ({
  fullWidth,
  fullHeight,
}: {
  fullWidth?: boolean;
  fullHeight?: boolean;
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetToken = queryParams.get('resetToken');
  const { error, setError } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (password: string) => {
    try {
      if (resetToken) {
        setError('');
        const response = await serverApi.resetPassword(resetToken, password);
        if (response.status === 201) {
          navigate('/login');
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { password: string; confirmPassword: string }) => {
      if (values.password === values.confirmPassword) {
        handleSubmit(values.password);
      }
    },
    isInitialValid: false,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack className={styles['change-password-box']}>
        <Stack
          width={fullWidth ? '90%' : '80%'}
          alignItems="center"
          spacing={5}
        >
          {fullHeight && (
            <Box className={styles['heading-box']}>
              <img
                src={RaccoonWithGreenTile}
                alt="Raccoon With Green Tile"
                className={styles['heading-image']}
              />
              <Typography variant="h3" className={styles['header-text']}>
                Change Password
              </Typography>
            </Box>
          )}
          {fullWidth && (
            <Typography variant="h3" className={styles['header-text']}>
              Change Password
            </Typography>
          )}
          <Stack width="100%" spacing={2} className={styles['fields-stack']}>
            <Box>
              <FormField
                fieldLabel="New Password"
                id="password"
                placeHolder="alpha numeric password"
                type="password"
                value={formik.values.password}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="password"
                isPassword={true}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.password && formik.errors.password
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : '&nbsp;'}
              </Typography>
            </Box>
            <Box>
              <FormField
                fieldLabel="Confirm Password"
                id="confirmPassword"
                placeHolder="alpha numeric password"
                type="password"
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="confirmPassword"
                isPassword={true}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword
                  : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>
          <Box width="100%">
            <Button
              className={styles['change-password-button']}
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              disabled={!formik.isValid}
            >
              <Typography>
                <b>Change Password</b>
              </Typography>
            </Button>
            <Typography
              visibility={`${error ? 'visible' : 'hidden'}`}
              variant="body2"
              color="red.main"
            >
              {error ? error : '&nbsp;'}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default ChangePasswordBox;
