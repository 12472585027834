import { ReactElement } from 'react';
import { Box, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ResponsiveAppBar from '../AppBar';
import BottomNavBar from '../BottomNavBar';
import SideNavBar from '../SideNavBar';
import styles from './styles.module.scss';

const HomeContainer = ({
  children,
  setBackground,
}: {
  children: ReactElement;
  setBackground?: boolean;
}) => {
  const location = useLocation();

  // Check if the current route is /tutorial
  const isTutorialRoute =
    location.pathname === '/tutorial' ||
    location.pathname === '/add-student' ||
    location.pathname === '/category-selection' ||
    location.pathname === '/subscription' ||
    location.pathname === '/payment' ||
    location.pathname === '/update-details';

  return (
    <Box
      className={`${styles['home-box']} ${
        setBackground && styles['home-background']
      }`}
    >
      <ResponsiveAppBar />
      <Stack className={styles['home-stack']} direction="row">
        {!isTutorialRoute && <SideNavBar />}
        <Box className={styles['home-children']}>{children}</Box>
      </Stack>
      {!isTutorialRoute && <BottomNavBar />}
    </Box>
  );
};

export default HomeContainer;
