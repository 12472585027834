import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface Theme {
  theme: 'light' | 'dark';
  setTheme: Dispatch<SetStateAction<'light' | 'dark'>>;
}

export const ThemeContext = createContext<Theme>({
  theme: 'light',
  setTheme: () => void {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};
