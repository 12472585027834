import { Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';
import { AxiosError } from 'axios';

const EMAIL_DELAY = 30;

const VerifyEmailBox = () => {
  const [status, setStatus] = useState<string>('');
  const { user } = useAuth();
  const [seconds, setSeconds] = useState(EMAIL_DELAY);

  const handleResendEmail = async () => {
    if (seconds > 0) return;

    try {
      const response = await serverApi.sendEmailVerificationLink(user.email);
      if (response.status === 201) {
        setStatus(response.data.message);
      } else {
        setStatus(response.data.message);
      }

      setSeconds(EMAIL_DELAY);
    } catch (error) {
      console.error('Error while resending verification email', error);
      if (error instanceof AxiosError) setStatus(error.response?.data.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevCount) => {
        if (prevCount === 0) {
          return prevCount;
        }

        return prevCount - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Stack className={styles['verify-email-box']}>
      <Stack width="90%" alignItems="center" spacing={5}>
        <Typography variant="h3" className={styles['header-text']}>
          Verify your Email
        </Typography>
        <Typography variant="h5" className={styles['sub-text']}>
          We have sent a verification email to {user.email}. Kindly check your
          Spam folder as well for email.
          <br />
          <br />
          Please click on it to continue.
        </Typography>

        <Stack direction="row" alignItems="center">
          <Typography variant="body1" className={styles['resend-text']}>
            Didn&apos;t receive? &nbsp;
          </Typography>
          <Link
            onClick={() => handleResendEmail()}
            underline="always"
            className={styles['resend-link']}
            style={{
              cursor: seconds === 0 ? 'pointer' : 'not-allowed',
              opacity: seconds === 0 ? 1 : 0.5,
            }}
          >
            Resend Email {seconds > 0 ? `(in ${seconds}s)` : ''}
          </Link>
        </Stack>

        {status ? (
          <Typography variant="body1" className={styles['status-text']}>
            {status}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default VerifyEmailBox;
