import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { ICountry } from 'countries-list';
import styles from './styles.module.scss';

interface DropdownProps {
  fieldLabel: string;
  id: string;
  value: string | number;
  error: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  name: string;
  options: (ICountry | number)[];
}

const extractValue = (option: ICountry | number): string | number => {
  if (typeof option === 'object' && 'name' in option) {
    return option.name;
  }
  return option;
};

const FormDropdown = (props: DropdownProps) => {
  const { fieldLabel, id, value, error, handleChange, name, options } = props;

  const maxHeight = window.matchMedia('(min-width: 600px)').matches
    ? '50vh'
    : '40vh';

  return (
    <Stack>
      <Typography mb={1} className={styles['label']}>
        {fieldLabel}
      </Typography>
      <TextField
        id={id}
        variant="outlined"
        value={value || 'Select'}
        error={error}
        onChange={handleChange}
        name={name}
        defaultValue="Select"
        select
        SelectProps={{ MenuProps: { style: { maxHeight } } }}
      >
        <MenuItem value="Select" disabled selected>
          Select
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={extractValue(option)}>
            {extractValue(option)}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};

export default FormDropdown;
