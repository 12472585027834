import { Stack, Typography } from '@mui/material';
import ProgressBar from '../ProgressBar';
import ChestClose from '../../Assets/Images/chest-close.svg';
import styles from './styles.module.scss';

interface ProgressBadgeBoxProps {
  badgeName: string;
  lowerBadgeWords: number;
  badgeWords: number;
  badgeProgress: number;
  image?: string;
  current?: boolean;
  completed?: boolean;
}

const ProgressBadgeBox = ({
  badgeName,
  badgeWords,
  lowerBadgeWords,
  badgeProgress,
  image,
  current,
  completed,
}: ProgressBadgeBoxProps) => {
  return (
    <Stack
      direction="row"
      className={`${styles['progress-badge-box-container']} ${
        current ? styles.yellow : ''
      } ${completed ? styles.green : ''}`}
    >
      <Stack spacing={1} className={styles['progress-badge-box-content']}>
        <Typography variant="h3" className={styles['heading']}>
          {badgeName}
        </Typography>
        <Typography variant="body1" className={styles['sub-text']}>
          Master {badgeWords} words {}
        </Typography>
        <ProgressBar
          lowerValue={lowerBadgeWords}
          uppervalue={badgeWords}
          value={badgeProgress}
        />
      </Stack>
      <Stack className={styles['badge-box']}>
        <img
          src={badgeProgress < badgeWords ? ChestClose : image}
          alt=""
          className={styles['badge']}
        />
      </Stack>
    </Stack>
  );
};

export default ProgressBadgeBox;
