import { useState } from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import RaccoonWithGreenTile from '../../Assets/Images/raccoon-with-green-tile.svg';
import { useNavigate } from 'react-router-dom';
import serverApi from '../../Utils/api';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';
import { AxiosError } from 'axios';

const ForgotPasswordConfirmationBox = ({
  fullHeight,
  fullWidth,
  email,
}: {
  fullHeight?: boolean;
  fullWidth?: boolean;
  email: string;
}) => {
  const [status, setStatus] = useState<string>('');
  const navigate = useNavigate();
  const { setError } = useAuth();

  const handleResendEmail = async () => {
    try {
      setError('');
      const response = await serverApi.forgetPassword(email);
      if (response.status === 201) {
        setStatus(response.data.message);
      } else {
        setStatus(response.data.message);
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const clickHandler = async () => {
    navigate('/');
  };
  return (
    <Stack className={styles['forgot-password-confirmation-box']}>
      <Stack width={fullWidth ? '100%' : '90%'} alignItems="center" spacing={5}>
        {fullHeight && (
          <Box className={styles['heading-box']}>
            <img
              src={RaccoonWithGreenTile}
              alt="Raccoon With Green Tile"
              className={styles['heading-image']}
            />
            <Typography variant="h3" className={styles['header-text']}>
              Reset Link Sent
            </Typography>
          </Box>
        )}
        {fullWidth && (
          <Typography variant="h3" className={styles['header-text']}>
            Reset Link Sent
          </Typography>
        )}
        <Typography variant="h5" className={styles['sub-text']}>
          Email has been sent on {email}, Please follow the instruction to reset
          password
        </Typography>
        <Stack spacing={4} width="90%" className={styles['box-stack']}>
          <Box width="100%">
            <Button
              className={styles['continue-button']}
              variant="contained"
              type="submit"
              fullWidth
              size="large"
              onClick={() => clickHandler()}
            >
              <Typography>
                <b>Continue</b>
              </Typography>
            </Button>
          </Box>
          <Link
            onClick={() => handleResendEmail()}
            underline="always"
            className={styles['resend-link']}
          >
            Resend Email
          </Link>
        </Stack>

        {status ? (
          <Typography variant="body1" className={styles['status-text']}>
            {status}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default ForgotPasswordConfirmationBox;
