import { Stack, LinearProgress, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface SessionProgressBarProps {
  sessionStage: string;
  callbackTime: number; // time after which callback will be initiated;
  timerCallback: () => void;
  timerEndedCallback: () => void;
  timerValue: number;
  setTimerValue: (t: any) => void;
}

const OneThirdLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
}));

const oneMilliSecond = 1000;

const SessionProgressBar = ({
  sessionStage,
  callbackTime,
  timerCallback,
  timerEndedCallback,
  timerValue,
  setTimerValue,
}: SessionProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const totalSessionTime = callbackTime * oneMilliSecond;

  useEffect(() => {
    if (timerValue >= callbackTime * 1000) return;
    const intervalId = setInterval(() => {
      setTimerValue((t: number) => t + 100);
      setProgress((timerValue / totalSessionTime) * 100);
    }, 100);
    return () => clearInterval(intervalId);
  }, [sessionStage, timerValue]);

  useEffect(() => {
    if (timerValue >= totalSessionTime) timerCallback();
    if (timerValue >= totalSessionTime) timerEndedCallback();
  }, [timerValue]);

  return (
    <Stack width={'100%'} spacing={3}>
      <Typography className={styles['heading']} variant="h3">
        Words
      </Typography>
      <Stack direction={'row'} justifyContent={'space-around'} width={'100%'}>
        <OneThirdLinearProgress value={progress} variant="determinate" />
      </Stack>
    </Stack>
  );
};

export default SessionProgressBar;
