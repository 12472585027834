import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AmericanFlag from '../../Assets/Images/american-flag.svg';
import BritishFlag from '../../Assets/Images/british-flag.svg';
import RightGreenArrow from '../../Assets/Images/right-green-arrow.svg';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';

const SpellingSelectionBox = ({
  onBoxClick,
}: {
  onBoxClick: (spelling: string) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { setError } = useAuth();

  const handleBoxClick = (spelling: string) => {
    if (onBoxClick) {
      onBoxClick(spelling);
    }
    setError('');
    navigate('/signup/fields');
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Stack className={styles['spelling-selection-box']}>
      <Stack width="90%" alignItems="center" spacing={8}>
        <Stack
          spacing={5}
          alignItems="center"
          width={isSmallScreen ? '100%' : '90%'}
        >
          {isSmallScreen ? (
            <Stack direction="row" className={styles['small-screen-heading']}>
              <IconButton
                onClick={handleBackClick}
                className={styles['back-btn']}
              >
                <img
                  src={BackArrow}
                  alt="back"
                  className={styles['back-img']}
                />
              </IconButton>
              <Typography variant="h4" className={styles['page-name']}>
                Select Spelling Version
              </Typography>
            </Stack>
          ) : (
            <Typography variant="h3" className={styles['header-text']}>
              Select Spelling Version
            </Typography>
          )}
          <Typography variant="h5" className={styles['sub-text']}>
            Please select the spelling version of your choice
          </Typography>
        </Stack>
        <Stack spacing={3} width="100%" className={styles['box-stack']}>
          <Box
            className={styles['content-box']}
            onClick={() => handleBoxClick('british')}
          >
            <Stack direction="row" className={styles['content-stack']}>
              <Stack direction="row">
                <Avatar
                  src={BritishFlag}
                  className={styles['avatar-image']}
                ></Avatar>
                <Typography variant="h4" className={styles['heading']}>
                  British English
                </Typography>
              </Stack>
              <img
                src={RightGreenArrow}
                alt=""
                className={styles['arrow-icon']}
              />
            </Stack>
          </Box>

          <Box
            className={styles['content-box']}
            onClick={() => handleBoxClick('american')}
          >
            <Stack direction="row" className={styles['content-stack']}>
              <Stack direction="row">
                <Avatar
                  src={AmericanFlag}
                  className={styles['avatar-image']}
                ></Avatar>
                <Typography variant="h4" className={styles['heading']}>
                  American English
                </Typography>
              </Stack>
              <img
                src={RightGreenArrow}
                alt=""
                className={styles['arrow-icon']}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SpellingSelectionBox;
