import { useEffect, useState } from 'react';
import { Container, Stack } from '@mui/material';
import ReactHowler from 'react-howler';

import SessionProgressBar from '../SessionProgressBar';
import FlashCardViewer from '../FlashCardViewer';

import { IFlashCard } from '../../Utils/constants/interfaces';
import useAuth from '../../Context/Auth';
import {
  RECORDER_STATES,
  TOTAL_INCORRECT_WORDS_ALLOWED,
  TOTAL_SESSION_TIME,
  VOICE_RECORDER_DURATION,
} from '../../Utils/constants/speechSession';
import { SESSION_STAGES } from '../../Utils/constants/speechSession';
import serverApi from '../../Utils/api';
import Confetti from 'react-confetti';

import styles from './styles.module.scss';
import Player from '../Player';

interface SoundsSessionProps {
  flashCardList: IFlashCard[];
  sessionStage: string;
  session_id: string;
  onSessionComplete: (
    sessionWrongCount: number,
    sessionCorrectCount: number
  ) => void;
  incorrectWordsList: IFlashCard[];
  updateIncorrectWords: (incorrectWord: IFlashCard | null) => void;
  reviewSessionCallback: () => void;
  sessionEndedCallback: (wrongCount: number, correctCount: number) => void;
  setCurrentStage: (stage: string) => void;
  setApiCounter: (counter: number) => void;
  apiCounter: number;
  timerValue: number;
  setTimerValue: (t: number) => void;
}

const SoundsSession = ({
  sessionStage,
  session_id,
  flashCardList,
  onSessionComplete,
  incorrectWordsList,
  updateIncorrectWords,
  reviewSessionCallback,
  sessionEndedCallback,
  setApiCounter,
  apiCounter,
  timerValue,
  setTimerValue,
}: SoundsSessionProps) => {
  const CORRECT_ANSWER_SOUND = '/SampleSounds/correct-answer.wav';

  const [answerSound, setAnswerSound] = useState<string>('');
  const [isAnswerSoundPlaying, setIsAnswerSoundPlaying] =
    useState<boolean>(false);

  const { user, selectedProfile, sounds } = useAuth();
  let totalSounds = flashCardList.length;

  const [flashCardIndex, setFlashCardIndex] = useState<number>(0);

  const [recorderStatus, setRecorderStatus] = useState<string>(
    RECORDER_STATES.recording
  );

  const [tempWrongCount, setTempWrongCount] = useState<number>(0);
  const [sessionWrongCount, setSessionWrongCount] = useState<number>(0);

  const [sessionCorrectCount, setSessionCorrectCount] = useState<number>(0);

  const [isAppSpeaking, setIsAppSpeaking] = useState<boolean>(false);

  const isSoundsActive = sounds;

  const [activeIndex, setActiveIndex] = useState<number>(-1);

  // Confetti State
  const [isConfettiActive, setIsConfettiActive] = useState<boolean>(false);

  useEffect(() => {
    totalSounds = flashCardList.length;
    if (flashCardIndex >= totalSounds - 1) setFlashCardIndex(0);
  }, [flashCardList]);

  const sessionFinishedCallback = () => {
    sessionEndedCallback(sessionWrongCount, sessionCorrectCount);
  };

  const moveToNextWord = () => {
    setTimeout(() => {
      setActiveIndex(-1);
      setTempWrongCount(0);
      if (flashCardIndex === totalSounds - 1) {
        setFlashCardIndex(0);
        setRecorderStatus(RECORDER_STATES.recording);
        setIsAppSpeaking(false);
        onSessionComplete(sessionWrongCount, sessionCorrectCount);
      } else {
        setFlashCardIndex((prevInd) => prevInd + 1);
        setRecorderStatus(RECORDER_STATES.recording);
      }
    }, 1000);
  };

  const recordingCompleteHandler = async (audioData?: Blob) => {
    const is_retry = tempWrongCount === 1 ? true : false;
    setIsConfettiActive(false);

    try {
      const { data: response } = await serverApi.verifyRecording(
        selectedProfile.uuid,
        flashCardList[flashCardIndex],
        audioData!,
        session_id,
        is_retry,
        user.token
      );
      setApiCounter(apiCounter + 1);

      if (response.aggregated_result === 'wrong') {
        setAnswerSound('');
        setIsAnswerSoundPlaying(true);

        setRecorderStatus(RECORDER_STATES.wrongAnswer);
        if (tempWrongCount === 1) {
          if (sessionStage === SESSION_STAGES.words) {
            setSessionWrongCount((prevCount) => prevCount + 1);
            setTimeout(() => {
              updateIncorrectWords(flashCardList[flashCardIndex]);
            }, 2000);
          }
          if (incorrectWordsList.length + 1 !== TOTAL_INCORRECT_WORDS_ALLOWED) {
            moveToNextWord();
          } else {
            setTimeout(() => {
              setIsAppSpeaking(true);
            }, 2000);
          }
        } else if (tempWrongCount === 0) {
          setTempWrongCount((prevCount) => prevCount + 1);
          setTimeout(() => {
            setIsAppSpeaking(true);
          }, 2000);
        }
      } else if (
        response.aggregated_result === 'correct' ||
        response.aggregated_result === 'semi-correct'
      ) {
        setAnswerSound(CORRECT_ANSWER_SOUND);
        setIsAnswerSoundPlaying(true);

        const confettiStatus = !is_retry;
        setIsConfettiActive(confettiStatus);

        setRecorderStatus(RECORDER_STATES.correctAnswer);
        response.aggregated_result === 'semi-correct' &&
          updateIncorrectWords(flashCardList[flashCardIndex]);
        sessionStage === SESSION_STAGES.words &&
          setSessionCorrectCount((prevCount) => prevCount + 1);
        // add delay for confetti to complete
        setTimeout(() => moveToNextWord(), confettiStatus ? 2500 : 0);
      }
    } catch (error) {
      setRecorderStatus(RECORDER_STATES.wrongAnswer); //this will change the racoon animation inside the VoiceRecorder component
      if (tempWrongCount === 1) {
        if (sessionStage === SESSION_STAGES.words) {
          setSessionWrongCount((prevCount) => {
            if (prevCount + 1 >= TOTAL_INCORRECT_WORDS_ALLOWED)
              setFlashCardIndex(0);
            return prevCount + 1;
          });
          updateIncorrectWords(flashCardList[flashCardIndex]);
        }
        moveToNextWord();
      } else if (tempWrongCount === 0) {
        setTempWrongCount((prevCount) => prevCount + 1);
        setTimeout(() => {
          setIsAppSpeaking(true);
        }, 2000);
      }
    }
  };

  const appSpeakingCompleteHandler = () => {
    setIsAppSpeaking(false);
    setRecorderStatus(RECORDER_STATES.recording);
  };

  return (
    <Container maxWidth={false} className={styles['top-container']}>
      {isConfettiActive && <Confetti gravity={1} recycle={false} />}
      <ReactHowler
        src={[answerSound]}
        format={['wav']}
        playing={isAnswerSoundPlaying && isSoundsActive}
        onEnd={() => {
          setIsAnswerSoundPlaying(false);
        }}
      />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        height={'100%'}
        paddingTop={'40px'}
      >
        <SessionProgressBar
          sessionStage={sessionStage}
          callbackTime={TOTAL_SESSION_TIME}
          timerCallback={reviewSessionCallback}
          timerEndedCallback={sessionFinishedCallback}
          timerValue={timerValue}
          setTimerValue={setTimerValue}
        />
        {flashCardList[flashCardIndex] && (
          <FlashCardViewer
            wrongCount={tempWrongCount}
            flashCard={flashCardList[flashCardIndex]}
            activeIndex={activeIndex}
          />
        )}
        <Player
          wordToRead={
            flashCardList[flashCardIndex].flashcard_value || undefined
          }
          audioList={flashCardList[flashCardIndex].sounds || undefined}
          onPlayerComplete={appSpeakingCompleteHandler}
          setActiveIndex={setActiveIndex}
          duration={VOICE_RECORDER_DURATION}
          onRecordingComplete={recordingCompleteHandler}
          currentStatus={recorderStatus}
          isAppSpeaking={isAppSpeaking}
        ></Player>
      </Stack>
    </Container>
  );
};

export default SoundsSession;
