import PaymentForm from '../PaymentForm';

import serverApi from '../../Utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../Context/Auth';
const PaymentRoute = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();
  const location = useLocation();

  const { flag } = location.state || {};

  const createSubscription = async (payload: any) => {
    return await serverApi.createSubscription(
      payload.customer_id,
      payload.payment_method_id,
      payload.price_id,
      payload.couponCode,
      payload.marketingEnabled,
      user.token
    );
  };

  const upgradeSubscription = async (payload: any) => {
    return await serverApi.upgradeSubscription(
      payload.customer_id,
      payload.payment_method_id,
      payload.price_id,
      payload.subscriptionId,
      user.token
    );
  };

  const prepareApiPayload = (payment_method_id: string, data: any) => ({
    customer_id: data.customerId,
    payment_method_id,
    price_id: data.priceId,
    user_id: data.userId,
    couponCode: data.couponCode,
    marketingEnabled: data.marketingEnabled,
  });

  const prepareApiPayloadForUpgrade = (
    payment_method_id: string,
    data: any
  ) => ({
    customer_id: data.customerId,
    payment_method_id,
    price_id: data.new_subscription_id,
    subscriptionId: data.old_subscription_id,
  });

  const navigator = async () => {
    const userStore = await serverApi.getUser(user.token);
    const { user: fetchedUser, subscriptionValidUntil } = userStore;
    const updatedUser = {
      ...fetchedUser,
      subscriptionValidUntil,
      token: user.token,
    };
    updateUser(updatedUser);
    if (flag) {
      navigate('/payment-details');
    } else {
      navigate('/add-student');
    }
  };

  return (
    <>
      <PaymentForm
        buttonLabel="Pay"
        prepareApiPayload={
          flag ? prepareApiPayloadForUpgrade : prepareApiPayload
        }
        makeApiCall={flag ? upgradeSubscription : createSubscription}
        navigator={navigator}
      />
    </>
  );
};

export default PaymentRoute;
