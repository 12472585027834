import { Box, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';

const StudentProfileCard = ({
  avatar_id,
  level,
  profile_name,
}: {
  avatar_id: string;
  level: number;
  profile_name: string;
}) => {
  const { avatars } = useAuth();

  const getImageById = (id: string) => {
    const avatar = avatars.find((avatar) => avatar.uuid === id);
    return avatar && avatar.image;
  };

  return (
    <Stack direction="row" className={styles['student-card']}>
      <Box>
        <img
          src={getImageById(avatar_id)}
          alt=""
          className={styles['card-profile-img']}
        />
      </Box>
      <Stack spacing={1} ml={1} className={styles['content']}>
        <Typography variant="h5" className={styles['username']}>
          {profile_name}
        </Typography>
        <Typography variant="body2" className={styles['student-level']}>
          Level: <b>{level}</b>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StudentProfileCard;
