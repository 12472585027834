import { Box, Stack, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import StarIcon from '../../Assets/Images/star-white-outline.svg';
import StarSpikesIcon from '../../Assets/Images/star-with-spikes.svg';
import styles from './styles.module.scss';

const Milestone = ({ position }: { position: number }) => (
  <Stack
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: `${position}%`,
      width: '4px', // Adjust the width of the vertical bar
      borderRadius: '50px',
      background: '#0000002f', // Adjust color as needed
    }}
  />
);

const ProgressBar = ({
  lowerValue,
  uppervalue,
  value,
  onHome,
}: {
  lowerValue: number;
  uppervalue: number;
  value: number;
  onHome?: boolean;
}) => {
  const totalWordsRequired = uppervalue - lowerValue;
  const wordsCompleted = value > lowerValue ? value - lowerValue : 0;
  let percentage = (wordsCompleted / totalWordsRequired) * 100;
  percentage = percentage > 100 ? 100 : percentage;
  const textLeft = percentage > 80 ? percentage - 15 : percentage + 15;

  let homePercentage = (wordsCompleted / totalWordsRequired) * 16.6;
  homePercentage =
    lowerValue === 0
      ? homePercentage
      : lowerValue === 20
      ? homePercentage + 16.67
      : lowerValue === 50
      ? homePercentage + 33.3
      : lowerValue === 100
      ? homePercentage + 66.6
      : lowerValue === 200
      ? homePercentage + 83.4
      : homePercentage;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={onHome ? homePercentage : percentage}
          sx={{ height: { xs: '8px', md: '15px' } }}
          className={styles['progress-bar']}
        />
        {onHome ? (
          <img
            src={StarSpikesIcon}
            alt="Star"
            style={{
              position: 'absolute',
              top: '50%',
              left: `${homePercentage}%`,
              transform: 'translate(-50%, -50%)',
              rotate: '-10deg',
              zIndex: '1',
            }}
            className={styles['star-spike-img']}
          />
        ) : (
          <img
            src={StarIcon}
            alt="Star"
            style={{
              position: 'absolute',
              top: '50%',
              left: `${percentage}%`,
              transform: 'translate(-50%, -50%)',
            }}
            className={styles['star-img']}
          />
        )}

        {!onHome && (
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              top: '50%',
              left: `${textLeft}%`,
              transform: 'translate(-50%, -50%)',
              color: '#162731',
              fontWeight: 'regular',
            }}
          >
            {value > uppervalue ? uppervalue : value}/{uppervalue}
          </Typography>
        )}
        {onHome && (
          <>
            <Milestone position={16.6} />
            <Milestone position={33.3} />
            <Milestone position={50} />
            <Milestone position={66.6} />
            <Milestone position={83.4} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProgressBar;
