import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { RECORDER_STATES } from '../../Utils/constants/speechSession';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import { IFlashCard } from '../../Utils/constants/interfaces';
import styles from './styles.module.scss';

interface FlashCardViewerProps {
  flashCard: IFlashCard;
  flashcardList: IFlashCard[];
  setAnswerSound: (sound: string) => void;
  setIsAnswerSoundPlaying: (sound: boolean) => void;
  setRecorderStatus: (state: string) => void;
  cardOpacity: string;
  checkResult: boolean;
  setCheckResult: (result: boolean) => void;
}

const FlashcardStack = ({
  flashCard,
  flashcardList,
  setAnswerSound,
  setIsAnswerSoundPlaying,
  setRecorderStatus,
  cardOpacity,
  checkResult,
  setCheckResult,
}: FlashCardViewerProps) => {
  const CORRECT_ANSWER_SOUND = '/SampleSounds/correct-answer.wav';

  const [correctIndex, setCorrectIndex] = useState(-1);
  const [incorrectIndex, setIncorrectIndex] = useState(-1);
  const [showPointer, setShowPointer] = useState<boolean>(false);

  const resetStates = () => {
    setCheckResult(false);
    setCorrectIndex(-1);
    setIncorrectIndex(-1);
  };

  const handleCardClick = (cardValue: string, index: number) => {
    if (checkResult) return;
    setShowPointer(false);
    setCheckResult(true);
    if (cardValue === flashCard.flashcard_value) {
      setAnswerSound(CORRECT_ANSWER_SOUND);
      setIsAnswerSoundPlaying(true);
      setRecorderStatus(RECORDER_STATES.correctAnswer);
    } else {
      setAnswerSound('');
      setRecorderStatus(RECORDER_STATES.wrongAnswer);
      setIsAnswerSoundPlaying(true);
      setIncorrectIndex(index);
    }
    setTimeout(() => resetStates(), 3000);
  };

  useEffect(() => {
    resetStates();
    flashcardList.map((word, index) => {
      if (word.flashcard_value === flashCard.flashcard_value)
        setCorrectIndex(index);
    });
  }, [flashCard, flashcardList]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowPointer(true);
    }, 2000);
    timer = setTimeout(() => {
      setShowPointer(false);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Stack className={styles['title-word']}>
      <Typography className={styles['heading']}>
        Click or tap the correct word
      </Typography>
      <Stack
        direction="row"
        className={`${styles['separated']} ${styles[cardOpacity]}`}
      >
        {flashcardList.map((flashcard: IFlashCard, index: number) => {
          return (
            <Stack className={styles['letter-cursor']} key={index}>
              <Typography
                variant="roundedMPlus_h1"
                className={`${styles['sound-box-letters']}
              ${
                checkResult && correctIndex === index
                  ? `${styles['green']} ${styles['shake']}`
                  : incorrectIndex === index
                  ? styles['pink']
                  : styles['white']
              }
              `}
                onClick={() =>
                  handleCardClick(flashcard.flashcard_value, index)
                }
              >
                {flashcard.flashcard_value}
              </Typography>
              {correctIndex === index && showPointer && (
                <img
                  src={PressGif}
                  alt="Press Gif"
                  className={styles['gif-overlay']}
                />
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FlashcardStack;
