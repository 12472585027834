import { ReactElement } from 'react';
import { Box, Stack } from '@mui/material';
import ResponsiveAppBar from '../AppBar';
import styles from './styles.module.scss';

const HomeContainer = ({
  children,
  setBackground,
}: {
  children: ReactElement;
  setBackground?: boolean;
}) => (
  <Box
    className={`${styles['home-box']} ${
      setBackground && styles['home-background']
    }`}
  >
    <ResponsiveAppBar />
    <Stack className={styles['home-stack']} direction="row">
      <Box className={styles['home-children']}>{children}</Box>
    </Stack>
  </Box>
);

export default HomeContainer;
