import { Typography, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { SESSION_STAGES } from '../../Utils/constants/speechSession';
import styles from './styles.module.scss';

interface CountdownProps {
  count: number;
  onCountdownComplete: () => void;
  sessionStage: string;
}

const Countdown = ({
  count,
  onCountdownComplete,
  sessionStage,
}: CountdownProps) => {
  const isPractice =
    sessionStage === SESSION_STAGES.practiceCountdown ? true : false;
  // additional 2 seconds for practice session countdown
  const [countLeft, setCountLeft] = useState<number>(isPractice ? 5 : count);

  const title = isPractice ? "Great job, now let's practise" : 'Get Ready!';

  useEffect(() => {
    const interval = setInterval(() => {
      setCountLeft((prevCount) => prevCount - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (countLeft === 0) {
      onCountdownComplete();
    }
  }, [countLeft]);

  const countdownClass = countLeft <= count ? 'countdown' : 'hidden';

  return (
    <Stack mt={2} justifyContent="center" alignItems="center" height="100%">
      <Typography className={styles['text-heading']}>{title}</Typography>
      <Typography className={styles[countdownClass]}>
        {/* countLeft > 0 makes sure that 0 does not flash on screen when the countdown ends */}
        {countLeft}
      </Typography>
    </Stack>
  );
};

export default Countdown;
