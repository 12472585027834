import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Avatar,
  CircularProgress,
  Stack,
} from '@mui/material';
import styles from './styles.module.scss';
import Racoon from '../../Assets/Images/single-user-plan.svg';
import Owl from '../../Assets/Images/multi-user-plan.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import serverApi from '../../Utils/api';
import useAuth from '../../Context/Auth';
import CompletionNotifyBar from '../CompletionNotifyBar';
import { validMultiplePlansForTeacher } from '../../Utils/constants/planTypes';

const CategorySelection = () => {
  const navigate = useNavigate();
  const { updateUser, user } = useAuth();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const userObject = user ? JSON.parse(JSON.stringify(user)) : null;

  const handleButtonClick = async (type: string) => {
    try {
      setLoader(true);
      updateUser({ ...userObject, subscription_type: type });
      const result = await serverApi.getSubscriptionPlans(type, user.token);
      setLoader(false);
      navigate('/subscription', { state: { plans: result, flag: false } });
    } catch (error) {
      setLoader(false);
      setError(true);
    }
  };

  const getPlanTypeForMultiplePlan = () => {
    //show ten students plan by default for teacher
    return validMultiplePlansForTeacher.includes(userObject?.plan_type)
      ? userObject.plan_type
      : userObject?.role === 'teacher'
      ? 'ThreeStudentsPlan'
      : 'Multiple Users Plan';
  };

  return (
    <>
      {userObject.user_status === 'activation_pending' ? (
        <Stack className={styles.notification}>
          <CompletionNotifyBar text="Account Created Successfully!" />
        </Stack>
      ) : null}

      <Container maxWidth={false} className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="h2" className={styles.title}>
            Please select Category
          </Typography>
          <Typography className={styles.subtitle}>
            Do you want a single-user or a multiple-user account?
          </Typography>
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              className={styles['button']}
              onClick={() => handleButtonClick(getPlanTypeForMultiplePlan())}
            >
              <Box className={styles.iconContainer}>
                <Avatar src={Owl} className={styles['avatar']} />
              </Box>
              <Box className={styles.textContainer}>
                <Typography variant="subtitle1" className={styles.buttonText}>
                  {userObject && userObject.role === 'teacher'
                    ? `Multiple Students`
                    : `Family`}
                </Typography>
                <Typography variant="body2" className={styles.buttonTextSmall}>
                  I want a multiple-user account
                </Typography>
              </Box>
              <ArrowForwardIosIcon className={styles.arrow} />
            </Button>
            <Button
              variant="contained"
              className={styles.button}
              onClick={() => handleButtonClick('Single User Plan')} //common for parent and teacher
            >
              <Box className={styles.iconContainer}>
                <Avatar src={Racoon} className={styles['avatar']} />
              </Box>
              <Box className={styles.textContainer}>
                <Typography variant="subtitle1" className={styles.buttonText}>
                  {userObject && userObject.role === 'teacher'
                    ? `Single Student`
                    : `Child`}
                </Typography>
                <Typography variant="body2" className={styles.buttonTextSmall}>
                  I want a single-user account
                </Typography>
              </Box>
              <ArrowForwardIosIcon className={styles.arrow} />
            </Button>
          </Box>
        </Box>
        {error ? (
          <Typography variant="h4" className={styles.subtitle}>
            Try Again!
          </Typography>
        ) : null}
        <Box className={styles.loader}>
          {loader ? <CircularProgress></CircularProgress> : null}
        </Box>
      </Container>
    </>
  );
};

export default CategorySelection;
