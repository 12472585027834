import { useNavigate } from 'react-router-dom';
import { Link, Stack, Typography } from '@mui/material';
import HourGlass from '../../Assets/Images/hourglass.svg';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

interface ResultListItemProps {
  sessionNumber: number;
  session: any;
}

const ResultsListItem = ({ sessionNumber, session }: ResultListItemProps) => {
  const navigate = useNavigate();
  const [date, setDate] = useState('' as string);
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  useEffect(() => {
    const isoTimestamp = session.end_time
      ? session.end_time
      : session.start_time;
    const date = new Date(isoTimestamp);
    const dayOfWeek = daysOfWeek[date.getUTCDay()];

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = monthsOfYear[date.getUTCMonth()];

    // const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear();

    const formattedDate = `${dayOfWeek} ${day}/${month}/${year}`;
    setDate(formattedDate);
  }, []);

  const handleClick = () => {
    navigate('/result-session', {
      state: { session, sessionNumber: sessionNumber },
    });
  };

  return (
    <Stack className={styles['list-item-stack']}>
      <Stack direction="row" className={styles['heading-stack']}>
        <Link onClick={handleClick} className={styles['link']}>
          <Typography variant="h4" className={styles['session-number']}>
            {`Session # ${sessionNumber}`}
          </Typography>
        </Link>
        <Typography variant="body2" className={styles['time']}>
          {date}
        </Typography>
      </Stack>
      <Stack direction="row" className={styles['session-result-stack']}>
        <Stack
          direction="row"
          spacing={1}
          className={styles['session-completion-time-stack']}
        >
          <img
            src={HourGlass}
            alt="time-hour-glass"
            className={styles['hour-glass-img']}
          />
          <Typography variant="h5" className={styles['text']}>
            {`Completed in 6 minutes`}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={{ xs: 1, md: 3 }}
          className={styles['score-stack']}
        >
          <Typography variant="h5" className={styles['score-heading']}>
            Your Score
          </Typography>
          <Typography variant="h4" className={styles['score']}>
            <span>{session.totalCorrect}</span>/{session.totalWords}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ResultsListItem;
