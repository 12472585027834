import { useEffect, useState, useRef } from 'react';
import { Stack, CircularProgress } from '@mui/material';
import ResultsSessionList from '../../Components/ResultsList';
import styles from './styles.module.scss';
import serverApi from '../../Utils/api';
import useAuth from '../../Context/Auth';
import ProfileHeader from '../../Components/ProfileHeader';

const Results = () => {
  const { user, selectedProfile } = useAuth();
  const [sessions, setSessions] = useState<any>([]);
  const currentProfile = selectedProfile;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [loader2, setLoader2] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const observerTarget = useRef<HTMLDivElement | null>(null);

  const fetchData = async () => {
    if (loader) return;
    setLoader(true);
    setLoader2(true);
    try {
      const response = await serverApi.getAllSessions(
        currentProfile.uuid,
        user.token,
        pageNumber,
        5
      );
      const newSessions = response.data.results.sessions;
      setSessions((prevSessions: any[]) => [
        ...(prevSessions || []),
        ...newSessions,
      ]);
      const isLastPage = pageNumber >= response.data.results.totalPages;
      setTotalSessions(response.data.results.total);
      setHasMore(!isLastPage);

      if (!isLastPage) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
      setLoader2(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loader) {
          fetchData();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, pageNumber, hasMore, loader]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Stack
      spacing={{ xs: 2, sm: 3, md: 4 }}
      className={styles['results-screen']}
    >
      <ProfileHeader profile={currentProfile} progressBar={true} />
      <Stack className={styles['list']}>
        {loader && pageNumber === 1 ? (
          <CircularProgress color="success" />
        ) : (
          <ResultsSessionList
            sessions={sessions}
            totalSessions={totalSessions}
            showButton={showButton}
          />
        )}
        <Stack ref={observerTarget}></Stack>
        {pageNumber !== 1 && loader2 && <CircularProgress color="success" />}
      </Stack>
    </Stack>
  );
};

export default Results;
