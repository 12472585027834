import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Container,
  Box,
  Stack,
  Button,
  Typography,
  Avatar,
  Tooltip,
  Popover,
  ListItemButton,
  ListItemIcon,
  SvgIcon,
  ListItemText,
  List,
  IconButton,
} from '@mui/material';
import EnterPasswordModal from '../EnterPasswordModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MwpLogo from '../../Assets/Images/logo.svg';
import AvatarImg from '../../Assets/Images/avatar.svg';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';
import LogoutIcon from '../../Assets/Images/LogoutIcon';
import SettingsIcon from '../../Assets/SettingsIcon';

const ResponsiveAppBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePasswordModalClose = () => setIsPasswordModalOpen(false);

  const handlePasswordClick = () => {
    setIsPasswordModalOpen(false);
    navigate('/profile');
  };

  const handleProfileClick = () => {
    if (location.pathname !== '/profile') {
      setIsPasswordModalOpen(true);
      setAnchorEl(null);
    }
  };

  return (
    <AppBar id={styles['appbar']}>
      <Container maxWidth={false} className={styles['appbar-container']}>
        <Box className={styles['logo-box']} onClick={() => navigate('/home')}>
          <Box component="img" src={MwpLogo} className={styles['logo-img']} />
          <Typography variant="h4" color="primary">
            MyWordPal
          </Typography>
        </Box>

        {Object.keys(user).length !== 0 && user.email_verified && (
          <Box>
            <IconButton className={styles['pdf-icon']}>
              <a
                href={require('../../Assets/Documents/MyWordPal-tutorial.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                className={styles['pdf-link']}
              >
                <HelpOutlineIcon />
              </a>
            </IconButton>
            <Tooltip title="Open settings">
              <Button
                className={styles['settings-btn']}
                variant="outlined"
                color="secondary"
                onClick={handleClick}
              >
                <Avatar src={AvatarImg} className={styles['avatar']}></Avatar>
                <Stack className={styles['profile-stack']}>
                  <Typography variant="body1" color="text.primary">
                    {`${user.full_name}`}
                  </Typography>
                  <Typography variant="body3" color="text.secondary">
                    {user.email}
                  </Typography>
                </Stack>
              </Button>
            </Tooltip>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              className={styles['popover']}
            >
              <List>
                <ListItemButton
                  className={styles['list-button']}
                  onClick={handleProfileClick}
                >
                  <ListItemIcon>
                    <SvgIcon isFill={false} component={SettingsIcon} />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </ListItemButton>
                <ListItemButton
                  className={styles['list-button']}
                  onClick={logout}
                >
                  <ListItemIcon>
                    <SvgIcon component={LogoutIcon} />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItemButton>
              </List>
            </Popover>
          </Box>
        )}
      </Container>
      <EnterPasswordModal
        isOpen={isPasswordModalOpen}
        onClose={handlePasswordModalClose}
        onSuccess={handlePasswordClick}
      />
    </AppBar>
  );
};

export default ResponsiveAppBar;
