import { Box, Container, Stack } from '@mui/material';
import LoginBox from '../../Components/LoginBox';
import BackGroundWeb from '../../Assets/Images/splash-screen-web.svg';
import styles from './styles.module.scss';

const Login = () => {
  return (
    <Container maxWidth={false} className={styles['login-container']}>
      <Box display={{ xs: 'none', xl: 'block' }}>
        <Stack className={styles['base-stack']}>
          <img
            src={BackGroundWeb}
            alt="background-image"
            className={styles['background-img']}
          />
          <Box className={styles['login-box']}>
            <LoginBox />
          </Box>
        </Stack>
      </Box>
      <Box display={{ xs: 'block', xl: 'none' }}>
        <LoginBox />
      </Box>
    </Container>
  );
};

export default Login;
