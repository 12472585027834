import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Typography, Box } from '@mui/material';
import styles from './styles.module.scss'; // Make sure you have a SCSS file for styling
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const PaymentForm = ({
  buttonLabel,
  prepareApiPayload,
  makeApiCall,
  navigator,
}: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();
  const data = location.state?.result;

  const [error, setError] = useState<string | undefined>(undefined);
  const [sendingPaymentData, setSendingPaymentData] = useState(false);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setError(undefined);

    if (!stripe || !elements) {
      setError('Stripe has not loaded yet. Please try again.');
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement) {
      try {
        setSendingPaymentData(true);
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (error) {
          throw new Error(error.message);
        }

        const apiPayload = prepareApiPayload(paymentMethod.id, data);

        const response = await makeApiCall(apiPayload);

        if (response.error) {
          throw new Error(`Error: ${response.error.message}`);
        } else {
          await navigator();
        }
      } catch (outerError: any) {
        setError(outerError.message);
      } finally {
        setSendingPaymentData(false);
      }
    }
  };
  const cardStyle = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
    showIcon: true,
  };

  return (
    <Box className={styles.container}>
      <Typography variant="h3" className={styles.title}>
        Enter Payment Details
      </Typography>
      <Box className={styles.content}>
        <form onSubmit={handleSubmit}>
          {error && (
            <Typography
              variant="h6"
              color="error"
              className={styles.errorMessage}
            >
              {error}
            </Typography>
          )}
          <Box className={styles.paymentDetails}>
            <Typography variant="h5">Card number</Typography>
            <CardNumberElement
              options={cardStyle}
              className={styles.StripeElement}
            />
            <Typography variant="h5">Expiration</Typography>
            <CardExpiryElement className={styles.StripeElement} />

            <Typography variant="h5">CVC</Typography>
            <CardCvcElement
              options={cardStyle}
              className={styles.StripeElement}
            />
          </Box>
          {sendingPaymentData ? (
            <button disabled={true} className={styles.submitButton}>
              Processing...
            </button>
          ) : (
            <button
              type="submit"
              disabled={!stripe}
              className={styles.submitButton}
            >
              {buttonLabel}
            </button>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default PaymentForm;
