import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';

const SplashScreenBox = () => {
  const navigate = useNavigate();
  const { setError } = useAuth();

  return (
    <Stack spacing={{ xs: 1, sm: 2, lg: 3 }} className={styles['base-stack']}>
      <Stack spacing={{ xs: 1, sm: 2, lg: 2 }} className={styles['text-stack']}>
        <Typography variant="h2" color="primary">
          MyWordPal
        </Typography>
        <Typography variant="h5" textAlign="center">
          Empowering dyslexic learners to reach their full potential.
        </Typography>
      </Stack>
      <Stack
        spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}
        className={styles['button-stack']}
      >
        <Button
          variant="contained"
          type="submit"
          fullWidth
          size="large"
          color="primary"
          className={styles['button']}
          onClick={() => {
            setError('');
            navigate('/signup');
          }}
        >
          Get Started
        </Button>
        <Button
          variant="outlined"
          type="submit"
          fullWidth
          size="large"
          color="secondary"
          className={styles['button']}
          onClick={() => {
            setError('');
            navigate('/login');
          }}
        >
          Login to your Account
        </Button>
      </Stack>
    </Stack>
  );
};

export default SplashScreenBox;
