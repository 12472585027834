import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';
import { AxiosError } from 'axios';

const EmailVerification = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationToken = queryParams.get('verificationToken');
  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();
  const { updateEmailVerified } = useAuth();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (verificationToken) {
          const response = await serverApi.verifyEmail(verificationToken);
          setStatusMessage(response.data.message);
          if (response.status === 201) {
            if (localStorage.getItem('user') !== null) {
              setVerificationStatus('success');
              updateEmailVerified(true);
              setLoading(false);
              navigate('/category-selection');
            } else {
              setVerificationStatus('success');
              setLoading(false);
              navigate('/login');
            }
          }
        } else {
          setStatusMessage('Invalid verification token');
          setVerificationStatus('error');
        }
      } catch (error) {
        console.error('Error while sending verification email', error);
        if (error instanceof AxiosError)
          setStatusMessage(error.response?.data.message);
        setLoading(false);
        setVerificationStatus('error');
      }
    };

    verifyEmail();
  }, []);

  return (
    <Container className={styles['verify-email-container']}>
      {loading && <Typography variant="h6">Loading...</Typography>}
      {!loading && verificationStatus === 'success' && (
        <Typography variant="h2">{statusMessage}</Typography>
      )}
      {!loading && verificationStatus === 'error' && (
        <Typography variant="h2">{statusMessage}</Typography>
      )}
    </Container>
  );
};

export default EmailVerification;
