import { Box, Stack, Typography } from '@mui/material';
import GreenRoundedCheckMark from '../../Assets/Images/green-rounded-checkmark.svg';
import styles from './styles.module.scss';

const CompletionNotifyBar = ({ text }: { text: string }) => {
  return (
    <Box className={styles['completion-notify-bar']}>
      <Stack spacing={1} className={styles['notify-stack']}>
        <img
          src={GreenRoundedCheckMark}
          alt="green rounded checkmark"
          className={styles['checkmark-icon']}
        />
        <Typography variant="h4" className={styles['notify-text']}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default CompletionNotifyBar;
