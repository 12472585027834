import { Avatar, Stack, Typography } from '@mui/material';
import WrongIcon from '../../Assets/Images/cross-red-background.svg';
import RightIcon from '../../Assets/Images/circle-green-checkmark.svg';
import SemiCorrectIcon from '../../Assets/Images/circle-yellow-dash.svg';
import Raccoon from '../../Assets/Images/Avatars/raccoon.svg';
import styles from './styles.module.scss';
import { Word } from '../../Pages/SingleResult';
import { useEffect, useState } from 'react';
import WordReader from '../WordReader';
import useAuth from '../../Context/Auth';

interface SingleResultListItemProps {
  word: Word;
  username: string;
}

const AudioPlayer = ({
  src,
  onUserPlayerComplete,
}: {
  src: any;
  onUserPlayerComplete: () => void;
}) => {
  useEffect(() => {
    const audio = new Audio(src);
    audio.addEventListener('ended', onUserPlayerComplete);

    return () => {
      audio.removeEventListener('ended', onUserPlayerComplete);
      audio.src = '';
    };
  }, [src, onUserPlayerComplete]);

  return <audio controls autoPlay src={src} style={{ display: 'none' }} />;
};

const SingleResultListItem = (props: SingleResultListItemProps) => {
  const { word, username } = props;
  const [playUserVoice, setPlayUserVoice] = useState(false);
  const [playMWPVoice, setMWPVoice] = useState(false);
  const [audioUrl, setAudioUrl] = useState<any>();
  const { selectedProfile, avatars } = useAuth();

  const createAudioUrl = () => {
    const audioData = new Uint8Array(word.sound.data);
    const blob = new Blob([audioData], { type: 'audio/wav' });
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  const onClickUserVoice = () => {
    if (word.sound) {
      createAudioUrl();
      setPlayUserVoice(true);
    }
  };

  const onClickMWPVoice = () => {
    setMWPVoice(true);
  };

  const onUserPlayerComplete = () => {
    setPlayUserVoice(false);
  };

  const onMWPPlayerComplete = () => {
    setMWPVoice(false);
  };

  const getImageById = (id: string) => {
    const avatar = avatars.find((avatar) => avatar.uuid === id);
    return avatar && avatar.image;
  };

  return (
    <>
      <Stack className={styles['list-item-stack']}>
        <Stack direction="row" className={styles['heading-stack']}>
          {word.result === 'correct' && (
            <img
              src={RightIcon}
              alt="wrong-answer-image"
              className={styles['icon-img']}
            />
          )}
          {word.result === 'wrong' && (
            <img
              src={WrongIcon}
              alt="wrong-answer-image"
              className={styles['icon-img']}
            />
          )}
          {word.result === 'semi-correct' && (
            <img
              src={SemiCorrectIcon}
              alt="wrong-answer-image"
              className={styles['icon-img']}
            />
          )}
          <Typography variant="h4" className={styles['word']}>
            {word.value}
          </Typography>
        </Stack>
        <Stack direction="row" className={styles['sounds-stack']}>
          <Stack
            onClick={() => onClickUserVoice()}
            direction="row"
            className={styles['sound']}
          >
            <Avatar
              src={getImageById(selectedProfile.avatar_uuid)}
              className={styles['avatar']}
            />
            <Typography variant="h5" className={styles['username']}>
              {username}&apos;s Voice
            </Typography>
          </Stack>
          <Stack
            onClick={onClickMWPVoice}
            direction="row"
            className={styles['sound']}
          >
            <Avatar src={Raccoon} className={styles['avatar']} />
            <Typography variant="h5" className={styles['username']}>
              MWP Voice
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {playMWPVoice && (
        <WordReader
          separatedWord={word.value}
          onComplete={onMWPPlayerComplete}
        />
      )}

      {audioUrl && playUserVoice && (
        <AudioPlayer
          onUserPlayerComplete={onUserPlayerComplete}
          src={audioUrl}
        />
      )}
    </>
  );
};

export default SingleResultListItem;
