import { useState } from 'react';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import useAuth, { IProfile } from '../../Context/Auth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ordinalSuffix from '../../Utils/common/ordinalSuffix';
import star from '../../Assets/Images/Badges/star.svg';
import bag from '../../Assets/Images/Badges/bag.svg';
import telescope from '../../Assets/Images/Badges/telescope.svg';
import trophy from '../../Assets/Images/Badges/trophy.svg';
import medal from '../../Assets/Images/Badges/medal.svg';
import rocket from '../../Assets/Images/Badges/rocket.svg';
import emptyStar from '../../Assets/Images/Badges/empty-star.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../ProgressBar';
import EnterPasswordModal from '../EnterPasswordModal';

const ProfileHeader = ({
  profile,
  progressBar,
  onHome,
  handleArrowDownClick,
}: {
  profile: IProfile;
  progressBar?: boolean;
  onHome?: boolean;
  handleArrowDownClick?: () => void;
}) => {
  const { avatars } = useAuth();
  const badges = [
    {
      level: 0,
      title: 'Just Started',
      wordsStarted: 0,
      wordsRequired: 0,
      image: emptyStar,
    },
    {
      level: 1,
      title: 'Ready Raccoon',
      wordsStarted: 0,
      wordsRequired: 20,
      image: star,
    },
    {
      level: 2,
      title: 'Daring Deer',
      wordsStarted: 20,
      wordsRequired: 50,
      image: medal,
    },
    {
      level: 3,
      title: 'Blazing Bird',
      wordsStarted: 50,
      wordsRequired: 100,
      image: bag,
    },
    {
      level: 4,
      title: 'Outstanding Owl',
      wordsStarted: 100,
      wordsRequired: 200,
      image: trophy,
    },
    {
      level: 5,
      title: 'Masterful Mouse',
      wordsStarted: 200,
      wordsRequired: 500,
      image: telescope,
    },
    {
      level: 6,
      title: 'Fluent Fox',
      wordsStarted: 500,
      wordsRequired: 1000,
      image: rocket,
    },
  ];

  const location = window.location.pathname;
  const navigate = useNavigate();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const getImageById = (id: string) => {
    const avatar = avatars.find((avatar) => avatar.uuid === id);
    return avatar && avatar.image;
  };

  const handleBoxClick = () => {
    if (location === '/student-progress') {
      return;
    }
    navigate('/student-progress');
  };

  const handlePasswordModalClose = () => setIsPasswordModalOpen(false);

  const handleProfilesClick = () => {
    setIsPasswordModalOpen(false);
    if (handleArrowDownClick) handleArrowDownClick();
  };

  return (
    <Stack className={styles['profile-header-container']}>
      <Stack className={styles['profile-header-content']}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h3" className={styles['greeting-text']}>
            {`Hi, ${profile.profile_name?.substring(0, 20)}`}
          </Typography>
          {onHome && (
            <IconButton
              color="primary"
              className={styles['dropdown-icon']}
              onClick={() => setIsPasswordModalOpen(true)}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          <Stack className={styles['profile-box']}>
            <Stack direction="row" alignItems="center" width="100%">
              <Avatar
                src={getImageById(profile.avatar_uuid)}
                className={styles['profile-image']}
              ></Avatar>
              <Stack className={styles['profile-stack']}>
                <Typography variant="body1" color="text.secondary">
                  Your Level:
                </Typography>
                <Typography variant="h4" className={styles['profile-level']}>
                  {profile.current_level}
                  <span>{ordinalSuffix(profile.current_level)}</span>
                </Typography>
                {progressBar && (
                  <ProgressBar
                    value={profile.no_of_mastered_cards}
                    lowerValue={
                      badges[profile.current_level ? profile.current_level : 1]
                        .wordsStarted
                    }
                    uppervalue={
                      badges[profile.current_level ? profile.current_level : 0]
                        .wordsRequired
                    }
                    onHome
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack className={styles['rewards-box']} onClick={handleBoxClick}>
            <Stack className={styles['rewards-stack']}>
              <Typography
                variant="body1"
                color="text.secondary"
                className={styles['earned-text']}
              >
                Earned
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="h4" className={styles['rewards-text']}>
                  {
                    badges[
                      profile.current_level ? profile.current_level - 1 : 0
                    ].title
                  }
                </Typography>
                {progressBar && (
                  <ArrowForwardIosIcon className={styles.arrow} />
                )}
              </Stack>
            </Stack>
            <Avatar
              src={
                badges[profile.current_level ? profile.current_level - 1 : 0]
                  .image
              }
              className={styles['reward-sticker']}
            />
          </Stack>
        </Stack>
      </Stack>
      <EnterPasswordModal
        isOpen={isPasswordModalOpen}
        onClose={handlePasswordModalClose}
        onSuccess={handleProfilesClick}
      />
    </Stack>
  );
};

export default ProfileHeader;
