import { Route, Routes } from 'react-router-dom';
import { PublicRoute, PrivateRoute, SubscriptionRoute } from './RouteType';
import { AuthProvider } from '../Context/Auth';
import SplashScreen from '../Pages/SplashScreen';
import Login from '../Pages/Login';
import Home from '../Pages/Home';
import Results from '../Pages/Results';
import SingleResult from '../Pages/SingleResult';
import Profile from '../Pages/Profile';
import SubscriptionScreen from '../Components/Subscription';
import PaymentDetails from '../Components/PaymentDetails';
import CategorySelection from '../Components/CategorySelection';
import PaymentRoute from '../Components/PaymentRoute';
import UpdateDetailsRoute from '../Components/UpdateDetailsRoute';
import Signup from '../Pages/Signup';
import ForgotPassword from '../Pages/ForgotPassword';
import AddStudent from '../Pages/AddStudent';
import EmailVerification from '../Pages/EmailVerification';
import Tutorial from '../Pages/Tutorial';
import StudentProfiles from '../Pages/StudentProfiles';
import StudentProgress from '../Pages/StudentProgress';
import SessionFinished from '../Pages/SessionFinished';
import MatchingGame from '../Components/MatchingGame';

const AppRouter = () => (
  <AuthProvider>
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <SplashScreen />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/*"
        element={
          <PublicRoute>
            <Signup />
          </PublicRoute>
        }
      />
      <Route path="game" element={<MatchingGame></MatchingGame>} />
      <Route
        path="/verification"
        element={
          <PublicRoute>
            <EmailVerification />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password/*"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/add-student"
        element={
          <PrivateRoute>
            <AddStudent />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-student"
        element={
          <PrivateRoute>
            <AddStudent />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/tutorial"
        element={
          <PrivateRoute>
            <Tutorial />
          </PrivateRoute>
        }
      />
      <Route
        path="/session-finished"
        element={
          <PrivateRoute setBackground>
            <SessionFinished />
          </PrivateRoute>
        }
      />
      <Route
        path="/results"
        element={
          <PrivateRoute>
            <Results />
          </PrivateRoute>
        }
      />
      <Route
        path="/result-session"
        element={
          <PrivateRoute>
            <SingleResult />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment-details"
        element={
          <PrivateRoute setBackground>
            <PaymentDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/student-profiles"
        element={
          <PrivateRoute>
            <StudentProfiles />
          </PrivateRoute>
        }
      />
      <Route
        path="/student-progress"
        element={
          <PrivateRoute>
            <StudentProgress />
          </PrivateRoute>
        }
      />
      <Route
        path="/category-selection"
        element={
          <SubscriptionRoute setBackground>
            <CategorySelection />
          </SubscriptionRoute>
        }
      />
      <Route
        path="/subscription"
        element={
          <SubscriptionRoute setBackground>
            <SubscriptionScreen />
          </SubscriptionRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <SubscriptionRoute setBackground>
            <PaymentRoute />
          </SubscriptionRoute>
        }
      />
      <Route
        path="/update-details"
        element={
          <SubscriptionRoute setBackground>
            <UpdateDetailsRoute />
          </SubscriptionRoute>
        }
      />
    </Routes>
  </AuthProvider>
);

export default AppRouter;
