import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  CssBaseline,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@mui/material';
import HomeIcon from '../../Assets/Images/HomeIcon';
import ResultIcon from '../../Assets/Images/ResultIcon';
import LogoutIcon from '../../Assets/Images/LogoutIcon';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';

export default function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [value, setValue] = useState('/home');

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <Box display={{ lg: 'none' }}>
      <CssBaseline />
      <Paper className={styles['navbar-paper']}>
        <BottomNavigation
          className={styles['navbar-bottom']}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            if (newValue === '/') logout();
            else navigate(newValue);
          }}
        >
          <BottomNavigationAction
            label="Home"
            value="/home"
            icon={<HomeIcon isFill={value.startsWith('/home')} />}
          />
          <BottomNavigationAction
            label="Results"
            value="/results"
            icon={<ResultIcon isFill={value.startsWith('/results')} />}
          />
          <BottomNavigationAction
            label="Logout"
            value="/"
            icon={<LogoutIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
