import { useEffect, useState } from 'react';
import { IconButton, Modal, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import ProfileHeader from '../../Components/ProfileHeader';
import useAuth, { IProfile } from '../../Context/Auth';
import ProgressBadgeBox from '../../Components/ProgressBadgeBox';
import ChestModal from '../../Components/ModalChest';
import BadgeModal from '../../Components/ModalBadge';
import star from '../../Assets/Images/Badges/star.svg';
import bag from '../../Assets/Images/Badges/bag.svg';
import telescope from '../../Assets/Images/Badges/telescope.svg';
import trophy from '../../Assets/Images/Badges/trophy.svg';
import medal from '../../Assets/Images/Badges/medal.svg';
import rocket from '../../Assets/Images/Badges/rocket.svg';
import styles from './styles.module.scss';
import serverApi from '../../Utils/api';

const StudentProgress = () => {
  const [isChestModalOpen, setIsChestModalOpen] = useState<boolean>(false);
  const [isBadgeModalOpen, setIsBadgeModalOpen] = useState<boolean>(false);
  const { user, updateProfiles, selectedProfile, setCurrentProfile } =
    useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const chest = location.state || false;
  const userLevel = selectedProfile.current_level || 1;

  useEffect(() => {
    if (chest) {
      handleChestModalOpen();
    }
  }, [chest]);

  const reload = async () => {
    const profiles = await serverApi.getProfiles(user.token);
    const profilesStore = profiles.data.profiles;
    setCurrentProfile(
      profilesStore.find(
        (profile: IProfile) => profile.uuid === selectedProfile.uuid
      )
    );
    updateProfiles(profilesStore);
  };

  useEffect(() => {
    reload();
  }, []);

  const handleClick = () => {
    navigate(-1);
  };

  const handleChestModalOpen = () => {
    setIsChestModalOpen(true);
  };

  const handleChestModalClose = () => {
    setIsChestModalOpen(false);
  };

  const handleBadgeModalOpen = () => {
    setIsBadgeModalOpen(true);
  };

  const handleBadgeModalClose = () => {
    setIsBadgeModalOpen(false);
  };

  const badges = [
    {
      level: 1,
      title: 'Ready Raccoon',
      wordsStarted: 0,
      wordsRequired: 20,
      image: star,
    },
    {
      level: 2,
      title: 'Daring Deer',
      wordsStarted: 20,
      wordsRequired: 50,
      image: medal,
    },
    {
      level: 3,
      title: 'Blazing Bird',
      wordsStarted: 50,
      wordsRequired: 100,
      image: bag,
    },
    {
      level: 4,
      title: 'Outstanding Owl',
      wordsStarted: 100,
      wordsRequired: 200,
      image: trophy,
    },
    {
      level: 5,
      title: 'Masterful Mouse',
      wordsStarted: 200,
      wordsRequired: 500,
      image: telescope,
    },
    {
      level: 6,
      title: 'Fluent Fox',
      wordsStarted: 500,
      wordsRequired: 1000,
      image: rocket,
    },
  ];

  return (
    <Stack className={styles['student-progress-container']}>
      <Modal
        open={isChestModalOpen}
        onClose={handleChestModalClose}
        className={styles['modal']}
      >
        <ChestModal
          handleClose={handleChestModalClose}
          openNextModal={handleBadgeModalOpen}
        />
      </Modal>
      <Modal
        open={isBadgeModalOpen}
        onClose={handleBadgeModalClose}
        className={styles['modal']}
      >
        <BadgeModal
          handleClose={handleBadgeModalClose}
          badge={badges[userLevel - 2]}
        />
      </Modal>
      <Stack spacing={5} className={styles['student-progress-content']}>
        <Stack spacing={1} direction="row" className={styles['heading']}>
          <IconButton onClick={handleClick} className={styles['back-btn']}>
            <img src={BackArrow} alt="back" className={styles['back-img']} />
          </IconButton>
          <Typography variant="h4" className={styles['page-name']}>
            Progress
          </Typography>
        </Stack>
        <ProfileHeader profile={selectedProfile} />
        <Stack spacing={2} className={styles['content-stack']}>
          <Stack
            spacing={2}
            direction={{ xs: 'column', lg: 'row' }}
            className={styles['content-row']}
          >
            <ProgressBadgeBox
              badgeName={badges[0].title}
              lowerBadgeWords={0}
              badgeWords={badges[0].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[0].image}
              current={selectedProfile.current_level === 1}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 1
                  : false
              }
            />
            <ProgressBadgeBox
              badgeName={badges[1].title}
              lowerBadgeWords={badges[0].wordsRequired}
              badgeWords={badges[1].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[1].image}
              current={selectedProfile.current_level === 2}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 2
                  : false
              }
            />
          </Stack>
          <Stack
            spacing={2}
            direction={{ xs: 'column', lg: 'row' }}
            className={styles['content-row']}
          >
            <ProgressBadgeBox
              badgeName={badges[2].title}
              lowerBadgeWords={badges[1].wordsRequired}
              badgeWords={badges[2].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[2].image}
              current={selectedProfile.current_level === 3}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 3
                  : false
              }
            />
            <ProgressBadgeBox
              badgeName={badges[3].title}
              lowerBadgeWords={badges[2].wordsRequired}
              badgeWords={badges[3].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[3].image}
              current={selectedProfile.current_level === 4}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 4
                  : false
              }
            />
          </Stack>
          <Stack
            spacing={2}
            direction={{ xs: 'column', lg: 'row' }}
            className={styles['content-row']}
          >
            <ProgressBadgeBox
              badgeName={badges[4].title}
              lowerBadgeWords={badges[3].wordsRequired}
              badgeWords={badges[4].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[4].image}
              current={selectedProfile.current_level === 5}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 5
                  : false
              }
            />
            <ProgressBadgeBox
              badgeName={badges[5].title}
              lowerBadgeWords={badges[4].wordsRequired}
              badgeWords={badges[5].wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badges[5].image}
              current={selectedProfile.current_level === 6}
              completed={
                selectedProfile.current_level
                  ? selectedProfile.current_level > 6
                  : false
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StudentProgress;
