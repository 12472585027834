const HomeIcon = ({ isFill }: { isFill?: boolean }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5481 20.768L1.45312 13.198C4.3883 8.40176 6.7845 5.65344 11.9792 2.0831C12.594 1.66058 13.4063 1.66058 14.0211 2.0831C19.2158 5.65344 21.612 8.40176 24.5472 13.198L24.4522 20.768C24.2531 22.5414 22.8388 23.9548 21.0661 24.1603C15.9071 24.7583 10.0932 24.7583 4.93423 24.1603C3.16155 23.9548 1.7472 22.5414 1.5481 20.768Z"
      fill={isFill ? '#54B948' : 'white'}
      fillOpacity="0.2"
    />
    <path
      d="M1.5481 20.768L1.45312 13.198C4.3883 8.40176 6.7845 5.65344 11.9792 2.0831C12.594 1.66058 13.4063 1.66058 14.0211 2.0831C19.2158 5.65344 21.612 8.40176 24.5472 13.198L24.4522 20.768C24.2531 22.5414 22.8388 23.9548 21.0661 24.1603C15.9071 24.7583 10.0932 24.7583 4.93423 24.1603C3.16155 23.9548 1.7472 22.5414 1.5481 20.768Z"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.78571"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
