import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import owl from '../../Assets/Images/Avatars/owl.svg';
import raccoon from '../../Assets/Images/Avatars/raccoon.svg';
import RightGreenArrow from '../../Assets/Images/right-green-arrow.svg';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import styles from './styles.module.scss';

const CreateAccountBox = ({
  fullWidth,
  onBoxClick,
}: {
  fullHeight?: boolean;
  fullWidth?: boolean;
  onBoxClick?: (role: string) => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleBoxClick = (role: string) => {
    if (onBoxClick) {
      onBoxClick(role);
    }
    navigate('/signup/spelling-selection');
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Stack className={styles['create-account-box']}>
      <Stack width={fullWidth ? '90%' : '90%'} alignItems="center" spacing={10}>
        {isSmallScreen ? (
          <Stack direction="row" className={styles['small-screen-heading']}>
            <IconButton
              onClick={handleBackClick}
              className={styles['back-btn']}
            >
              <img src={BackArrow} alt="back" className={styles['back-img']} />
            </IconButton>
            <Typography variant="h4" className={styles['page-name']}>
              Create an Account
            </Typography>
          </Stack>
        ) : (
          <Typography variant="h3" className={styles['header-text']}>
            Create an Account
          </Typography>
        )}
        <Stack spacing={4} width="100%" className={styles['box-stack']}>
          <Box
            className={styles['content-box']}
            onClick={() => handleBoxClick('teacher')}
          >
            <Stack direction="row" className={styles['content-stack']}>
              <Avatar src={owl} className={styles['avatar-image']}></Avatar>
              <Stack spacing={1} className={styles['text-stack']}>
                <Typography variant="h4" className={styles['heading']}>
                  I am a Teacher
                </Typography>
                <Typography variant="body2" className={styles['sub-text']}>
                  I want to create an account for my student(s).
                </Typography>
              </Stack>
              <img
                src={RightGreenArrow}
                alt=""
                className={styles['arrow-icon']}
              />
            </Stack>
          </Box>

          <Box
            className={styles['content-box']}
            onClick={() => handleBoxClick('parent')}
          >
            <Stack direction="row" className={styles['content-stack']}>
              <Avatar src={raccoon} className={styles['avatar-image']}></Avatar>
              <Stack className={styles['text-stack']}>
                <Typography variant="h4" className={styles['heading']}>
                  I am a Parent
                </Typography>
                <Typography variant="body2" className={styles['sub-text']}>
                  I want to create an account for my child/children.
                </Typography>
              </Stack>
              <img
                src={RightGreenArrow}
                alt=""
                className={styles['arrow-icon']}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateAccountBox;
