import {
  Stack,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import starTilted from '../../Assets/Images/star-tilted.svg';
import ChestClose from '../../Assets/Images/chest-close.svg';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import ProgressBadgeBox from '../../Components/ProgressBadgeBox';
import star from '../../Assets/Images/Badges/star.svg';
import bag from '../../Assets/Images/Badges/bag.svg';
import telescope from '../../Assets/Images/Badges/telescope.svg';
import trophy from '../../Assets/Images/Badges/trophy.svg';
import medal from '../../Assets/Images/Badges/medal.svg';
import rocket from '../../Assets/Images/Badges/rocket.svg';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import serverApi from '../../Utils/api';
import useAuth, { IProfile } from '../../Context/Auth';

const SessionFinished = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, selectedProfile, setCurrentProfile, updateProfiles } =
    useAuth();
  const { session_id } = location.state;
  const [upgrade, setUpgrade] = useState(false);
  const [totalCorrectWords, setTotalCorrectWords] = useState(0);
  const [totalWordsAttempted, setTotalWordsAttempted] = useState(0);
  const [sessionDetails, setSessionDetails] = useState<any>();
  const [sessionNumber, setSessionNumber] = useState(0);
  const [loader, setLoader] = useState(false);
  const session = {
    uuid: session_id,
  };

  const badges = [
    {
      level: 1,
      title: 'Ready Raccoon',
      wordsStarted: 0,
      wordsRequired: 20,
      image: star,
    },
    {
      level: 2,
      title: 'Daring Deer',
      wordsStarted: 20,
      wordsRequired: 50,
      image: medal,
    },
    {
      level: 3,
      title: 'Blazing Bird',
      wordsStarted: 50,
      wordsRequired: 100,
      image: bag,
    },
    {
      level: 4,
      title: 'Outstanding Owl',
      wordsStarted: 100,
      wordsRequired: 200,
      image: trophy,
    },
    {
      level: 5,
      title: 'Masterful Mouse',
      wordsStarted: 200,
      wordsRequired: 500,
      image: telescope,
    },
    {
      level: 6,
      title: 'Fluent Fox',
      wordsStarted: 500,
      wordsRequired: 1000,
      image: rocket,
    },
  ];

  const [badge, setBadge] = useState(badges[0]);

  const reload = async () => {
    const profiles = await serverApi.getProfiles(user.token);
    const profilesStore = profiles.data.profiles;
    setCurrentProfile(
      profilesStore.find(
        (profile: IProfile) => profile.uuid === selectedProfile.uuid
      )
    );
    updateProfiles(profilesStore);
  };

  const getSession = async () => {
    setLoader(true);
    try {
      const response = await serverApi.getAllSessions(
        selectedProfile.uuid,
        user.token,
        1
      );
      const sessions = response.data.results.sessions;
      const session = sessions.find(
        (session: any) => session.uuid === session_id
      );
      setSessionDetails(session);
      setSessionNumber(sessions.length);
      setTotalCorrectWords(session.totalCorrect);
      setTotalWordsAttempted(session.totalWords);
    } catch (error) {
      console.error('Error while fetching sessions', error);
    }
    setLoader(false);
  };

  useEffect(() => {
    const upgradeProfile = async () => {
      const response = await serverApi.upgradeProfile(
        selectedProfile.uuid,
        selectedProfile.current_level || 1,
        user.token
      );
      setUpgrade(response.upgraded);
      reload();
    };
    getSession();
    upgradeProfile();
    setBadge(findBadgeById(selectedProfile.current_level || 1));
  }, []);

  const findBadgeById = (id: number) => {
    if (id > 6) return badges[5];
    else return badges[id - 1];
  };

  const handleChestClick = () => {
    if (selectedProfile.no_of_mastered_cards >= badge.wordsRequired) {
      navigate('/student-progress', {
        state: { chest: true, userLevel: selectedProfile.current_level },
      });
    } else {
      navigate('/student-progress');
    }
  };

  const resultTitle =
    (sessionDetails?.totalCorrect / sessionDetails?.totalWords) * 100 > 50
      ? 'Wow! That was Good!'
      : 'Nice effort!';

  return (
    <Stack className={styles['session-finished-container']}>
      {loader ? (
        <CircularProgress color="success" />
      ) : (
        <Stack spacing={2} className={styles['result-stack']}>
          <Stack
            alignItems="center"
            width="100%"
            spacing={{ xs: 2, sm: 3, md: 2, lg: 2 }}
          >
            <Typography variant="h3" className={styles['heading']}>
              {resultTitle}
            </Typography>
            <Stack className={styles['score-stack']}>
              <img src={starTilted} alt="" className={styles['star-1']} />
              <img src={starTilted} alt="" className={styles['star-2']} />
              <img src={starTilted} alt="" className={styles['star-3']} />
              <Typography variant="h2" className={styles['score-text']}>
                <span className={styles['correct']}>{totalCorrectWords}</span>/
                {totalWordsAttempted}
              </Typography>
            </Stack>
          </Stack>
          {selectedProfile.no_of_mastered_cards < badge.wordsRequired ? (
            <ProgressBadgeBox
              badgeName={badge.title}
              lowerBadgeWords={badge.wordsStarted}
              badgeWords={badge.wordsRequired}
              badgeProgress={selectedProfile.no_of_mastered_cards}
              image={badge.image}
              current
            />
          ) : null}
          {upgrade && (
            <Stack spacing={2} className={styles['chest-stack']}>
              <Typography variant="h3" className={styles['heading']}>
                Wow , you unlocked a chest!
              </Typography>
              <Typography variant="body1" className={styles['sub-text']}>
                Press the chest to view it.
              </Typography>
              <IconButton
                className={styles['chest-btn']}
                onClick={handleChestClick}
              >
                <img
                  src={ChestClose}
                  alt="chest"
                  className={styles['chest-img']}
                />
                <img
                  src={PressGif}
                  alt="Press Gif"
                  className={styles['gif-overlay']}
                />
              </IconButton>
            </Stack>
          )}
          <Stack width="80%" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              className={styles['results-btn']}
              onClick={() => {
                navigate('/result-session', {
                  state: {
                    session,
                    sessionNumber: sessionNumber,
                  },
                });
              }}
            >
              See Results
            </Button>
            <Button
              variant="outlined"
              type="button"
              size="large"
              fullWidth
              className={styles['back-btn']}
              onClick={() => {
                navigate('/home');
              }}
            >
              Back to Home
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SessionFinished;
