import { useState } from 'react';
import {
  CircularProgress,
  Container,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProfileTable from '../../Components/ProfileTable';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import AddIcon from '@mui/icons-material/Add';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';
import UpgradePlanModal from '../../Components/ModalUpgradePlan';

const StudentProfiles = () => {
  const navigate = useNavigate();
  const { user, profiles } = useAuth();
  const [isUpgradePlanOpen, setIsUpgradePlanOpen] = useState(false);
  const handleUpgradePlanClose = () => setIsUpgradePlanOpen(false);
  const [loader, setLoader] = useState(false);
  const isAddStudentPossible =
    (user.role === 'parent' &&
      user.plan_type === 'Multiple Users Plan' &&
      profiles.length >= 3) ||
    (user.role === 'teacher' &&
      user.plan_type === 'ThirtyStudentsPlan' &&
      profiles.length >= 30);

  const handleClick = () => {
    navigate('/profile');
  };
  const handleAddStudent = () => {
    if (
      (user.plan_type === 'ThreeStudentsPlan' && profiles.length >= 3) ||
      (user.plan_type === 'FiveStudentsPlan' && profiles.length >= 5) ||
      (user.plan_type === 'TenStudentsPlan' && profiles.length >= 10) ||
      (user.plan_type === 'TwentyStudentsPlan' && profiles.length >= 20) ||
      (user.plan_type === 'ThirtyStudentsPlan' && profiles.length >= 30) ||
      user.plan_type == 'Single User Plan'
    ) {
      setIsUpgradePlanOpen(true);
    } else {
      navigate('/add-student', {
        state: { previousPage: '/student-profiles' },
      });
    }
  };

  return (
    <Container className={styles['student-profiles-container']}>
      <Modal
        className={styles['change-modal']}
        open={isUpgradePlanOpen}
        onClose={handleUpgradePlanClose}
      >
        <UpgradePlanModal
          handleClose={handleUpgradePlanClose}
          setLoader={setLoader}
        />
      </Modal>
      {loader ? (
        <CircularProgress color="success" />
      ) : (
        <Stack spacing={4} className={styles['student-profiles-stack']}>
          <Stack spacing={1} direction="row" className={styles['header']}>
            <Stack spacing={1} direction="row" className={styles['heading']}>
              <IconButton onClick={handleClick} className={styles['back-btn']}>
                <img
                  src={BackArrow}
                  alt="back"
                  className={styles['back-img']}
                />
              </IconButton>
              <Typography variant="h4" className={styles['page-name']}>
                Student Profiles
              </Typography>
            </Stack>
            {!isAddStudentPossible ? (
              <IconButton
                className={styles['section-btn']}
                onClick={() => handleAddStudent()}
              >
                <AddIcon className={styles['icon']} />
                <Typography variant="body1" className={styles['text']}>
                  Add Student
                </Typography>
              </IconButton>
            ) : null}
          </Stack>
          <ProfileTable />
        </Stack>
      )}
    </Container>
  );
};

export default StudentProfiles;
