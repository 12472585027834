import { Stack, Typography, IconButton } from '@mui/material';
import ResultsListItem from '../ResultsListItem';
import styles from './styles.module.scss';
import { animateScroll as scroll } from 'react-scroll';
import { ReactComponent as UpwardButton } from '../../Assets/Images/up-button.svg';

interface ResultListProps {
  sessions: any[];
  totalSessions: number;
  showButton: boolean;
}

const ResultsList = ({
  sessions,
  totalSessions,
  showButton,
}: ResultListProps) => {
  let total_sessions = totalSessions;

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <Stack spacing={3} className={styles['list-container-stack']}>
      <Stack spacing={1} direction="row" className={styles['divider']}>
        <Typography variant="h5" className="text">
          All Sessions
        </Typography>
        <Stack className={styles['horizontal-line']}></Stack>
      </Stack>
      {sessions.map((session, index) => (
        <ResultsListItem
          key={index}
          session={session}
          sessionNumber={total_sessions--}
        />
      ))}
      {showButton && (
        <IconButton
          className={styles['scroll-to-top-button']}
          color="primary"
          onClick={scrollToTop}
          aria-label="scroll-to-top"
        >
          <UpwardButton />
        </IconButton>
      )}
    </Stack>
  );
};

export default ResultsList;
