import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import raccoon from '../../Assets/Images/Avatars/raccoon.svg';
import Trash from '../../Assets/Images/trash.svg';
import hidePasswordIcon from '../../Assets/Images/hide_password.svg';
import ThreeDots from '../../Assets/Images/three-dots.svg';
import useAuth from '../../Context/Auth';
import styles from './styles.module.scss';

const ProfileDesktopScreen = ({
  handleDeleteAccountOpen,
  handleDeactivateAccountOpen,
}: {
  handleDeleteAccountOpen: () => void;
  handleDeactivateAccountOpen: () => void;
}) => {
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAccountClick = () => {
    handleClose();
    handleDeleteAccountOpen();
  };

  const handleDeactivateAccountClick = () => {
    handleClose();
    handleDeactivateAccountOpen();
  };

  return (
    <Stack direction="row" className={styles['container-stack']}>
      <Stack
        direction="row"
        spacing={3}
        className={styles['credentials-stack']}
      >
        <Box className={styles['profile-photo-box']}>
          <img
            src={raccoon}
            alt="profile-photo"
            className={styles['profile-photo']}
          />
        </Box>
        <Stack>
          <Typography variant="body1" className={styles['username-head']}>
            Name
          </Typography>
          <Typography variant="h4" className={styles['username']}>
            {user.full_name}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={3} className={styles['buttons-stack']}>
        <Button
          type="submit"
          variant="outlined"
          fullWidth
          size="large"
          color="secondary"
          onClick={logout}
          className={styles['signout']}
        >
          Signout
        </Button>
        <IconButton className={styles['options']} onClick={handleClick}>
          <img src={ThreeDots} alt="modal-menu" />
        </IconButton>
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{
            borderRadius: '20px',
            padding: '0.5rem 0',
          }}
          className={styles['popover']}
        >
          <Stack className={styles['btn-stack']}>
            <IconButton
              color="error"
              className={styles['icon-btn']}
              onClick={() => handleDeleteAccountClick()}
            >
              <img src={Trash} alt="delete-icon" />
              <Typography variant="h4" ml={3} className={styles['btn-text']}>
                Delete Account
              </Typography>
            </IconButton>
            <IconButton
              className={styles['icon-btn']}
              onClick={() => handleDeactivateAccountClick()}
            >
              <img src={hidePasswordIcon} alt="delete-icon" />
              <Typography variant="h4" ml={3} className={styles['btn-text']}>
                Deactivate Account
              </Typography>
            </IconButton>
          </Stack>
        </Popover>
      </Stack>
    </Stack>
  );
};

export default ProfileDesktopScreen;
