import { useEffect, useState } from 'react';
import { Container, Stack } from '@mui/material';
import ReactHowler from 'react-howler';
import SessionProgressBar from '../SessionProgressBar';

import { IFlashCard } from '../../Utils/constants/interfaces';
import useAuth from '../../Context/Auth';
import {
  RECORDER_STATES,
  TOTAL_SESSION_TIME,
  WORD_OPACITY,
} from '../../Utils/constants/speechSession';

import styles from './styles.module.scss';

import { DraggableFlashcards } from '../DraggableFlashcards';
import { DraggableItem } from './interfaces/DraggableInterface';
import Player from '../Player';

interface PracticeSessionProps {
  sessionStage: string;
  wordsList: IFlashCard[];
  reviewSessionCallback: () => void;
  sessionEndedCallback: (wrongCount: number, correctCount: number) => void;
  timerValue: number;
  setTimerValue: (t: number) => void;
  isPaused: boolean;
}

const PracticeSessionThree = ({
  sessionStage,
  wordsList,
  reviewSessionCallback,
  sessionEndedCallback,
  timerValue,
  setTimerValue,
  isPaused,
}: PracticeSessionProps) => {
  const [flashcardList, setFlashcard] = useState<IFlashCard[]>([]);
  const [answerSound, setAnswerSound] = useState<string>('');
  const [flashcardTop, setFlashcardTop] = useState<DraggableItem[]>([]);
  const [flashcardBottom, setFlashcardBottom] = useState<DraggableItem[]>([]);
  // to monitor if the user has drag and drop a card
  const [checkResult, setCheckResult] = useState(false);
  // initial delay after session starts
  const [isInitialPause, setIsInitialPause] = useState(true);

  useEffect(() => {
    setFlashcard([...wordsList]);
  }, [wordsList]);

  const shuffleFlashcardsTop = async (arr: IFlashCard[]) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    const arr2 = arr.map(({ flashcard_uuid, flashcard_value }) => ({
      id: flashcard_uuid,
      flashcard_value,
    }));
    setFlashcardTop(arr2);
  };

  const shuffleFlashcardsBottom = async (arr: IFlashCard[]) => {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }

    const arr2 = arr.map(({ flashcard_uuid, flashcard_value }) => ({
      id: flashcard_uuid,
      flashcard_value,
    }));

    setFlashcardBottom(arr2);
  };

  useEffect(() => {
    shuffleFlashcardsTop(flashcardList);
    shuffleFlashcardsBottom(flashcardList);
  }, [flashcardList]);

  const sessionWrongCount = 0;
  const sessionCorrectCount = 0;

  const [isAnswerSoundPlaying, setIsAnswerSoundPlaying] =
    useState<boolean>(false);

  const { sounds } = useAuth();

  const [cardIndex, setCardIndex] = useState<number>(0);

  const [isAppSpeaking, setIsAppSpeaking] = useState<boolean>(true);

  const [recorderStatus, setRecorderStatus] = useState<string>(
    RECORDER_STATES.recording
  );

  const isSoundsActive = sounds;

  const [cardOpacity, setCardOpacity] = useState<string>('');

  const sessionFinishedCallback = () => {
    sessionEndedCallback(sessionWrongCount, sessionCorrectCount);
  };

  const moveToNextWord = () => {
    setTimeout(() => {
      if (cardIndex === wordsList.length - 1) {
        setCardIndex(0);
      } else {
        setCardIndex((prevInd) => prevInd + 1);
      }
      shuffleFlashcardsTop(flashcardList);
      shuffleFlashcardsBottom(flashcardList);
      return;
    }, 1000);
  };

  const recordingCompleteHandler = async () => {
    setTimeout(
      () => {
        if (isPaused) return;
        setCardOpacity(WORD_OPACITY.hidden);
        setTimeout(() => {
          setIsAppSpeaking(true);
          setCardOpacity(WORD_OPACITY.visible);
        }, 2000);
        moveToNextWord();
      },
      checkResult ? 2000 : 0 // additional 2 seconds to display result if user has drag and drop a card
    );
  };

  const appSpeakingCompleteHandler = () => {
    setIsAppSpeaking(false);
    setRecorderStatus(RECORDER_STATES.recording);
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsInitialPause(false), 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Container maxWidth={false} className={styles['top-container']}>
      <ReactHowler
        src={[answerSound]}
        format={['wav']}
        playing={isAnswerSoundPlaying && isSoundsActive}
        onEnd={() => {
          setIsAnswerSoundPlaying(false);
        }}
      />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        height={'100%'}
        paddingTop={'40px'}
      >
        <SessionProgressBar
          sessionStage={sessionStage}
          callbackTime={TOTAL_SESSION_TIME}
          timerCallback={reviewSessionCallback}
          timerEndedCallback={sessionFinishedCallback}
          timerValue={timerValue}
          setTimerValue={setTimerValue}
        />
        {flashcardTop.length &&
          flashcardBottom.length &&
          wordsList[cardIndex] && (
            <DraggableFlashcards
              flashCard={wordsList[cardIndex]}
              flashcardsTop={flashcardTop}
              flashcardsBottom={flashcardBottom}
              setAnswerSound={setAnswerSound}
              setIsAnswerSoundPlaying={setIsAnswerSoundPlaying}
              setRecorderStatus={setRecorderStatus}
              cardOpacity={cardOpacity}
              checkResult={checkResult}
              setCheckResult={setCheckResult}
            />
          )}
        {flashcardTop.length &&
          flashcardBottom.length &&
          wordsList[cardIndex] && (
            <Player
              wordToRead={wordsList[cardIndex].flashcard_value || undefined}
              audioList={wordsList[cardIndex].sounds || undefined}
              onPlayerComplete={appSpeakingCompleteHandler}
              duration={10}
              onRecordingComplete={recordingCompleteHandler}
              currentStatus={recorderStatus}
              isAppSpeaking={isAppSpeaking}
              isPaused={isInitialPause}
            />
          )}
      </Stack>
    </Container>
  );
};

export default PracticeSessionThree;
