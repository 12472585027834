import { Box, Button, Container, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import screen1 from '../../Assets/Images/TutorialFlow/screen-1.svg';
import screen2 from '../../Assets/Images/TutorialFlow/screen-2.svg';
import screen3 from '../../Assets/Images/TutorialFlow/screen-3.svg';
import screen4 from '../../Assets/Images/TutorialFlow/screen-4.svg';
import screen5 from '../../Assets/Images/TutorialFlow/screen-5.svg';
import screen6 from '../../Assets/Images/TutorialFlow/screen-6.svg';
import screen7 from '../../Assets/Images/TutorialFlow/screen-7.svg';
import screen8 from '../../Assets/Images/TutorialFlow/screen-8.svg';
import screen9 from '../../Assets/Images/TutorialFlow/screen-9.svg';
import screen10 from '../../Assets/Images/TutorialFlow/screen-10.svg';
import screen11 from '../../Assets/Images/TutorialFlow/screen-11.svg';
import screen12 from '../../Assets/Images/TutorialFlow/screen-12.svg';
import screen13 from '../../Assets/Images/TutorialFlow/screen-13.svg';
import screen14 from '../../Assets/Images/TutorialFlow/screen-14.svg';
import screen15 from '../../Assets/Images/TutorialFlow/screen-15.svg';
import screen16 from '../../Assets/Images/TutorialFlow/screen-16.svg';
import screen17 from '../../Assets/Images/TutorialFlow/screen-17.svg';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import Sound from '../../Assets/Gifs/sound.gif';
import styles from './styles.module.scss';
import useAuth from '../../Context/Auth';

const Tutorial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const { removeIsTutorial } = useAuth();

  const images = [
    screen1,
    screen2,
    screen3,
    screen4,
    screen5,
    screen6,
    screen7,
    screen8,
    screen9,
    screen10,
    screen11,
    screen12,
    screen13,
    screen14,
    screen15,
    screen16,
    screen17,
  ];

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setImageLoaded(false);
    } else {
      removeIsTutorial();
      localStorage.setItem('openFinish', JSON.stringify(true));
      navigate('/home');
    }
  };

  const handleSkip = () => {
    removeIsTutorial();
    localStorage.setItem('openFinish', JSON.stringify(true));
    navigate('/home');
  };

  return (
    <Container className={styles['tutorial-container']} maxWidth={false}>
      <Stack className={styles['base-stack']}>
        <img
          src={images[currentIndex]}
          alt="screen-1"
          className={styles['screen']}
          onLoad={() => setImageLoaded(true)}
        />
        <Stack direction="row" className={styles['button-stack']}>
          <Button
            variant="contained"
            onClick={() => handleSkip()}
            className={styles['skip-btn']}
          >
            Skip
          </Button>
          <Box className={styles['div']}>
            <Button
              variant="contained"
              onClick={() => handleNext()}
              className={styles['next-btn']}
            >
              Next
              <ArrowForwardOutlinedIcon className={styles['icon']} />
            </Button>

            <img
              src={PressGif}
              alt="GIF"
              className={styles['press-gif-overlay']}
            />
          </Box>

          {[1, 6].includes(currentIndex) && imageLoaded && (
            <img
              src={Sound}
              alt="GIF"
              className={styles['sound-gif-overlay']}
            />
          )}
          {[3, 5].includes(currentIndex) && imageLoaded && (
            <img
              src={Sound}
              alt="GIF"
              className={styles['sound-2-gif-overlay']}
            />
          )}
          {[7, 10].includes(currentIndex) && imageLoaded && (
            <img
              src={Sound}
              alt="GIF"
              className={styles['sound-3-gif-overlay']}
            />
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Tutorial;
