const SettingsIcon = ({ isFill }: { isFill: boolean }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.30635 16.4006C2.54856 18.6644 4.36938 20.4853 6.63216 20.7375C8.31288 20.9248 10.0386 21.0857 11.798 21.0857C13.5574 21.0857 15.2831 20.9248 16.9638 20.7375C19.2266 20.4853 21.0474 18.6644 21.2896 16.4006C21.4684 14.7293 21.6194 13.0135 21.6194 11.2643C21.6194 9.51505 21.4684 7.79927 21.2896 6.12804C21.0474 3.86416 19.2266 2.04333 16.9638 1.79111C15.2831 1.60377 13.5574 1.44287 11.798 1.44287C10.0386 1.44287 8.31288 1.60377 6.63216 1.79111C4.36938 2.04333 2.54856 3.86416 2.30635 6.12804C2.12755 7.79927 1.97656 9.51505 1.97656 11.2643C1.97656 13.0135 2.12755 14.7293 2.30635 16.4006Z"
      fill={isFill ? '#54B948' : 'white'}
      fillOpacity="0.2"
    />
    <path
      d="M2.30635 16.4006C2.54856 18.6644 4.36938 20.4853 6.63216 20.7375C8.31288 20.9248 10.0386 21.0857 11.798 21.0857C13.5574 21.0857 15.2831 20.9248 16.9638 20.7375C19.2266 20.4853 21.0474 18.6644 21.2896 16.4006C21.4684 14.7293 21.6194 13.0135 21.6194 11.2643C21.6194 9.51505 21.4684 7.79927 21.2896 6.12804C21.0474 3.86416 19.2266 2.04333 16.9638 1.79111C15.2831 1.60377 13.5574 1.44287 11.798 1.44287C10.0386 1.44287 8.31288 1.60377 6.63216 1.79111C4.36938 2.04333 2.54856 3.86416 2.30635 6.12804C2.12755 7.79927 1.97656 9.51505 1.97656 11.2643C1.97656 13.0135 2.12755 14.7293 2.30635 16.4006Z"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.35714"
    />
    <path
      d="M7.08594 7.33594H13.3717"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.35714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5156 15.1929H10.2299"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.35714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M14.154 9.6928C15.4558 9.6928 16.5112 8.63747 16.5112 7.33566C16.5112 6.03384 15.4558 4.97852 14.154 4.97852C12.8522 4.97852 11.7969 6.03384 11.7969 7.33566C11.7969 8.63747 12.8522 9.6928 14.154 9.6928Z"
      fill={isFill ? '#54B948' : 'white'}
      fillOpacity="0.2"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.35714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.43973 17.5502C8.13792 17.5502 7.08259 16.4949 7.08259 15.1931C7.08259 13.8913 8.13792 12.8359 9.43973 12.8359C10.7415 12.8359 11.7969 13.8913 11.7969 15.1931C11.7969 16.4949 10.7415 17.5502 9.43973 17.5502Z"
      fill={isFill ? '#54B948' : 'white'}
      fillOpacity="0.2"
      stroke={isFill ? '#54B948' : '#737D83'}
      strokeWidth="2.35714"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SettingsIcon;
