import { Stack, IconButton, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import RightGreenArrow from '../../Assets/Images/right-green-arrow.svg';
import styles from './styles.module.scss';
import useAuth, { IAvatar } from '../../Context/Auth';

const AvatarCarousel = ({
  setAvatar,
  current,
}: {
  setAvatar: (avatar: string) => void;
  current?: string;
}) => {
  const { avatars } = useAuth();
  const [data, setData] = useState<IAvatar[]>([...avatars]);

  const previousClick = () => {
    const tempImages = data;
    const lastElement = tempImages.pop();
    if (lastElement) setData([...[lastElement, ...tempImages]]);
  };

  const nextClick = () => {
    const tempImages = data;
    const firstElement = tempImages.shift();
    if (firstElement) tempImages.push(firstElement);
    setData([...tempImages]);
  };

  useEffect(() => {
    if (current) {
      let currentIndex = data.findIndex((item) => item.uuid === current);

      while (currentIndex !== Math.floor(data.length / 2)) {
        const firstElement = data.shift();
        if (firstElement) data.push(firstElement);
        currentIndex = data.findIndex((item) => item.uuid === current);
      }

      setData([...data]);
    }
  }, [current]);

  useEffect(() => {
    const middleIndex = Math.floor(data.length / 2);
    const middleAvatar = data[middleIndex];

    setAvatar(middleAvatar?.uuid);
  }, [data]);

  const props = [
    { zIndex: 0, width: 0 },
    { zIndex: 10, width: 50 },
    { zIndex: 20, width: 100 },
    { zIndex: 50, width: 150 },
    { zIndex: 20, width: 100 },
    { zIndex: 10, width: 50 },
    { zIndex: 0, width: 0 },
  ];

  return (
    <>
      <Stack direction="row" className={styles['avatar-carousel-stack']}>
        <IconButton onClick={() => previousClick()}>
          <img
            src={RightGreenArrow}
            alt=""
            className={styles['left-arrow-icon']}
          />
        </IconButton>
        <Stack direction="row" className={styles['avatar-stack']}>
          {data.map((item, index) => (
            <Box
              key={index}
              className={styles['image-stack']}
              sx={{
                zIndex: `${props[index]?.zIndex} !important`,
                width: props[index]?.width,
                height: props[index]?.width,

                '@media (max-width: 600px)': {
                  width: props[index]?.width / 1.8,
                  height: props[index]?.width / 1.8,
                },
              }}
            >
              <img
                key={item?.uuid}
                src={item?.image}
                alt="avatar"
                className={styles['avatar']}
              />
            </Box>
          ))}
        </Stack>
        <IconButton onClick={() => nextClick()}>
          <img
            src={RightGreenArrow}
            alt="right arrow"
            className={styles['right-arrow-icon']}
          />
        </IconButton>
      </Stack>
    </>
  );
};

export default AvatarCarousel;
