import { createTheme } from '@mui/material/styles';
const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 769,
  lg: 1020,
  xl: 1280,
};
const FONT_WEIGHTS = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};
const BORDER_RADIUS = '16px';
const breakpointTheme = createTheme({
  breakpoints: {
    values: { ...BREAKPOINTS },
  },
});
const modeTheme = {
  light: {
    palette: {
      text: {
        primary: '#162731',
        secondary: '#737d83',
      },
    },
  },
  dark: {
    palette: {},
  },
};
const baseTheme = {
  ...breakpointTheme,
  palette: {
    primary: {
      main: '#54B948',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#162731',
      contrastText: '#FFFFFF',
    },
    red: {
      main: '#FF3654',
      contrastText: '#FFFFFF',
    },
    yellow: {
      main: '#F7DE87',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Lexend',
    h1: {
      fontSize: '48px',
      fontWeight: FONT_WEIGHTS.bold,
      letterSpacing: '4px',
      [breakpointTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        letterSpacing: '3.5px',
      },
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        letterSpacing: '3px',
      },
    },
    h2: {
      fontSize: '40px',
      fontWeight: FONT_WEIGHTS.semiBold,
      letterSpacing: '4px',
      [breakpointTheme.breakpoints.down('md')]: {
        fontSize: '36px',
        letterSpacing: '3.5px',
      },
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '32px',
        letterSpacing: '3px',
      },
    },
    h3: {
      fontSize: '32px',
      fontWeight: FONT_WEIGHTS.semiBold,
      letterSpacing: '0px',
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
        letterSpacing: '0px',
      },
    },
    h4: {
      fontSize: '24px',
      fontWeight: FONT_WEIGHTS.semiBold,
      letterSpacing: '2px',
      [breakpointTheme.breakpoints.down('md')]: {
        fontSize: '20px',
        letterSpacing: '2px',
      },
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        letterSpacing: '1.71px',
      },
    },
    h5: {
      fontWeight: FONT_WEIGHTS.regular,
      fontSize: '16px',
      letterSpacing: '1.75px',
      [breakpointTheme.breakpoints.down('lg')]: {
        fontSize: '18px',
        letterSpacing: '2px',
      },
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '14px',
        letterSpacing: '1.5px',
      },
    },
    h6: {
      fontSize: '14px',
      fontWeight: FONT_WEIGHTS.medium,
      letterSpacing: '1.5px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: FONT_WEIGHTS.regular,
      letterSpacing: '0px',
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    body2: {
      fontSize: '14px',
      fontWeight: FONT_WEIGHTS.regular,
      letterSpacing: '0px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: FONT_WEIGHTS.regular,
      letterSpacing: '0px',
      [breakpointTheme.breakpoints.down('sm')]: {
        fontSize: '7px',
      },
    },
    inputLabel: {
      fontSize: '14px',
      fontWeight: FONT_WEIGHTS.medium,
      letterSpacing: '0px',
    },
    helperText: {
      fontSize: '12px',
      fontWeight: FONT_WEIGHTS.regular,
      letterSpacing: '0px',
    },
    roundedMPlus_h1: {
      fontSize: '136px',
      fontWeight: FONT_WEIGHTS.regular,
      letterSpacing: '6.4px',
    },
    roundedMPlus_body1: {
      fontSize: '24px',
      fontWeight: FONT_WEIGHTS.medium,
      letterSpacing: '2px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          roundedMPlus_h1: 'h1',
          roundedMPlus_body1: 'body1',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          backgroundColor: '#FFFFFF',
          fieldset: {
            borderWidth: '1px', //leaving 1px border so the field is visible on a white background. Will be removed later on.
            borderColor: '#D0D0D0',
          },
          //leaving the below code as a sample if any hover effect is needed at some point.
          //A helpful link: https://github.com/mui/material-ui/issues/32100#issuecomment-1087466444
          // '&:hover': {
          //   backgroundColor: 'red',
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          borderRadius: BORDER_RADIUS,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          //changing the background and text color for primary buttons only when they're disabled
          ...(ownerState.color === 'primary' && {
            '&:disabled': {
              backgroundColor: '#98D591',
              color: '#FFFFFF',
            },
            '&:hover': {
              outline: '2px solid #a3d59d',
              outlineOffset: '4px',
              transition: '0.2s',
              backgroundColor: '#54B948',
              boxShadow: 'none',
            },
          }),
          ...(ownerState.color === 'secondary' && {
            backgroundColor: '#FFFFFF',
            '&:disabled': {},
            '&:hover': {
              backgroundColor: '#FFFFFF',
              boxShadow: 'none',
            },
          }),
          ...(ownerState.size === 'large' && {
            fontSize: '20px',
            fontWeight: FONT_WEIGHTS.bold,
            letterSpacing: '1.68px',
          }),
          ...(ownerState.size === 'small' && {
            fontSize: '16px',
            fontWeight: FONT_WEIGHTS.regular,
          }),
        }),
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '10px',
          borderRadius: BORDER_RADIUS,
          backgroundColor: '#eeeeee', //TODO: Background color needs to be fixed according to theme type.
        },
        bar: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
  },
};
export { modeTheme, baseTheme };
