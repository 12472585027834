import {
  Box,
  Button,
  Container,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../../Components/FormField';
import FormDropdown from '../../Components/FormDropdown';
import styles from './styles.module.scss';
import serverApi from '../../Utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth, { IProfile } from '../../Context/Auth';
import AvatarCarousel from '../../Components/AvatarCarousel';
import { useState } from 'react';
import CompletionNotifyBar from '../../Components/CompletionNotifyBar';
import DeleteProfileModal from '../../Components/ModalDeleteStudent';
import { AxiosError } from 'axios';

const validationSchema = yup.object().shape({
  fullname: yup
    .string()
    .min(4, 'Fullname must be 6 characters long')
    .required('Fullname is required'),
  age: yup.string().required('Age is required'),
});

const AddStudent = () => {
  const ageList = Array.from({ length: 9 }, (_, i) => i + 4);
  const [avatar, setAvatar] = useState('');
  const {
    error,
    setError,
    user,
    profiles,
    updateProfiles,
    isTutorial,
    selectedProfile,
    setCurrentProfile,
    avatars,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditStudentPage = location.pathname === '/edit-student';
  const profile = (location.state as IProfile) || {};
  const { previousPage } = location.state || '';
  const [isDeleteStudentOpen, setIsDeleteStudentOpen] = useState(false);

  const isAddStudentPossible =
    (user.role === 'parent' &&
      user.plan_type === 'Multiple Users Plan' &&
      profiles.length >= 3) ||
    (user.role === 'teacher' &&
      user.plan_type === 'ThreeStudentsPlan' &&
      profiles.length >= 3) ||
    (user.role === 'teacher' &&
      user.plan_type === 'FiveStudentsPlan' &&
      profiles.length >= 5) ||
    (user.role === 'teacher' &&
      user.plan_type === 'TenStudentsPlan' &&
      profiles.length >= 10) ||
    (user.role === 'teacher' &&
      user.plan_type === 'TwentyStudentsPlan' &&
      profiles.length >= 20) ||
    (user.role === 'teacher' &&
      user.plan_type === 'ThirtyStudentsPlan' &&
      profiles.length >= 30);

  const handleDeleteStudentOpen = () => {
    setIsDeleteStudentOpen(true);
  };
  const handleDeleteStudentClose = () => {
    setError('');
    setIsDeleteStudentOpen(false);
  };

  const handleSubmit = async (values: {
    fullname: string;
    age: string;
    buttonClicked: string;
  }) => {
    try {
      setError('');
      const response = await serverApi.addStudent(
        user?.token as string,
        avatar,
        values.fullname,
        Number(values.age)
      );

      if (response.status === 201) {
        const profiles = await serverApi.getProfiles(user.token);
        const profilesStore = profiles.data.profiles;
        updateProfiles(profilesStore);

        if (profilesStore.length === 1) {
          setCurrentProfile(profilesStore[0]);
        }
        if (values.buttonClicked === 'add') {
          if (previousPage) {
            navigate(previousPage);
          } else if (profilesStore.length > 0) {
            formik.resetForm();
            navigate('/home');
          }
        } else {
          formik.resetForm();
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error while adding a student profile', error);
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const handleEdit = async (values: {
    fullname: string;
    age: string;
    buttonClicked: string;
  }) => {
    try {
      setError('');
      await serverApi.editStudent(
        user?.token as string,
        profile?.uuid as string,
        avatar,
        values.fullname,
        Number(values.age)
      );

      const profiles = await serverApi.getProfiles(user.token);
      const profilesStore = profiles.data.profiles;
      updateProfiles(profilesStore);
      if (selectedProfile.uuid === profile.uuid) {
        setCurrentProfile(
          profilesStore.find(
            (profile: IProfile) => profile.uuid === selectedProfile.uuid
          )
        );
      }
      if (values.buttonClicked === 'edit') {
        formik.resetForm();
        navigate('/profile');
      }
      formik.resetForm();
    } catch (error) {
      console.error('Error while editing student profile', error);
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullname: isEditStudentPage ? profile?.profile_name ?? '' : '',
      age: isEditStudentPage
        ? (profile && profile.age && profile.age.toString()) || ''
        : '',
      buttonClicked: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: {
      fullname: string;
      age: string;
      buttonClicked: string;
    }) => {
      setError('');
      isEditStudentPage ? handleEdit(values) : handleSubmit(values);
    },
    validateOnMount: true,
  });

  return (
    <Container maxWidth={false} className={styles['add-student-container']}>
      <Modal
        open={isDeleteStudentOpen}
        onClose={handleDeleteStudentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteProfileModal
          handleClose={handleDeleteStudentClose}
          profileId={profile?.uuid || ''}
        />
      </Modal>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={1} className={styles['base-stack']}>
          {isTutorial && <CompletionNotifyBar text="Payment was Successful!" />}
          <Typography variant="h3" className={styles['heading']}>
            {isEditStudentPage
              ? 'Edit Student/Child Details'
              : 'Add Student/Child Details'}
          </Typography>
          <Typography variant="h5">Choose Avatar</Typography>

          <AvatarCarousel
            setAvatar={setAvatar}
            current={profile?.avatar_uuid || avatars[0]?.uuid}
          />

          <Stack width="100%" className={styles['fields-stack']}>
            <Box>
              <FormField
                name="fullname"
                fieldLabel="Full Name"
                id="fullname"
                placeHolder="e.g Steve Irwin"
                value={formik.values.fullname}
                error={
                  formik.touched.fullname && formik.errors.fullname
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.fullname && formik.errors.fullname
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.fullname && formik.errors.fullname
                  ? formik.errors.fullname
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormDropdown
                fieldLabel="Age"
                id="age"
                value={formik.values.age}
                error={formik.touched.age && formik.errors.age ? true : false}
                handleChange={formik.handleChange}
                name="age"
                options={ageList}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.age && formik.errors.age ? 'visible' : 'hidden'
                }`}
              >
                {formik.touched.age && formik.errors.age
                  ? formik.errors.age
                  : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>
          <Stack className={styles['btn-stack']}>
            <Box width="100%">
              <Button
                name="add-student"
                className={styles['add-button']}
                variant="contained"
                type="submit"
                fullWidth
                size="large"
                disabled={!formik.isValid || isAddStudentPossible}
                onClick={() =>
                  formik.setFieldValue(
                    'buttonClicked',
                    isEditStudentPage ? 'edit' : 'add'
                  )
                }
              >
                <Typography>
                  <b>
                    {isEditStudentPage ? 'Save ' : 'Add '}
                    Student
                  </b>
                </Typography>
              </Button>
              <Typography
                visibility={`${error ? 'visible' : 'hidden'}`}
                variant="body2"
                color="red.main"
              >
                {error ? error : '&nbsp;'}
              </Typography>
            </Box>
            {!isEditStudentPage &&
              (user.plan_type == 'ThreeStudentsPlan' ||
                user.plan_type == 'FiveStudentsPlan' ||
                user.plan_type == 'TenStudentsPlan' ||
                user.plan_type == 'TwentyStudentsPlan' ||
                user.plan_type == 'ThirtyStudentsPlan' ||
                user.plan_type == 'Multiple Users Plan') &&
              !isAddStudentPossible && (
                <Box width="100%">
                  <Button
                    name="save-student"
                    type="submit"
                    fullWidth
                    size="large"
                    color="secondary"
                    onClick={() =>
                      formik.setFieldValue('buttonClicked', 'save')
                    }
                    className={styles['save-button']}
                  >
                    Save & Add Another Student
                  </Button>
                </Box>
              )}
          </Stack>

          {isAddStudentPossible ? (
            <Typography variant="body2" color="red.main" textAlign="center">
              You have reached the maximum number of students allowed for your
              plan. Please upgrade your plan to add more students.
            </Typography>
          ) : (
            <></>
          )}

          {isAddStudentPossible ? (
            <Box width="100%">
              <Button
                name="proceed-button"
                type="button"
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                onClick={() => navigate('/home')}
                className={styles['add-button']}
              >
                Proceed
              </Button>
            </Box>
          ) : null}

          {isEditStudentPage && (
            <Box width="100%">
              <Button
                name="save-student"
                type="button"
                variant="contained"
                fullWidth
                size="large"
                color="error"
                onClick={() => handleDeleteStudentOpen()}
                className={styles['delete-button']}
              >
                Delete Profile
              </Button>
            </Box>
          )}
        </Stack>
      </form>
    </Container>
  );
};

export default AddStudent;
