import {
  Container,
  Box,
  Stack,
  Typography,
  IconButton,
  Modal,
  Button,
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth, { IProfile } from '../../Context/Auth';
import PracticeSessionCard from '../../Components/PracticeSessionCard';
import SessionCard from '../../Components/SessionCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import styles from './styles.module.scss';
import cross from '../../Assets/Images/green-cross.svg';
import ProfileHeader from '../../Components/ProfileHeader';

const Home = () => {
  const [openStart, setStartOpen] = useState(false);
  const [openFinish, setFinishOpen] = useState(false);
  const [arrowClick, setArrowClick] = useState(false);
  const navigate = useNavigate();
  const { selectedProfile, isTutorial, profiles, setCurrentProfile, avatars } =
    useAuth();

  const currentAvatar = avatars.find(
    (avatar) => avatar.uuid === selectedProfile.avatar_uuid
  );

  // if (Object.keys(selectedProfile).length === 0) {
  //   setCurrentProfile(profiles[0]);
  // }

  const handleOpenStartModal = () => setStartOpen(true);

  const handleCloseStartModal = () => {
    setStartOpen(false);
    navigate('/tutorial');
  };

  const handleOpenFinishModal = () => setFinishOpen(true);

  const handleCloseFinishModal = () => {
    setFinishOpen(false);
  };

  const handleArrowDownClick = () => {
    setArrowClick(!arrowClick);
  };

  const handleCloseStudentProfiles = () => {
    setArrowClick(false);
  };

  const handleProfileClick = (profile: IProfile) => {
    setCurrentProfile(profile);
    setArrowClick(false);
  };

  useEffect(() => {
    if (localStorage.isTutorial === 'true' && isTutorial) {
      handleOpenStartModal();
    }
  }, []);

  useEffect(() => {
    const isFinishOpen = localStorage.openFinish
      ? JSON.parse(localStorage.openFinish)
      : false;
    if (isFinishOpen) {
      handleOpenFinishModal();
      localStorage.removeItem('openFinish');
    }
  }, []);

  // session lock true in prod and staging
  const isBetaVersion = process.env.REACT_APP_SESSION_LOCK === 'false';

  return (
    <Container className={styles['home-container']}>
      <Button
        onClick={handleOpenStartModal}
        className={styles['tutorial-button']}
      >
        Tutorial <HelpOutlineIcon />
      </Button>
      {isBetaVersion && (
        <Typography className={styles['beta-version-text']}>
          Beta Version
        </Typography>
      )}
      <Modal
        open={openStart}
        // onClose={handleCloseStartModal}
        aria-labelledby="modal-modal-title"
      >
        <Stack spacing={{ xs: 3, md: 6 }} className={styles['modal-box']}>
          <Typography variant="h2" className={styles['modal-heading']}>
            Hi friends!
          </Typography>
          <Typography variant="h5" className={styles['modal-text']}>
            Let me explain how this works. Follow me!
          </Typography>
          <Button
            variant="contained"
            className={styles['modal-btn']}
            onClick={handleCloseStartModal}
          >
            Start
          </Button>
          <img src={PressGif} alt="GIF" className={styles['gif-overlay']} />
        </Stack>
      </Modal>
      <Modal
        open={openFinish}
        // onClose={handleCloseFinishModal}
        aria-labelledby="modal-modal-title"
      >
        <Stack spacing={{ xs: 2, md: 6 }} className={styles['modal-box']}>
          <Typography variant="h2" className={styles['modal-heading']}>
            Way to Go!
          </Typography>
          <Typography variant="h5" className={styles['modal-2-text']}>
            I hope you got the hang of it. You can always practise if you feel
            like it.
          </Typography>
          <Stack spacing={1}>
            <a
              href={require('../../Assets/Documents/MyWordPal-tutorial.pdf')}
              target="_blank"
              rel="noopener noreferrer"
              className={styles['modal-link']}
            >
              Application User Manual
            </a>
            <Button
              variant="contained"
              className={styles['modal-btn']}
              onClick={handleCloseFinishModal}
            >
              Finish
            </Button>
          </Stack>
          <img
            src={PressGif}
            alt="GIF"
            className={styles['gif-overlay-finish']}
          />
        </Stack>
      </Modal>
      <Modal
        open={arrowClick}
        onClose={handleCloseStudentProfiles}
        aria-labelledby="modal-modal-title"
      >
        <Stack
          spacing={{ xs: 3, md: 6 }}
          className={styles['profiles-modal-box']}
        >
          <Stack direction={'row'} className={styles['heading']}>
            <Typography variant="h4">Students</Typography>
            <IconButton
              onClick={handleCloseStudentProfiles}
              className={styles.closeButton}
            >
              <Avatar src={cross} className={styles['cross']}></Avatar>
            </IconButton>
          </Stack>

          <List sx={{ mt: '0px !important' }}>
            <Stack spacing={1} px={2}>
              {selectedProfile &&
                profiles.map((profile, index) => (
                  <ListItemButton
                    key={index}
                    onClick={() => handleProfileClick(profile)}
                    className={
                      profile.uuid === selectedProfile.uuid
                        ? `${styles.listItemButton} ${styles.listItemButtonSelected}`
                        : styles.listItemButton
                    }
                  >
                    <Stack direction="row" spacing={2}>
                      <ListItemIcon>
                        <Avatar
                          src={currentAvatar?.image}
                          className={styles.avatar}
                        />
                      </ListItemIcon>
                      <Stack direction="column" spacing={0.5}>
                        <Typography className={styles.listItemText}>
                          {profile.profile_name}
                        </Typography>

                        <Stack direction="row" className={styles.levelText}>
                          <Typography>{`Level: `}</Typography>
                          <Typography className={styles.listItemLevel}>
                            {profile.current_level}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </ListItemButton>
                ))}
            </Stack>
          </List>
        </Stack>
      </Modal>

      {profiles && (
        <ProfileHeader
          profile={selectedProfile}
          progressBar={true}
          onHome={true}
          handleArrowDownClick={handleArrowDownClick}
        />
      )}
      <Box className={styles['session-box']}>
        <Typography variant="body1">
          Your session for today is ready!
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={{ xs: 1, md: 2, lg: 3 }}
        width={{ lg: '100%', xl: '80%' }}
      >
        <SessionCard />
        <PracticeSessionCard />
      </Stack>
    </Container>
  );
};

export default Home;
