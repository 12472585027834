import { Box, Container, Stack } from '@mui/material';
import { useState } from 'react';
import ForgotPasswordBox from '../../Components/ForgotPasswordBox';
import BackGroundWeb from '../../Assets/Images/splash-screen-web.svg';
import styles from './styles.module.scss';
import { Route, Routes } from 'react-router-dom';
import ForgotPasswordConfirmationBox from '../../Components/ForgotPasswordConfirmationBox';
import ChangePasswordBox from '../../Components/ChangePasswordBox';

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState('');
  return (
    <Container maxWidth={false} className={styles['parent-container']}>
      <Box display={{ xs: 'none', xl: 'block' }}>
        <Stack className={styles['base-stack']}>
          <img
            src={BackGroundWeb}
            alt="background-image"
            className={styles['background-img']}
          />
          <Box className={styles['forgot-password-box']}>
            <Routes>
              <Route
                path="/"
                element={
                  <ForgotPasswordBox
                    setEmail={(email) => setUserEmail(email)}
                    fullWidth
                  />
                }
              />
              <Route
                path="link-sent"
                element={
                  <ForgotPasswordConfirmationBox email={userEmail} fullWidth />
                }
              />
              <Route path="reset" element={<ChangePasswordBox fullWidth />} />
            </Routes>
          </Box>
        </Stack>
      </Box>
      <Box display={{ xs: 'block', xl: 'none' }}>
        <Routes>
          <Route
            path="/"
            element={
              <ForgotPasswordBox
                setEmail={(email) => setUserEmail(email)}
                fullHeight
              />
            }
          />
          <Route
            path="link-sent"
            element={
              <ForgotPasswordConfirmationBox email={userEmail} fullHeight />
            }
          />
          <Route path="reset" element={<ChangePasswordBox fullHeight />} />
        </Routes>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
