import { FormControlLabel, Stack, Typography } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss';
import { useState } from 'react';
import useAuth from '../../Context/Auth';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ProfileSettingsSection = () => {
  const navigate = useNavigate();
  const { updateSounds, updateRestrictedAccess, sounds, restrictedAccess } =
    useAuth();
  const [soundSwitch, setSoundSwitch] = useState(sounds);
  const [restrictedSwitch, setRestrictedSwitch] = useState(restrictedAccess);

  const handleSoundSwitch = () => {
    setSoundSwitch(!soundSwitch);
    updateSounds(!soundSwitch);
  };
  const handleRestrictedSwitch = () => {
    setRestrictedSwitch(!restrictedSwitch);
    updateRestrictedAccess(!restrictedSwitch);
  };

  return (
    <Stack spacing={5} className={styles['settings-container']}>
      <Stack spacing={3} className={styles['general-settings-stack']}>
        <Stack spacing={2} className={styles['subscription-stack']}>
          <Stack
            className={styles['box']}
            onClick={() => navigate('/payment-details')}
          >
            <Stack direction="row" className={styles['content']}>
              <Typography variant="body1" className={styles['section-button']}>
                Subscription/Payment Method
              </Typography>
              <ArrowForwardIos className={styles['right-arrow']} />
            </Stack>
          </Stack>
        </Stack>
        <Typography variant="h4">General Settings</Typography>
        <Stack
          className={styles['section-content']}
          direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
          spacing={3}
        >
          <Stack className={styles['first-stack']}>
            <Stack direction="row" className={styles['content']}>
              <Typography className={styles['section-button']} variant="h5">
                Sound Effects
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={soundSwitch}
                    onChange={() => handleSoundSwitch()}
                  />
                }
                label=""
              />
            </Stack>
          </Stack>
          <Stack className={styles['second-stack']}>
            <Stack direction="row" className={styles['content']}>
              <Typography className={styles['section-button']} variant="h5">
                Restricted Access
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={restrictedSwitch}
                    onChange={handleRestrictedSwitch}
                  />
                }
                label=""
              />
            </Stack>
            <Typography variant="body1" className={styles['text']}>
              Various sections of the application will be protected by your
              password, so that only you can access them.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={2} className={styles['privacy-policy-stack']}>
        <Typography variant="h4">Privacy Policy</Typography>
        <Stack className={styles['box']}>
          <Stack direction="row" className={styles['content']}>
            <Typography variant="body1" className={styles['section-button']}>
              Allow to collect information for analytics
            </Typography>
            <FormControlLabel control={<IOSSwitch checked />} label="" />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileSettingsSection;
