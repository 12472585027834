import { useNavigate } from 'react-router-dom';
import { Typography, Button, Stack, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import { AxiosError } from 'axios';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(6, 'Password must be 6 characters long')
    .required('Password is required'),
});

const SignupBox = () => {
  const navigate = useNavigate();
  const { error, setError, login } = useAuth();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError('');
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [error]);

  const loginClickHandler = async (email: string, password: string) => {
    setError('');
    try {
      const response = await serverApi.login(email, password);
      const { user, token, subscriptionValidUntil } = response.data;
      const profiles = await serverApi.getProfiles(token);
      const profilesStore = profiles.data.profiles;
      const userStore = { ...user, token, subscriptionValidUntil };
      login(userStore, profilesStore);
      formik.resetForm();
      if (!user.email_verified) {
        navigate('/signup/verify-email');
      } else if (user.user_status === 'activation_pending') {
        navigate('/category-selection');
      } else if (profilesStore.length == 0) {
        navigate('/add-student');
      } else {
        navigate('/home');
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { email: string; password: string }) => {
      loginClickHandler(values.email, values.password);
    },
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        alignItems="center"
        justifyContent="center"
        className={styles['login-box']}
      >
        <Stack width="90%" alignItems="center" spacing={6}>
          <Typography variant="h3" className={styles['header-text']}>
            Login
          </Typography>
          <Stack width="90%" spacing={2}>
            <Box>
              <FormField
                name="email"
                fieldLabel="Email"
                id="email"
                placeHolder="e.g steve@gmail.com"
                value={formik.values.email}
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
                handleChange={formik.handleChange}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.email && formik.errors.email
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <FormField
                fieldLabel="Enter Password"
                id="password"
                placeHolder="Enter Password"
                type="password"
                value={formik.values.password}
                error={
                  formik.touched.password && formik.errors.password
                    ? true
                    : false
                }
                handleChange={formik.handleChange}
                name="password"
                isPassword={true}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.password && formik.errors.password
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box>
              <Typography
                variant="body2"
                color="primary.main"
                component="span"
                onClick={() => navigate('/forgot-password')}
                className={styles['signup-text']}
              >
                Forgot Password?
              </Typography>
            </Box>

            <Box>
              <Button
                className={styles['login-button']}
                variant="contained"
                type="submit"
                fullWidth
                size="large"
                disabled={!formik.isValid}
              >
                <Typography>
                  <b>Login</b>
                </Typography>
              </Button>
              <Typography
                visibility={`${error ? 'visible' : 'hidden'}`}
                variant="body2"
                color="red.main"
              >
                {error ? error : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default SignupBox;
