import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Link,
} from '@mui/material';
import styles from './styles.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import serverApi from '../../Utils/api';
import useAuth from '../../Context/Auth';
import CircularProgress from '@mui/material/CircularProgress';
import { ConvertedPrice } from './interfaces/exchangeRate.interface';
import CompletionNotifyBar from '../CompletionNotifyBar';
import { validMultiplePlansForTeacher } from '../../Utils/constants/planTypes';

interface Plan {
  amount: number;
  interval: string;
  price_id: string;
}

const SubscriptionScreen = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const location = useLocation();
  const { plans, flag } = location.state || {};
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const [userPlans, setUserPlans] = useState(plans);
  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);
  const [error, setErrorFlag] = useState(false);
  const [rateError, setRateErrorFlag] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<ConvertedPrice | null>(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false); // Add state for checkbox
  const [isMarketingChecked, setIsMarketingChecked] = useState(false);

  const [studentCount, setStudentCount] = useState(
    user?.subscription_type || 'ThreeStudentsPlan'
  );
  const studentOptions = [
    { label: 'Up to 3 students', value: 'ThreeStudentsPlan' },
    { label: 'Up to 5 students', value: 'FiveStudentsPlan' },
    { label: 'Up to 10 students', value: 'TenStudentsPlan' },
    { label: 'Up to 20 students', value: 'TwentyStudentsPlan' },
    { label: 'Up to 30 students', value: 'ThirtyStudentsPlan' },
  ];

  const handleStudentChange = async (event: { target: { value: string } }) => {
    const selectedValue = event.target.value;
    setStudentCount(selectedValue);
    setLoader(true);
    const result = await serverApi.getSubscriptionPlans(
      selectedValue,
      user.token
    );
    setUserPlans(result);
    setLoader(false);
  };

  // disable all previous options
  const isDisabled = (index: number) => {
    const userPlan = studentOptions.find(
      (plan) => plan.value === user.plan_type
    );
    if (!userPlan) return false;

    if (studentOptions.indexOf(userPlan) < index) return false;
    else return true;
  };

  const handleButtonClick = async () => {
    let result;
    if (flag) {
      const { prev_subscription } = location.state;
      result = {
        new_subscription_id: selectedPlan?.price_id,
        old_subscription_id: prev_subscription,
        customerId: user.stripe_id,
      };
    } else {
      result = {
        priceId: selectedPlan?.price_id,
        customerId: user.stripe_id,
        userId: user.uuid,
        couponCode: couponCode,
        marketingEnabled: isMarketingChecked,
      };
    }
    navigate('/payment', { state: { result: result, flag: flag } });
  };

  const handleCardClick = async (plan: Plan, index: number) => {
    if (selectedCardIndex === index) return;
    setLoading(true);
    setSelectedPlan(plan);
    setSelectedCardIndex(index);
    try {
      const response = await serverApi.getExchangeRate(
        plan.price_id,
        user.country,
        user.token
      );
      setExchangeRate(response.convertedPrice);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
      setRateErrorFlag(true);
      setLoading(false);
    }
  };

  const handleCouponChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCouponCode(event.target.value);
  };

  const applyCoupon = async () => {
    try {
      const response = await serverApi.getCoupon(couponCode, user.token);
      const couponData = response;
      if (couponData && couponData.percent_off) {
        setDiscount(couponData.percent_off);
        setErrorFlag(false);
      }
    } catch (error) {
      setErrorFlag(true);
      setDiscount(null);
      console.error('Error applying coupon:', error);
    }
  };

  const getDiscountedAmount = (amount: number) => {
    if (!discount) return amount;
    const discountAmount = amount * (discount / 100);
    return (amount - discountAmount).toFixed(2);
  };

  return (
    <>
      {discount ? (
        <Stack className={styles.notification}>
          <CompletionNotifyBar text="Coupon added successfully!" />
        </Stack>
      ) : null}

      <Box className={styles.container}>
        <Box className={styles.content}>
          <Typography variant="h3" className={styles.title}>
            Subscription Plans
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            {user.subscription_type === 'Multiple Users Plan' ||
            validMultiplePlansForTeacher.includes(user.subscription_type)
              ? 'For Multiple-User'
              : 'For Single-User'}
          </Typography>
          {validMultiplePlansForTeacher.includes(user.subscription_type) ? (
            <FormControl fullWidth variant="outlined" className={styles.form}>
              <Select
                labelId="student-count-label"
                id="student-count-select"
                value={studentCount}
                onChange={handleStudentChange}
              >
                {studentOptions.map((option, index) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={isDisabled(index)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          <Typography variant="caption" className={styles.trialText}>
            Your first week is on us! Your subscription starts after the 7-day
            free trial period.
          </Typography>
          {loader ? (
            <CircularProgress color="success" />
          ) : (
            <Box className={styles.cardContainer}>
              {userPlans.map((plan: any, index: number) => (
                <Card
                  key={index}
                  className={`${styles.card} ${
                    index === selectedCardIndex ? styles.cardSelected : ''
                  }`}
                  onClick={async () => await handleCardClick(plan, index)}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#d7b5f2' : '#f69271',
                    boxShadow:
                      index === selectedCardIndex
                        ? `0 0 0 4px ${
                            index % 2 === 0 ? '#d7b5f2' : '#f69271'
                          }, 
                        0 0 10px rgba(0, 0, 0, 0.2)`
                        : 'none',
                    transform:
                      index === selectedCardIndex
                        ? 'translateY(-100px)'
                        : 'none',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" className={styles.cardTitle}>
                      Get access!
                    </Typography>
                    <Typography variant="h4" className={styles.price}>
                      €
                      {discount
                        ? getDiscountedAmount(plan.amount)
                        : plan.amount}
                    </Typography>
                    <Typography variant="body2" className={styles.period}>
                      {plan.interval.charAt(0).toUpperCase() +
                        plan.interval.slice(1) +
                        'ly'}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
          <Typography variant="caption" className={styles.text}>
            Got a coupon code? Enter it to receive discount
          </Typography>
          <Box className={styles.couponBox}>
            <TextField
              placeholder="Enter Code"
              variant="outlined"
              className={styles.couponInput}
              value={couponCode}
              onChange={handleCouponChange}
            />
            <Button
              variant="contained"
              color="primary"
              className={styles.applyButton}
              onClick={applyCoupon}
            >
              Apply
            </Button>
          </Box>
          {error ? (
            <Typography variant="caption" className={styles.error}>
              Coupon Not Valid
            </Typography>
          ) : null}
          {selectedPlan && (exchangeRate || rateError) ? (
            <>
              <Stack>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkboxChecked}
                      onChange={(e) => setCheckboxChecked(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="caption" className={styles.text}>
                      I have read and agree to the{' '}
                      <Link
                        href={require('../../Assets/Documents/MyWordPal-Terms of Use.pdf')}
                        target="_blank"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMarketingChecked}
                      onChange={(e) => setIsMarketingChecked(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="caption" className={styles.text}>
                      {`I'd like to receive `}
                      <Link
                        href={require('../../Assets/Documents/MyWordPal-Website Privacy Policy.pdf')}
                        target="_blank"
                      >
                        News, Updates and Special Offers
                      </Link>
                    </Typography>
                  }
                />
              </Stack>
              <Button
                variant="contained"
                color="primary"
                className={`${styles.proceedButton} ${
                  !checkboxChecked ? styles.disabled : ''
                }`}
                onClick={handleButtonClick}
                disabled={!checkboxChecked}
              >
                Proceed
              </Button>
            </>
          ) : null}

          <Typography variant="caption" className={styles.disclaimer}>
            {loading ? (
              <CircularProgress color="success" />
            ) : exchangeRate ? (
              <span>
                *Disclaimer: Currency is as per the country selected, the
                exchange rate is {exchangeRate.currency}{' '}
                {exchangeRate.unit_amount / 100}
              </span>
            ) : rateError ? (
              <span>*Disclaimer: Cant show exchange rate for your country</span>
            ) : null}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionScreen;
