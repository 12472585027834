import { Button, Stack, Typography } from '@mui/material';
import styles from './styles.module.scss';

const BadgeModal = ({
  handleClose,
  badge,
}: {
  handleClose: () => void;
  badge: any;
}) => {
  return (
    <Stack spacing={4} className={styles['badge-modal-container']}>
      <Typography variant="h4" className={styles['heading']}>
        Badge Earned
      </Typography>
      <img src={badge.image} alt="star" className={styles['badge-img']} />
      <Stack spacing={1} className={styles['badge-details-stack']}>
        <Typography variant="h3" className={styles['badge-name']}>
          {badge.title}
        </Typography>
        <Typography variant="body1" className={styles['sub-text']}>
          Mastered {badge.wordsRequired} words
        </Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={handleClose}
        className={styles['modal-button']}
      >
        Awesome!
      </Button>
    </Stack>
  );
};

export default BadgeModal;
