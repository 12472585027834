import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import SingleResultList from '../../Components/SingleResultList';
import BackArrow from '../../Assets/Images/back-arrow.svg';
import styles from './styles.module.scss';
import { animateScroll as scroll } from 'react-scroll';
import { ReactComponent as UpwardButton } from '../../Assets/Images/up-button.svg';

export interface Word {
  value: string;
  result: 'correct' | 'wrong' | 'semi-correct';
  sound?: any;
}

const SingleResult = () => {
  const [words, setWords] = useState<Word[]>();
  const [sessionDetails, setSessionDetails] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const { user, selectedProfile } = useAuth();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showButton, setShowButton] = useState(false);
  const observerTarget = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { session, sessionNumber } = location.state;
  const session_id = session.uuid;
  const profile_id = selectedProfile.uuid;

  const fetchData = async () => {
    if (loader) return;
    setLoader(true);
    setLoader2(true);
    try {
      const response = await serverApi.getSessionResult(
        profile_id,
        session_id,
        user.token,
        pageNumber
      );

      const wordsList: Word[] = [];
      response.data.results.data.map((result: any) => {
        wordsList.push({
          value: result.flashcard_value,
          result: result.result,
          sound: result.user_audio,
        });
      });
      setWords((prevWords) => [...(prevWords || []), ...wordsList]);
      setSessionDetails(response.data.results.data[0]);
      const isLastPage = pageNumber >= response.data.results.totalPages;
      setHasMore(!isLastPage);

      if (!isLastPage) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      }
    } catch (error) {
      console.error('Error while fetching session result', error);
    } finally {
      setLoader(false);
      setLoader2(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loader) {
          fetchData();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, pageNumber, hasMore, loader]);

  const handleClick = () => {
    navigate('/results');
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const resultTitle =
    (sessionDetails?.totalCorrect / sessionDetails?.totalWords) * 100 > 50
      ? 'Wow! That was Good!'
      : 'Nice effort!';

  return (
    <Stack className={styles['container-stack']}>
      <Stack spacing={1} direction="row" className={styles['heading']}>
        <IconButton onClick={handleClick} className={styles['back-btn']}>
          <img src={BackArrow} alt="back" className={styles['back-img']} />
        </IconButton>
        <Typography variant="h4" className={styles['page-name']}>
          Session Result
        </Typography>
      </Stack>
      {loader && pageNumber === 1 ? (
        <CircularProgress color="success" />
      ) : (
        <>
          <Stack className={styles['top-container']}>
            <Stack className={styles['top-content']}>
              <Typography variant="h4" className={styles['top-heading']}>
                Session # {sessionNumber || 0}
              </Typography>
              <Typography variant="h3" className={styles['score']}>
                <span>{sessionDetails?.totalCorrect || 0}</span>/
                {sessionDetails?.totalWords || 0}
              </Typography>
            </Stack>
            <Stack className={styles['top-container-footer']}>
              <Typography variant="body1" className={styles['text']}>
                {resultTitle}
              </Typography>
            </Stack>
          </Stack>

          <SingleResultList words={words} />
          {showButton && (
            <IconButton
              className={styles['scroll-to-top-button']}
              color="primary"
              onClick={scrollToTop}
              aria-label="scroll-to-top"
            >
              <UpwardButton />
            </IconButton>
          )}
        </>
      )}
      <Stack ref={observerTarget}></Stack>
      {pageNumber !== 1 && loader2 && <CircularProgress color="success" />}
    </Stack>
  );
};

export default SingleResult;
