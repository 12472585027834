import React, { useState } from 'react';
import './styles.css';

function MatchingGame() {
  const [draggedItem, setDraggedItem] = useState(null);
  const [droppedSuccess, setDroppedSuccess] = useState(false);

  // Handle drag start (for mouse)
  const onDragStart = (event: any, item: any) => {
    setDraggedItem(item);
    event.dataTransfer.setData('text', item);
  };

  // Handle drag over (for mouse)
  const onDragOver = (event: any) => {
    event.preventDefault();
  };

  // Handle drop (for mouse)
  const onDrop = (event: any, target: any) => {
    event.preventDefault();
    const item = event.dataTransfer.getData('text');
    handleDrop(item, target);
  };

  // Handle touch start (for touch)
  const onTouchStart = (event: any, item: any) => {
    event.preventDefault();
    setDraggedItem(item);
    createDragImage(event, item);
  };

  // Handle touch move (for touch)
  const onTouchMove = (event: any) => {
    event.preventDefault(); // Prevent default touch action (scrolling)
    if (!draggedItem) return;
    moveDragImage(event);
  };

  // Handle touch end (for touch)
  const onTouchEnd = (event: any) => {
    removeDragImage();
    const touch = event.changedTouches[0];
    const targetElement = document.elementFromPoint(
      touch.clientX,
      touch.clientY
    ) as HTMLElement;
    if (targetElement && targetElement.textContent) {
      handleDrop(draggedItem, targetElement.textContent);
    }
    setDraggedItem(null);
  };

  // Create a ghost image for touch drag
  const createDragImage = (event: any, item: any) => {
    const dragImage = document.createElement('div');
    dragImage.className = 'box ghost';
    dragImage.textContent = item;
    dragImage.style.position = 'absolute';
    dragImage.style.pointerEvents = 'none';
    dragImage.style.zIndex = '1000';
    dragImage.style.width = '100px';
    dragImage.style.height = '100px';
    document.body.appendChild(dragImage);
    moveDragImage(event);
  };

  // Move the ghost image for touch drag
  const moveDragImage = (event: any) => {
    const dragImage: any = document.querySelector('.ghost');
    if (dragImage) {
      dragImage.style.left = `${
        event.touches[0].clientX - dragImage.offsetWidth / 2
      }px`;
      dragImage.style.top = `${
        event.touches[0].clientY - dragImage.offsetHeight / 2
      }px`;
    }
  };

  // Remove the ghost image after touch end
  const removeDragImage = () => {
    const dragImage = document.querySelector('.ghost');
    if (dragImage) {
      document.body.removeChild(dragImage);
    }
  };

  // General drop handler
  const handleDrop = (item: any, target: any) => {
    if (item === target) {
      setDroppedSuccess(true);
    } else {
      setDroppedSuccess(false);
    }
  };

  // Render a box
  const renderBox = (item: any) => (
    <div
      key={item}
      className="box"
      draggable
      onDragStart={(event) => onDragStart(event, item)}
      onDragOver={onDragOver}
      onDrop={(event) => onDrop(event, item)}
      onTouchStart={(event) => onTouchStart(event, item)}
      onTouchMove={onTouchMove}
      onTouchEnd={(event) => onTouchEnd(event)}
    >
      {item}
    </div>
  );

  return (
    <div className="app">
      <h2>Drag and Drop Example</h2>
      <div className="container">
        <div className="row">{['A', 'B'].map(renderBox)}</div>
        <div className="row">{['A', 'B'].map(renderBox)}</div>
      </div>
      {droppedSuccess && (
        <div className="success">Success! Item dropped correctly.</div>
      )}
    </div>
  );
}

export default MatchingGame;
