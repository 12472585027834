import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  useDraggable,
  useDroppable,
} from '@dnd-kit/core';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState, type FC } from 'react';
import PressGif from '../../Assets/Gifs/Press Animation.gif';
import type { IFlashCard } from '../../Utils/constants/interfaces';
import { RECORDER_STATES } from '../../Utils/constants/speechSession';
import {
  DraggableItem,
  Position,
} from '../PracticeSessionThree/interfaces/DraggableInterface';
import styles from './styles.module.scss';

const CORRECT_ANSWER_SOUND = '/SampleSounds/correct-answer.wav';

type DraggableFlashcardProps = {
  flashcardValue: string;
  itemId: number;
  isCorrectId: boolean;
  isInCorrectId: boolean;
  position: Position;
  isOver?: boolean;
  disabled?: boolean;
};

const DraggableFlashcard: FC<DraggableFlashcardProps> = ({
  flashcardValue,
  isCorrectId,
  isInCorrectId,
  position,
  itemId,
  isOver,
  disabled,
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `${itemId}-${position}`,
    data: {
      type: position,
    },
    disabled,
  });

  const { setNodeRef: collisionDropRef } = useDroppable({
    id: `${itemId}-${position}-drop`,
    data: {
      accepts: [position === Position.TOP ? Position.BOTTOM : Position.TOP],
    },
  });

  return (
    <Box ref={collisionDropRef}>
      <Box
        id={position}
        key={flashcardValue}
        className={styles.bux}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <Typography
          id={Position.BOTTOM}
          variant="h1"
          className={`${styles['box']} ${
            isCorrectId
              ? `${styles['green']} ${styles['shake']}`
              : isInCorrectId
              ? styles['pink']
              : isOver
              ? styles['over']
              : styles['white']
          }`}
        >
          {flashcardValue}
        </Typography>
      </Box>
    </Box>
  );
};

export type DraggableFlashcardsProps = {
  flashCard: IFlashCard;
  flashcardsTop: DraggableItem[];
  flashcardsBottom: DraggableItem[];
  setAnswerSound: (sound: string) => void;
  setIsAnswerSoundPlaying: (isPlaying: boolean) => void;
  setRecorderStatus: (state: string) => void;
  cardOpacity: string;
  checkResult: boolean;
  setCheckResult: (result: boolean) => void;
};

export const DraggableFlashcards: FC<DraggableFlashcardsProps> = ({
  flashCard,
  flashcardsTop,
  flashcardsBottom,
  setAnswerSound,
  setIsAnswerSoundPlaying,
  setRecorderStatus,
  cardOpacity,
  checkResult,
  setCheckResult,
}) => {
  /**
   * Drag and Drop
   */
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const dragReset = () => {
    setActiveId(null);
    setOverIdBottom(null);
    setOverIdTop(null);
  };

  const setCorrectId = (id: number, position: Position) => {
    if (position === Position.TOP) {
      setCorrectIdTop(id);
    }
    if (position === Position.BOTTOM) {
      setCorrectIdBottom(id);
    }
  };

  const setIncorrectId = (id: number, position: Position) => {
    if (position === Position.TOP) {
      setIncorrectIdTop(id);
    }
    if (position === Position.BOTTOM) {
      setIncorrectIdBottom(id);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    if (checkResult) return;

    console.log('onDragEnd', event);

    setShowPointer(false);
    setCheckResult(true);

    const [overId, overPostion] = event.over?.id.toString().split('-') as [
      string,
      Position
    ];
    const [activeId, activePosition] = event.active.id
      .toString()
      .split('-') as [string, Position];

    if (overPostion === activePosition) {
      dragReset();
      setCheckResult(false);
      return;
    }

    const overFlashcard =
      overPostion === Position.TOP
        ? flashcardsTop[Number(overId)]
        : flashcardsBottom[Number(overId)];
    const activeFlashcard =
      activePosition === Position.TOP
        ? flashcardsTop[Number(activeId)]
        : flashcardsBottom[Number(activeId)];

    // CORRECT
    if (
      activeFlashcard.flashcard_value === flashCard.flashcard_value &&
      overFlashcard.flashcard_value === flashCard.flashcard_value
    ) {
      setCorrectId(Number(activeId), activePosition);
      setCorrectId(Number(overId), overPostion);
      setAnswerSound(CORRECT_ANSWER_SOUND);
      setIsAnswerSoundPlaying(true);
      setRecorderStatus(RECORDER_STATES.correctAnswer);

      return;
    }

    // INCORRECT
    if (
      activeFlashcard.flashcard_value !== flashCard.flashcard_value ||
      overFlashcard.flashcard_value !== flashCard.flashcard_value
    ) {
      setIncorrectId(Number(activeId), activePosition);
      setIncorrectId(Number(overId), overPostion);
      setAnswerSound('');
      setIsAnswerSoundPlaying(true);
      setRecorderStatus(RECORDER_STATES.wrongAnswer);

      return;
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const id = Number(event.over?.id.toString().split('-')[0]);
    const position = event.over?.id.toString().split('-')[1];

    const currentIsNotTop = event.active.id
      .toString()
      .includes(Position.BOTTOM);

    if ((id !== undefined || id !== null) && position) {
      if (position === Position.TOP && currentIsNotTop) {
        setOverIdTop(id);
      }
      if (position === Position.BOTTOM && !currentIsNotTop) {
        setOverIdBottom(id);
      }
    }
  };

  /**
   * Pointer Tracking
   */
  const correctTop = useRef<HTMLDivElement>(null);
  const correctBottom = useRef<HTMLDivElement>(null);
  const [showPointer, setShowPointer] = useState<boolean>(false);
  const [positionPointer, setPositionPointer] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  useEffect(() => {
    const setPosition = () => {
      if (showPointer && correctTop.current && correctBottom.current) {
        const rect = correctTop.current.getBoundingClientRect();
        setPositionPointer({ x: rect.x, y: rect.y });
      }
    };

    const changePosition = () => {
      if (showPointer && correctTop.current && correctBottom.current) {
        const rect = correctBottom.current.getBoundingClientRect();
        setPositionPointer({ x: rect.x, y: rect.y });
      }
    };

    setPosition();
    const timer = setTimeout(changePosition, 2000);
    return () => clearTimeout(timer);
  }, [showPointer]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowPointer(true);
    }, 2000);
    timer = setTimeout(() => {
      setShowPointer(false);
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  /**
   * Drag and Drop
   */

  const [correctIdTop, setCorrectIdTop] = useState<number | null>(null);
  const [correctIdBottom, setCorrectIdBottom] = useState<number | null>(null);
  const [incorrectIdTop, setIncorrectIdTop] = useState<number | null>(null);
  const [incorrectIdBottom, setIncorrectIdBottom] = useState<number | null>(
    null
  );
  const [overIdTop, setOverIdTop] = useState<number | null>(null);
  const [overIdBottom, setOverIdBottom] = useState<number | null>(null);

  const resetStates = () => {
    setCheckResult(false);
    setIncorrectIdBottom(null);
    setIncorrectIdTop(null);
    setCorrectIdTop(null);
    setCorrectIdBottom(null);
  };

  //   const handleDrop = () => {};

  useEffect(() => {
    resetStates();
    dragReset();
  }, [flashCard, flashcardsTop, flashcardsBottom]);

  return (
    <Stack className={styles['title-words']}>
      <Typography className={styles['heading']}>
        Click or tap, drag and drop to match the correct words
      </Typography>
      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Stack
          direction="row"
          className={`${styles['separated']} ${styles[cardOpacity]}`}
        >
          {flashcardsTop.map((item, index) => (
            <Box
              ref={index === correctIdTop ? correctTop : null}
              key={item.flashcard_value}
            >
              <DraggableFlashcard
                flashcardValue={item.flashcard_value}
                itemId={index}
                isCorrectId={checkResult && index === correctIdTop}
                isInCorrectId={index === incorrectIdTop}
                position={Position.TOP}
                isOver={index === overIdTop}
                disabled={checkResult}
              />
            </Box>
          ))}
        </Stack>
        <Stack
          direction="row"
          className={`${styles['separated']} ${styles[cardOpacity]}`}
        >
          {flashcardsBottom.map((item, index) => (
            <Box
              ref={index === correctIdBottom ? correctBottom : null}
              key={item.flashcard_value}
            >
              <DraggableFlashcard
                flashcardValue={item.flashcard_value}
                itemId={index}
                isCorrectId={checkResult && index === correctIdBottom}
                isInCorrectId={index === incorrectIdBottom}
                position={Position.BOTTOM}
                isOver={index === overIdBottom}
                disabled={checkResult}
              />
            </Box>
          ))}
        </Stack>
        <DragOverlay>
          {activeId ? (
            <>
              <Box>
                <DraggableFlashcard
                  flashcardValue={
                    activeId?.toString().includes(Position.TOP)
                      ? flashcardsTop[
                          Number(activeId.toString()?.split('-')?.[0])
                        ].flashcard_value
                      : flashcardsBottom[
                          Number(activeId.toString()?.split('-')?.[0])
                        ].flashcard_value
                  }
                  itemId={0}
                  isCorrectId={false}
                  isInCorrectId={false}
                  position={
                    activeId?.toString().includes(Position.TOP)
                      ? Position.TOP
                      : Position.BOTTOM
                  }
                />
              </Box>
            </>
          ) : null}
        </DragOverlay>
      </DndContext>
      {positionPointer.x > 0 && showPointer && (
        <img
          src={PressGif}
          alt="Press Gif"
          className={styles['gif-overlay']}
          style={{
            transform: `translate(${positionPointer.x}px, ${positionPointer.y}px)`,
          }}
        />
      )}
    </Stack>
  );
};
