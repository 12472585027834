import { useRef, useEffect } from 'react';

interface WordReaderProps {
  separatedWord: string;
  onComplete: () => void;
}

const WordReader = ({ separatedWord, onComplete }: WordReaderProps) => {
  const msg = new window.SpeechSynthesisUtterance();
  const synthesis = window.speechSynthesis;
  const isWordSpoken = useRef(false);

  useEffect(() => {
    // isWordSpoken is used to make sure this useEffect does not trigger its callback twice in strict mode.
    if (!isWordSpoken.current) {
      const completeWord = separatedWord.replace(/-/g, '');

      msg.text = completeWord;

      const voiceList = synthesis.getVoices();

      //TODO: Improve the implementation for voice agents
      let foundVoice = voiceList.find(
        (voice) => voice.name === 'Google UK English Female'
      );

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) => voice.name === 'Karen' && voice.lang === 'en-AU'
        );
      }

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) =>
            voice.name === 'Shelley (English (United Kingdom))' &&
            voice.lang === 'en-GB'
        );
      }

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) =>
            voice.name === 'English United States' && voice.lang === 'en_US'
        );
      }

      if (foundVoice) {
        msg.voice = foundVoice;
        foundVoice.name === 'Google UK English Female'
          ? (msg.rate = 1)
          : (msg.rate = 0.8);
      } else {
        onComplete();
        return;
      }

      synthesis.cancel(); //clear the queue
      synthesis.speak(msg);

      msg.onend = () => {
        onComplete();
      };
    }

    return () => {
      isWordSpoken.current = true;
    };
  }, []);

  return <></>;
};

export default WordReader;
