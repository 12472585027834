import { useState } from 'react';
import {
  Typography,
  Stack,
  TextField,
  IconButton,
  Box,
  Button,
  Tooltip,
} from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import hidePasswordIcon from '../../Assets/Images/hide_password.svg';
import unhidePasswordIcon from '../../Assets/Images/unhide_password.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import styles from './styles.module.scss';

interface FormFieldProps {
  fieldLabel: string;
  id: string;
  placeHolder: string;
  type?: string;
  value: string;
  error: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  name: string;
  isPassword?: boolean;
  disabled?: boolean;
  changeButton?: boolean;
  handleClick?: () => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f7de87',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 240,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const FormField = (props: FormFieldProps) => {
  const {
    fieldLabel,
    id,
    placeHolder,
    type,
    value,
    error,
    handleChange,
    name,
    isPassword,
    disabled,
    changeButton,
    handleClick,
  } = props;

  const [valueType, setValueType] = useState(type);

  return (
    <Stack>
      <Stack direction="row" className={styles['label-stack']}>
        <Typography mb={1} className={styles['label']}>
          {fieldLabel}
        </Typography>
        {isPassword && !changeButton ? (
          <HtmlTooltip
            title={
              <Typography>
                Password should be:
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '20px',
                    marginTop: '5px',
                  }}
                >
                  <li>at least 8 characters</li>
                  <li>1 uppercase letter</li>
                  <li>1 lowercase letter</li>
                  <li>1 number</li>
                  <li>1 special character</li>
                </ul>
              </Typography>
            }
            placement="right"
            arrow
            className={styles['tooltip']}
          >
            <InfoOutlinedIcon className={styles['info-icon']} />
          </HtmlTooltip>
        ) : null}
      </Stack>
      <TextField
        id={id}
        variant="outlined"
        type={valueType}
        placeholder={placeHolder}
        value={value}
        error={error}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        InputProps={{
          endAdornment: changeButton ? (
            <Button
              className={styles['change-btn']}
              color="primary"
              onClick={handleClick ? () => handleClick() : undefined}
            >
              change
            </Button>
          ) : isPassword ? (
            <IconButton
              onClick={() => {
                valueType === 'password'
                  ? setValueType('text')
                  : setValueType('password');
              }}
            >
              <Box
                component="img"
                src={
                  valueType === 'password'
                    ? unhidePasswordIcon
                    : hidePasswordIcon
                }
              />
            </IconButton>
          ) : null,
        }}
      ></TextField>
    </Stack>
  );
};

export default FormField;
