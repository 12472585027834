import PaymentForm from '../PaymentForm';
import serverApi from '../../Utils/api';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Context/Auth';

const UpdateDetailsRoute = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const callUpdateMethod = async (payload: any) => {
    return await serverApi.updatePaymentMethod(
      payload.customer_id,
      payload.payment_method_id,
      user.token
    );
  };

  const prepareApiPayload = (payment_method_id: string, data: any) => ({
    customer_id: data.customerId,
    payment_method_id,
  });

  const navigator = async () => {
    navigate('/payment-details');
  };

  return (
    <>
      <PaymentForm
        buttonLabel="Update"
        prepareApiPayload={prepareApiPayload}
        makeApiCall={callUpdateMethod}
        navigator={navigator}
      />
    </>
  );
};

export default UpdateDetailsRoute;
