import { IconButton, Stack, Typography } from '@mui/material';
import Trash from '../../Assets/Images/trash.svg';
import Close from '../../Assets/Images/black-cross.svg';
import hidePasswordIcon from '../../Assets/Images/hide_password.svg';
import styles from './styles.module.scss';

type ModalComponentProps = {
  handleClose: () => void;
  handleDeleteAccountOpen: () => void;
  handleDeactivateAccountOpen: () => void;
};

const ModalComponent = ({
  handleClose,
  handleDeleteAccountOpen,
  handleDeactivateAccountOpen,
}: ModalComponentProps) => {
  const handleDeleteAccountClick = () => {
    handleDeleteAccountOpen();
    handleClose();
  };

  const handleDeactivateAccountClick = () => {
    handleDeactivateAccountOpen();
    handleClose();
  };

  return (
    <Stack className={styles['modal-container']}>
      <Stack className={styles['modal-content']} spacing={2}>
        <IconButton
          color="error"
          className={styles['icon-btn']}
          onClick={handleDeleteAccountClick}
        >
          <img src={Trash} alt="delete-icon" />
          <Typography variant="h4" ml={3}>
            Delete Account
          </Typography>
        </IconButton>
        <IconButton
          className={styles['icon-btn']}
          onClick={() => handleDeactivateAccountClick()}
        >
          <img src={hidePasswordIcon} alt="delete-icon" />
          <Typography variant="h4" ml={3}>
            Deactivate Account
          </Typography>
        </IconButton>
        <IconButton
          color="secondary"
          onClick={handleClose}
          className={styles['icon-btn']}
        >
          <img src={Close} alt="close-icon" />
          <Typography variant="h4" ml={3}>
            Cancel
          </Typography>
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default ModalComponent;
