const LogoutIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75493 1.15479C7.83646 1.06593 6.89043 1.01953 5.92418 1.01953C4.95792 1.01953 4.01188 1.06593 3.0934 1.15479C2.08272 1.25258 1.30954 2.27698 1.26389 3.52432C1.19338 5.45077 1.15625 7.45061 1.15625 9.50167C1.15625 11.5527 1.19338 13.5526 1.26389 15.479C1.30954 16.7264 2.08272 17.7508 3.0934 17.8486C4.01188 17.9374 4.95792 17.9838 5.92418 17.9838C6.89043 17.9838 7.83646 17.9374 8.75493 17.8486C9.76561 17.7508 10.5388 16.7264 10.5844 15.479C10.6096 14.7917 10.6305 14.095 10.647 13.39V5.6134C10.6305 4.90837 10.6096 4.21167 10.5844 3.52432C10.5388 2.27698 9.76561 1.25257 8.75493 1.15479Z"
      fill="white"
    />
    <path
      d="M12.4097 9.6216C12.4097 10.2944 12.504 11.2414 12.6012 12.0394C12.7003 12.8527 13.514 13.3736 14.264 13.0439C15.637 12.4403 16.9025 11.5449 17.7882 10.2427C18.0433 9.86755 18.0433 9.37565 17.7882 9.00053C16.9026 7.69829 15.6371 6.80289 14.2641 6.19931C13.5141 5.86961 12.7004 6.3905 12.6013 7.20375C12.504 8.00175 12.4097 8.94883 12.4097 9.6216Z"
      fill="white"
    />
    <path
      d="M12.4097 9.6216C12.4097 10.2944 12.504 11.2414 12.6012 12.0394C12.7003 12.8527 13.514 13.3736 14.264 13.0439C15.637 12.4403 16.9025 11.5449 17.7882 10.2427C18.0433 9.86755 18.0433 9.37565 17.7882 9.00053C16.9026 7.69829 15.6371 6.80289 14.2641 6.19931C13.5141 5.86961 12.7004 6.3905 12.6013 7.20375C12.504 8.00175 12.4097 8.94883 12.4097 9.6216Z"
      stroke="#737D83"
      strokeWidth="2.03571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5844 3.52432C10.5388 2.27698 9.76561 1.25257 8.75493 1.15479C7.83646 1.06593 6.89043 1.01953 5.92418 1.01953C4.95792 1.01953 4.01188 1.06593 3.0934 1.15479C2.08272 1.25258 1.30954 2.27698 1.26389 3.52432C1.19338 5.45077 1.15625 7.45061 1.15625 9.50167C1.15625 11.5527 1.19338 13.5526 1.26389 15.479C1.30954 16.7264 2.08272 17.7508 3.0934 17.8486C4.01188 17.9374 4.95792 17.9838 5.92418 17.9838C6.89043 17.9838 7.83646 17.9374 8.75493 17.8486C9.76561 17.7508 10.5388 16.7264 10.5844 15.479"
      stroke="#737D83"
      strokeWidth="2.03571"
      strokeLinecap="round"
    />
    <path
      d="M5.4347 9.62109L12.4062 9.62109"
      stroke="#737D83"
      strokeWidth="2.03571"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutIcon;
