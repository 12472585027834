import { Box, Button, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormField from '../FormField';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Context/Auth';
import serverApi from '../../Utils/api';
import RaccoonWithGreenTile from '../../Assets/Images/raccoon-with-green-tile.svg';
import { AxiosError } from 'axios';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

const ForgotPasswordBox = ({
  fullHeight,
  fullWidth,
  setEmail,
}: {
  fullHeight?: boolean;
  fullWidth?: boolean;
  setEmail?: (role: string) => void;
}) => {
  const navigate = useNavigate();
  const { error, setError } = useAuth();

  const clickHandler = async (email: string) => {
    try {
      setError('');
      const response = await serverApi.forgetPassword(email);
      if (response.status === 201) {
        formik.resetForm();
        setEmail && setEmail(email);
        navigate('/forgot-password/link-sent');
      }
    } catch (error) {
      if (error instanceof AxiosError) setError(error.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { email: string }) => {
      clickHandler(values.email);
    },
    isInitialValid: false,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack className={styles['forgot-password-box']}>
        <Stack
          width={fullWidth ? '100%' : '90%'}
          alignItems="center"
          spacing={5}
        >
          {fullHeight ? (
            <Box className={styles['heading-box']}>
              <img
                src={RaccoonWithGreenTile}
                alt="Raccoon With Green Tile"
                className={styles['heading-image']}
              />
              <Typography variant="h3" className={styles['header-text']}>
                Forgot Password?
              </Typography>
            </Box>
          ) : fullWidth ? (
            <Typography variant="h3" className={styles['header-text']}>
              Forgot Password?
            </Typography>
          ) : null}
          <Typography variant="h5" className={styles['sub-text']}>
            Please enter your email address and we will send the reset link
          </Typography>
          <Stack spacing={3} width="90%" className={styles['box-stack']}>
            <Box width="100%">
              <FormField
                name="email"
                fieldLabel="Email"
                id="email"
                placeHolder="e.g John@gmail.com"
                value={formik.values.email}
                error={
                  formik.touched.email && formik.errors.email ? true : false
                }
                handleChange={formik.handleChange}
              />
              <Typography
                variant="body2"
                color="red.main"
                visibility={`${
                  formik.touched.email && formik.errors.email
                    ? 'visible'
                    : 'hidden'
                }`}
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : '&nbsp;'}
              </Typography>
            </Box>

            <Box width="100%">
              <Button
                className={styles['send-button']}
                variant="contained"
                type="submit"
                fullWidth
                size="large"
                disabled={!formik.isValid}
              >
                <Typography>
                  <b>Send Email</b>
                </Typography>
              </Button>
              <Typography
                visibility={`${error ? 'visible' : 'hidden'}`}
                variant="body2"
                color="red.main"
              >
                {error ? error : '&nbsp;'}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default ForgotPasswordBox;
