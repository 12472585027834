import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Typography,
  Button,
  Avatar,
  IconButton,
  Modal,
  Popover,
  Box,
  Paper,
} from '@mui/material';
import useAuth, { IProfile } from '../../Context/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '../../Assets/Images/menu_grey.svg';
import styles from './styles.module.scss';
import DeleteProfileModal from '../ModalDeleteStudent';

const ProfileTable: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profiles, avatars } = useAuth();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const [selectedProfile, setSelectedProfile] = useState<IProfile | null>(null);

  const isStudentProfilesPage = location.pathname === '/student-profiles';

  const [isDeleteStudentOpen, setIsDeleteStudentOpen] = useState(false);

  const handleDeleteStudentOpen = () => {
    handleClose();
    setIsDeleteStudentOpen(true);
  };
  const handleDeleteStudentClose = () => setIsDeleteStudentOpen(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    profile: IProfile
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedProfile(profile);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getImageById = (id: string) => {
    const avatar = avatars.find((avatar) => avatar.uuid === id);
    return avatar && avatar.image;
  };

  const handleEditClick = () => {
    navigate('/edit-student', { state: selectedProfile });
  };

  return (
    <TableContainer className={styles['table-container']} component={Paper}>
      <Modal
        open={isDeleteStudentOpen}
        onClose={handleDeleteStudentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteProfileModal
          handleClose={handleDeleteStudentClose}
          profileId={selectedProfile?.uuid || ''}
        />
      </Modal>
      <Table className={styles['table']} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="text.secondary">Student</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography color="text.secondary">Total Sessions</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography color="text.secondary">Level</Typography>
            </TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map((row) => {
            return (
              <TableRow key={row.uuid} hover tabIndex={-1}>
                <TableCell component="th" scope="row">
                  <Stack
                    direction="row"
                    spacing={1}
                    className={styles['student-profile-stack']}
                  >
                    <Avatar
                      className={styles['avatar']}
                      src={getImageById(row.avatar_uuid)}
                    />
                    <Typography>{row.profile_name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.total_sessions}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.current_level}</Typography>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={(e) => handleClick(e, row)}>
                    <img src={Menu} alt="" />
                  </IconButton>
                  <Popover
                    id={popoverId}
                    open={open && anchorEl !== null}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    className={styles['popover']}
                  >
                    <Stack>
                      <Button
                        type="button"
                        onClick={() => handleEditClick()}
                        className={styles['edit-button']}
                      >
                        Edit Profile
                      </Button>
                      <Button
                        type="button"
                        color="error"
                        onClick={() =>
                          // handleDeleteClick(selectedProfile?.profile_id || '')
                          handleDeleteStudentOpen()
                        }
                        className={styles['delete-button']}
                      >
                        Delete Profile
                      </Button>
                    </Stack>
                  </Popover>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!isStudentProfilesPage && (
        <Box marginX="auto" marginY="15px" width="40%">
          <Button
            variant="contained"
            type="submit"
            fullWidth
            size="large"
            className={styles['view-students-btn']}
            onClick={() => navigate('/student-profiles')}
          >
            <Typography className={styles['btn-text']}>
              <b>See All Students</b>
            </Typography>
          </Button>
        </Box>
      )}
    </TableContainer>
  );
};

export default ProfileTable;
